import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsv2-pagina-barra-navegacao',
  templateUrl: './pagina-barra-navegacao.component.html'
})
export class PaginaBarraNavegacaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
