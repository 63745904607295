import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AddHttpHeaderInterceptor implements HttpInterceptor {
  constructor(private router: Router ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenValue = localStorage.getItem('tsToken');

    const clonedReq = req.clone({
      // headers: req.headers.set('Content-Type', 'application/json').set('token', ((tokenValue!==null) ? tokenValue : '')).set('url', this.router.url)
      headers: req.headers.set('Content-Type', 'application/json').set('token', ((tokenValue!==null) ? tokenValue : ''))
    });

    return next.handle(clonedReq);
  }
}
