import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';
import { ServicoProjeto, ServicoProjetoAberturaIdentificacao, ServicoProjetoTipo, ServicoProjetoTipoCaptura, ServicoProjetoTipoIdentificacao } from '../../servicos/srv.projeto';

@Component({
  selector: 'tsv2-prj-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoMsg, ServicoPaginacao, ServicoProjeto, ServicoProjetoAberturaIdentificacao, ServicoProjetoTipo, ServicoProjetoTipoCaptura, ServicoProjetoTipoIdentificacao ]
})
export class ProjetoPesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  resultado: any;
  tipos: any;
  tiposAbertura: any;
  tiposCaptura: any;
  tiposIdentificacao: any;

  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoProjeto: ServicoProjeto,
    private servicoProjetoAberturaIdentificacao: ServicoProjetoAberturaIdentificacao,
    private servicoProjetoTipo: ServicoProjetoTipo,
    private servicoProjetoTipoCaptura: ServicoProjetoTipoCaptura,
    private servicoProjetoTipoIdentificacao: ServicoProjetoTipoIdentificacao,
    private servicoPaginacao: ServicoPaginacao
  ) {
    this.opcaoPadrao = -1;
    this.qtdeItensPadrao = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;
  }

  ngOnInit() {
    this.spinner.show();
    this.servicoProjetoTipo.listarProjetoTipo({}).subscribe(res => {
      this.tipos = res.lista;
      this.servicoProjetoAberturaIdentificacao.listarProjetoAberturaIdentificacao({}).subscribe(res => {
        this.tiposAbertura = res.lista;
        this.servicoProjetoTipoCaptura.listarProjetoTipoCaptura({}).subscribe(res => {
          this.tiposCaptura = res.lista;
          this.servicoProjetoTipoIdentificacao.listarProjetoTipoIdentificacao({}).subscribe(res => {
            this.tiposIdentificacao = res.lista;
            this.spinner.hide();
          }, (err) => {
            this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }, (err) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  exibirBarraNav(exibir) {
    this.exibirBarra = exibir;

    // this.configAppComponent(exibir);
  }

  pesquisar(frm, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      //this.spinner.show();
      this.pesquisa = 1;
      frm.pagina = pagina;
      frm.itensPagina = (qtdeItens==undefined || qtdeItens==null ? this.qtdeItensPadrao : qtdeItens);

      this.servicoProjeto.listarProjetos(frm).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        //this.spinner.hide();
      }, (err) => {
        //this.spinner.hide();
        this.servicoMsg.exibeErro({"statusCode": 999});
      });
    }
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

  /*configAppComponent(exibir) {
    let pageContainer = this.pageContent.nativeElement.parentNode.parentNode;
    let classe = "page-container-wide";
    let arr = pageContainer.className.split(" ");
    if (!exibir) {
      if (arr.indexOf(classe) == -1) {
        pageContainer.className += " " + classe;
      }
    }
    else {
      let i = arr.indexOf(classe);
      if (i >= 0) {
        let classeRemovida = arr.splice(i, 1);
        pageContainer.className = arr.toString();
      }
    }
  }*/

}
