import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoEstabelecimento, ServicoRede } from '../../servicos/srv.rede';
import { ServicoEstado, ServicoCidade } from '../../servicos/srv.configuracao';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';
import { ServicoSessao } from '../../servicos/srv.sessao';

import { Util } from '../../servicos/srv.util';

@Component({
  selector: 'tsv2-stb-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoCidade, ServicoEstabelecimento, ServicoEstado, ServicoMsg, ServicoRede, ServicoPaginacao, ServicoSessao, Util ]
})
export class EstabelecimentoPesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  prj: any;
  redes: any;
  ufs: any;
  cidades: any;
  resultado: any;
  paginas: any;
  idProjeto: number;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  cnpjValido: boolean;
  cepValido: boolean;
  idEstado: number = -1;
  idCidade: number = -1;
  idRede: number = -1;
  grid: boolean = true;
  permissao: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoCidade: ServicoCidade,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoEstado: ServicoEstado,
    private servicoMsg: ServicoMsg,
    private servicoRede: ServicoRede,
    private servicoPaginacao: ServicoPaginacao,
    private servicoSessao: ServicoSessao,
    private util: Util
  ) {
    let controller = this.route.snapshot.url[0].path;
    this.permissao = servicoSessao.buscarPermissoes(controller);
  }

  ngOnInit() {
    // this.spinner.show();
    // this.prj = this.servicoSessao.buscarProjeto();
    // if (this.prj.hasOwnProperty("projetoId") && this.prj.projetoId != null){

      this.cnpjValido = true;
      this.cepValido = true;
      this.qtdeItensPadrao = 20;
      this.pesquisa = 0;
      this.paginaAtual = 1;
      this.ttPaginas = 0;

      this.listarEstados();
      this.listarRedes();

      // let obj = { "idProjeto": this.prj.projetoId };

      // this.servicoRede.listarRedes(obj).subscribe(rsp => {
      //   this.redes = rsp.lista;
      //   this.servicoEstado.listarEstados(obj).subscribe(estados => {
      //     this.ufs = estados.lista;
      //     // this.spinner.hide();
      //   }, (err) => {
      //     // this.spinner.hide();
      //     this.servicoMsg.exibeErro(err).then(r1 => {});
      //   });
      // }, (err) => {
      //   // this.spinner.hide();
      //   this.servicoMsg.exibeErro(err).then(r1 => {});
      // });
    // }
    // else {
    //   // this.spinner.hide();
    //   this.servicoMsg.exibeErro({"statusCode": 999}).then(r1 => {});
    // }
  }

  pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      this.spinner.show();

      frmValues.cnpj = this.util.soNumeros(frmValues.cnpj);
      this.pesquisa = 1;
      frmValues.pagina = pagina;
      frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);

      this.servicoEstabelecimento.listarEstabelecimentos(frmValues).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

   validaCNPJ(valor){
     var vl = valor.replace(/[^0-9]/g, "");

     if (vl.length > 0) {
       if (!this.util.validaCNPJ(vl)) {
         this.cnpjValido = false;
       }
       else {
         this.cnpjValido = true;
       }
     }
     else {
       this.cnpjValido = true;
     }
   }

   validaCEP(valor){
     var vl = valor.replace(/[^0-9]/g, "");

     if (vl.length > 0 && vl.length < 8) {
       this.cepValido = false;
     }
     else {
       this.cepValido = true;
     }
   }

   listarEstados(){
     this.idEstado = -1;
     this.servicoEstado.listarEstados({"idEstado": this.idEstado}).subscribe(
       estados => {
         this.ufs = estados.lista;

         if (estados.lista.length===1){
           this.idEstado = estados.lista[0].idEstado;
           this.listarCidades();
         }
       }, (err) => {
         this.servicoMsg.exibeErro(err);
       }
     );
   }

   listarCidades(){
     this.idCidade = -1;
     if (this.idEstado >= 0) {
       this.servicoCidade.listarCidades({"idEstado": this.idEstado}).subscribe(
         cidades => {
           this.cidades = cidades.lista;

           if (cidades.lista.length===1){
             this.idCidade = cidades.lista[0].idCidade;
           }
           this.listarRedes();
         }, (err) => {
           this.servicoMsg.exibeErro(err);
         }
       );
     }
     else {
       this.cidades = [];
       this.listarRedes();
     }
   }

   listarRedes(){
     this.idRede = -1;
     this.servicoRede.listarRedes({"idEstado": this.idEstado, "idCidade": this.idCidade}).subscribe(
       redes => {
         this.redes = redes.lista;
       }, (err) => {
         this.servicoMsg.exibeErro(err);
       }
     );
   }

   mudarExibicao(){
     this.grid = (!this.grid) ? true : false;
   }

}
