import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Ng2TreeSettings, TreeModel, NodeEvent } from 'ng2-tree';

import { NgbModal, NgbActiveModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ServicoUsuario, ServicoUsuarioEstabelecimento, ServicoUsuarioMenu, ServicoUsuarioProduto, ServicoUsuarioProjeto, ServicoUsuarioRede } from '../../servicos/srv.configuracao';
import { ServicoFornecedor, ServicoMarca, ServicoProduto } from '../../servicos/srv.produto';
import { ServicoProjeto } from '../../servicos/srv.projeto';
import { ServicoEstabelecimento } from '../../servicos/srv.rede';
import { ServicoMsg } from '../../servicos/srv.msg';

// import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'tsv2-usr-config',
  templateUrl: './configuracoes.component.html',
  providers: [
    ServicoEstabelecimento,
    ServicoFornecedor,
    ServicoMsg,
    ServicoMarca,
    ServicoProjeto,
    ServicoUsuario,
    ServicoUsuarioEstabelecimento,
    ServicoUsuarioMenu,
    ServicoUsuarioProduto,
    ServicoUsuarioProjeto,
    ServicoUsuarioRede
  ]
})
export class UsuarioConfiguracoesComponent implements OnInit {

  @Input() frns: any;
  @Input() menuestrutura: any;
  @Input() prds: any;
  @Input() prjs: any;
  @Input() redes: any;
  @Input() stbs: any;
  @Input() usrs: any;

  @Output() editar = new EventEmitter();
  @Output() adicionarLoja = new EventEmitter();
  @Output() adicionarRede = new EventEmitter();
  @Output() adicionarProduto = new EventEmitter();
  @Output() atualizar = new EventEmitter();

  public menutreesettgs: Ng2TreeSettings;

  listaMenus: any = [];
  listaIDs: any = [];
  listaCNPJs: any = [];
  mrcs: any = [];

  private eventsSubscription: any;

  // @ViewChild('treeComponent') treeComponent;
  // oopNodeController: any;

  constructor(
    private router: Router,
    private servicoMsg: ServicoMsg,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMarca: ServicoMarca,
    private servicoProduto: ServicoProduto,
    private servicoProjeto: ServicoProjeto,
    private servicoUsuario: ServicoUsuario,
    private servicoUsuarioEstabelecimento: ServicoUsuarioEstabelecimento,
    private servicoUsuarioMenu: ServicoUsuarioMenu,
    private servicoUsuarioProduto: ServicoUsuarioProduto,
    private servicoUsuarioProjeto: ServicoUsuarioProjeto,
    private servicoUsuarioRede: ServicoUsuarioRede,
    // private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.menutreesettgs = {
      "rootIsVisible": true,
      "showCheckboxes": true,
      "enableCheckboxes": true
    };
  }

  salvarProjeto(frm){
    frm.idUsuario = this.usrs.idUsuario;
    frm.idUsuarioPai = this.usrs.idUsuarioPai;
    this.servicoUsuarioProjeto.adicionarUsuarioProjeto(frm).subscribe(id => {
      this.servicoMsg.exibeSucesso('Criado!', 'Projeto vinculado com sucesso!').then(r1 => {
        if (r1.success){
          this.atualizarAcessos();
        }
      }, (err: any) => {
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    });
  }

  editarUsuario(u){
    this.editar.emit(u.idUsuario);
  }

  removerProjeto(idProjeto){
    let o = {};
    o = Object.assign(o, {"idUsuario": this.usrs.idUsuario});
    o = Object.assign(o, {"idProjeto": idProjeto});
    o = Object.assign(o, {"idUsuarioPai": this.usrs.idUsuarioPai});

    let tituloAlerta = 'Deseja realmente remover o acesso ao projeto?';
    let tituloSucesso = 'Acesso removido com sucesso!';
    let textoSucesso = 'Usuário não detém mais acesso ao projeto.';
    let btnConfirm = 'Sim!';
    let btnCancel = 'Cancelar';

    this.servicoMsg.exibeAlertaCustomizado(tituloAlerta, o, this.servicoUsuarioProjeto, 'removerUsuarioProjetoPorUK', tituloSucesso, textoSucesso, btnConfirm, btnCancel).then(r1 => {
      if (r1.success){
        this.atualizarAcessos();
      }
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  redefinirSenha(id){
    let tituloAlerta = 'Redefinir uma nova senha?';
    let tituloSucesso = 'Senha reenviada ao usuário!';
    let textoSucesso = 'Solicite ao mesmo (usuário), verificar seu e-mail.';
    let btnConfirm = 'Sim!';
    let btnCancel = 'Cancelar';
    this.servicoMsg.exibeAlertaCustomizado(tituloAlerta, id, this.servicoUsuario, 'redefinirSenha', tituloSucesso, textoSucesso, btnConfirm, btnCancel).then(r1 => {
      if (r1.success){
        return true;
      }
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  atualizarAcessos(){
    let obj = {};
    obj = Object.assign(obj, {"idUsuario": this.usrs.idUsuario});
    if (this.usrs.idPerfil==1 || this.usrs.idPerfil==2) {
      this.servicoProjeto.listarAcessos(obj).subscribe(p => {
        this.prjs = p;
      });
    } else if (this.usrs.idPerfil==3) {
      this.servicoProduto.listarAcessos(obj).subscribe(produtos => {
        this.frns = Array.from(
          new Set(
            produtos.map(item => item.idFornecedor)
          )
        ).map(id => {
          return {
            "idFornecedor": id,
            "fornecedor": produtos.find(s => s.idFornecedor === id).fornecedor
          }
        });
        this.prds = produtos;
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    } else if (this.usrs.idPerfil==4) {
      this.servicoEstabelecimento.listarAcessos(obj).subscribe(estabelecimentos => {
        this.redes = Array.from(
          new Set(
            estabelecimentos.map(item => item.idRede)
          )
        ).map(id => {
          return {
            "idRede": id,
            "rede": estabelecimentos.find(s => s.idRede === id).rede
          }
        });
        this.stbs = estabelecimentos;
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
  }

  listarFornecedores(){
    let p = {};
    this.servicoFornecedor.listarFornecedores(p).subscribe(obj => {
      return obj.lista;
    });
  }

  listarMarcas(idFornecedor){

    let p = {};
    p = Object.assign(p, {"idFornecedor": idFornecedor});
    p = Object.assign(p, {"idUsuario": this.usrs.idUsuario});

    this.servicoProduto.listarAcessos(p).subscribe(produtos => {
      this.prds = produtos;
      this.mrcs = Array.from(
        new Set(
          produtos.filter(f => {return f.acesso !== true}).map(item => item.idMarca)
        )
      ).map(id => {
        return {
          "idMarca": id,
          "marca": produtos.find(s => s.idMarca === id).marca
        }
      });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  listarProdutos(idFornecedor, idMarca){
    let p = {};
    p = Object.assign(p, {"idFornecedor": idFornecedor});
    p = Object.assign(p, {"idMarca": idMarca});
    p = Object.assign(p, {"idUsuario": this.usrs.idUsuario});

    this.servicoProduto.listarAcessos(p).subscribe(produtos => {
      this.prds = produtos;
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  listarEstabelecimentos(idRede){
    let p = {};
    p = Object.assign(p, {"idRede": idRede});
    p = Object.assign(p, {"idUsuario": this.usrs.idUsuario});

    this.servicoEstabelecimento.listarAcessos(p).subscribe(estabelecimentos => {
      this.stbs = estabelecimentos;
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  prepararLista(idProduto){
    let ids = this.listaIDs.filter(f => { return f == idProduto.value });
    if (idProduto.checked && ids.length == 0){
      this.listaIDs.push(idProduto.value);
    }
    else if (!idProduto.checked && ids.length > 0){
      this.listaIDs = this.listaIDs.filter(f => { return f !== idProduto.value });
    }
  }

  prepararListaCNPJs(cnpj){
    let ids = this.listaCNPJs.filter(f => { return f == cnpj.value });
    if (cnpj.checked && ids.length == 0){
      this.listaCNPJs.push(cnpj.value);
    }
    else if (!cnpj.checked && ids.length > 0){
      this.listaCNPJs = this.listaCNPJs.filter(f => { return f !== cnpj.value });
    }
  }

  salvarListaProdutos(){
    let obj = {};
    obj = Object.assign(obj, {"idUsuario": this.usrs.idUsuario});
    obj = Object.assign(obj, {"listaIDs": this.listaIDs});

    this.servicoUsuarioProduto.adicionarUsuarioListaProduto(obj).subscribe(id => {
      this.servicoMsg.exibeSucesso('Criado!', 'Produtos vinculados com sucesso!').then(r1 => {
        if (r1.success){
          // window.location.href=this.router.url;
          this.atualizarAcessos();
        }
      }, (err: any) => {
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  removerProduto(idProduto){
    let o = {};
    o = Object.assign(o, {"idUsuario": this.usrs.idUsuario});
    o = Object.assign(o, {"idProduto": idProduto});

    let tituloAlerta = 'Deseja realmente remover o acesso ao produto?';
    let tituloSucesso = 'Acesso removido com sucesso!';
    let textoSucesso = 'Usuário não detém mais acesso aos dados do produto.';
    let btnConfirm = 'Sim!';
    let btnCancel = 'Cancelar';

    this.servicoMsg.exibeAlertaCustomizado(tituloAlerta, o, this.servicoUsuarioProduto, 'removerUsuarioProdutoPorUK', tituloSucesso, textoSucesso, btnConfirm, btnCancel).then(r1 => {
      if (r1.success){
        this.atualizar.emit(this.usrs.idUsuario);
      }
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  salvarListaCNPJs(){
    let obj = {};
    obj = Object.assign(obj, {"idUsuario": this.usrs.idUsuario});
    obj = Object.assign(obj, {"listaCNPJs": this.listaCNPJs});

    this.servicoUsuarioEstabelecimento.adicionarUsuarioListaEstabelecimento(obj).subscribe(id => {
      this.servicoMsg.exibeSucesso('Criado!', 'Lojas vinculadas com sucesso!').then(r1 => {
        if (r1.success){
          //window.location.href=this.router.url;
          this.atualizarAcessos();
        }
      }, (err: any) => {
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  removerEstabelecimento(cnpj){
    let o = {};
    o = Object.assign(o, {"idUsuario": this.usrs.idUsuario, "cnpj": cnpj});

    let tituloAlerta = 'Deseja realmente remover o acesso à loja?';
    let tituloSucesso = 'Acesso removido com sucesso!';
    let textoSucesso = 'Usuário não detém mais acesso aos dados da loja.';
    let btnConfirm = 'Sim!';
    let btnCancel = 'Cancelar';

    this.servicoMsg.exibeAlertaCustomizado(tituloAlerta, o, this.servicoUsuarioEstabelecimento, 'removerUsuarioEstabelecimentoPorUK', tituloSucesso, textoSucesso, btnConfirm, btnCancel).then(r1 => {
      if (r1.success){
        this.atualizar.emit(this.usrs.idUsuario);
      }
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  removerRede(idRede){
    let o = {};
    o = Object.assign(o, {"idUsuario": this.usrs.idUsuario, "idRede": idRede});

    let tituloAlerta = 'Deseja realmente remover o acesso à rede?';
    let tituloSucesso = 'Acesso removido com sucesso!';
    let textoSucesso = 'Usuário não detém mais acesso aos dados da rede.';
    let btnConfirm = 'Sim!';
    let btnCancel = 'Cancelar';

    this.servicoMsg.exibeAlertaCustomizado(tituloAlerta, o, this.servicoUsuarioRede, 'removerUsuarioRede', tituloSucesso, textoSucesso, btnConfirm, btnCancel).then(r1 => {
      if (r1.success){
        this.atualizar.emit(this.usrs.idUsuario);
      }
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  adicMenu(e: NodeEvent): void {
    // this.oopNodeController = this.treeComponent.getControllerByNodeId(e.node.id);
    // if (!this.oopNodeController.component.checkboxElementRef) {
    //   return;
    // }

    // if (e.node.node.settings.selectionAllowed) {
      if (this.usrs.menuAlterado === 1) {
        this.listaMenus = [];
        this.usrs.menuAlterado = 0;
      }

      if ((this.listaMenus.length === 0) && (this.listaMenus.filter(x => {return x.idMenu === e.node.id}).length === 0)) {
        this.listaMenus.push({ "idMenu": e.node.id, "acao": "+" });
      }
      else{
        let cont = 0;
        this.listaMenus.filter(x => {return x.idMenu === e.node.id}).forEach(obj => {
          obj.acao = '+';
          cont += 1;
        });
        if (cont === 0) this.listaMenus.push({ "idMenu": e.node.id, "acao": "+" });
      }
    // }
    // else {
    //   this.oopNodeController.unselect();
    //   e.node.node.settings.checked = false;
    //   this.oopNodeController.tree.checked = false;
    //   this.oopNodeController.component.checkboxElementRef.nativeElement.checked = false;
    // }
  }

  remMenu(e: NodeEvent): void {
    if (this.usrs.menuAlterado === 1) {
      this.listaMenus = [];
      this.usrs.menuAlterado = 0;
    }

    this.listaMenus.filter(x => {return x.idMenu === e.node.id}).forEach(obj => { obj.acao = '-' });
  }

  salvarListaMenus(){
    let obj = {};
    obj = Object.assign(obj, {"idUsuario": this.usrs.idUsuario});
    obj = Object.assign(obj, {"listaIDs": this.listaMenus});

    this.servicoUsuarioMenu.adicionarUsuarioListaMenu(obj).subscribe(id => {
      this.servicoMsg.exibeSucesso('Atualizado!', 'Menus atualizados com sucesso!').then(r1 => {
        if (r1.success){
          this.listaMenus = [];
        }
      }, (err: any) => {
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  novaLoja(){
    this.adicionarLoja.emit();
  }

  novaRede(){
    this.adicionarRede.emit();
  }

  novoProduto(){
    this.adicionarProduto.emit();
  }

}
