import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../app.error-handler'

import { EncryptDecrypt } from './srv.encryptdecrypt';

import { environment } from '../../environments/environment';

@Injectable()
export class ServicoSessao {
  private existe: boolean;

  constructor(
    private http: HttpClient,
    private encryptDecrypt: EncryptDecrypt
  ) { }

  adicionarSessao(nome, valor): Promise<any>{
    return new Promise((resolve,reject)=>{
      this.removerSessao(nome).then(r1 => {
        localStorage.setItem(nome, valor);
        resolve({success: true});
      }, (err) => {
        reject({"msg": "Erro inesperado!"});
      });
    });
  }

  checarSessao(nome) {
    if (nome !== null && nome.length > 0){
      let token = localStorage.getItem(nome);
      return (token !== null && token.length > 0) ? true : false;
    }
    return false;
  }

  buscarSessao(nome) {
    /* -999 força o erro e direciona a tela de LOGIN!!! */
    return (this.checarSessao(nome)) ? localStorage.getItem(nome) : '-999';
  }

  removerSessao(nome): Promise<any>{
    return new Promise((resolve,reject)=>{
      if (this.checarSessao(nome)) {
        localStorage.removeItem(nome);
        resolve({success: true});
      }
      else {
        // reject({"msg": "Erro inesperado!"});
        resolve({success: true});
      }
    });
  }

  checarProjeto() {
    return this.http.get<any>(`${environment.configuracao_api}/usuario/validarProjeto`).pipe(catchError(ErrorHandler.handleError));
  }

  alterarProjeto(frm) {
    return this.http.post<any>(`${environment.configuracao_api}/usuario/alterarProjeto`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  buscarProjeto() {
    let ds = this.buscarSessao('tsDadosSessao');
    let obj = JSON.parse(this.encryptDecrypt.Decrypt(ds));
    return { "projetoId": obj.projetoId, "projeto": obj.projeto };
  }

  buscarUsuario() {
    let ds = this.buscarSessao('tsDadosSessao');
    let obj = JSON.parse(this.encryptDecrypt.Decrypt(ds));
    return { "usuarioId": obj.usuarioId, "nome": obj.nome, "email": obj.email };
  }

  buscarMenus() {
    let ds = this.buscarSessao('tsDadosSessao');
    let obj = (ds !=='-999') ? JSON.parse(this.encryptDecrypt.Decrypt(ds)) : '';
    return { "menus": obj.hasOwnProperty("menus") ? obj.menus : [] };
  }

  buscarDadosSessao() {
    return this.http.get<any>(`${environment.configuracao_api}/usuario/buscarDadosSessao`).pipe(catchError(ErrorHandler.handleError));
  }

  buscarPermissoes(ctrl){
    let m = this.buscarMenus();

    let filtro: any = [];
    filtro = m.menus.filter(f => {return (f.url!==null && f.url.includes('/'+ctrl+'/'))});

    let permissao: any = {};
    permissao.adicionar = (filtro.filter(x => {return x.acao==='A'}).length > 0) ? true : false;
    permissao.editar = (filtro.filter(x => {return x.acao==='E'}).length > 0) ? true : false;
    permissao.remover = (filtro.filter(x => {return x.acao==='R'}).length > 0) ? true : false;
    permissao.visualizar = (filtro.filter(x => {return x.acao==='V'}).length > 0) ? true : false;

    return permissao;
  }
}
