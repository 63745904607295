import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

import { APP_CONFIG } from '../app.config';

@Injectable()
export class EncryptDecrypt {
  private key: string;
  private iv: string;

  constructor() {
    this.key = CryptoJS.enc.Utf8.parse(APP_CONFIG.keyPassPhrase);
    this.iv = CryptoJS.enc.Utf8.parse(APP_CONFIG.ivPassPhrase);
  }

  Encrypt(param) {
    let retorno = "";

    let paramCrypt = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(param), this.key, {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );

    retorno = '' + paramCrypt;
    return retorno;
  }

  Decrypt(param) {
    try {
      let bytes = CryptoJS.AES.decrypt(param, this.key, {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      let retorno = bytes.toString(CryptoJS.enc.Utf8);
      // let retorno = bytes.toString();
      return retorno;
    } catch (ex)
    {
      console.log(ex);
    }
  }
}
