import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCliente, ServicoClienteGrupo, ServicoClienteGrupoCliente } from '../../servicos/srv.cliente';
import { ServicoSessao } from '../../servicos/srv.sessao';

@Component({
  selector: 'tsv2-adicionar',
  templateUrl: './adicionar.component.html',
  providers: [ ServicoMsg, ServicoCliente, ServicoClienteGrupo, ServicoClienteGrupoCliente, ServicoSessao ]
})
export class ClienteGrupoAdicionarComponent implements OnInit {
  exibirBarra: boolean = true;

  prj: any;
  clientes: any;
  associados: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCliente: ServicoCliente,
    private servicoClienteGrupo: ServicoClienteGrupo,
    private servicoClienteGrupoCliente: ServicoClienteGrupoCliente,
    private servicoSessao: ServicoSessao
  ) { }

  ngOnInit() {
    // this.spinner.show();
    this.prj = this.servicoSessao.buscarProjeto();
    if (this.prj.hasOwnProperty("projetoId")){
      let obj = Object.assign({}, {idProjeto: this.prj.projetoId});
      obj.idProjeto = this.prj.projetoId;
      this.servicoCliente.listarClientes(obj).subscribe(res => {
        this.clientes = res.lista;
        // this.servicoClienteGrupoCliente.listarClienteGrupoCliente(obj).subscribe(res => {
        //   // this.spinner.hide();
        //   this.associados = res.lista;
        // }, (err) => {
        //   // this.spinner.hide();
        //   this.servicoMsg.exibeErro(err).then(r1 => {});
        // });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
    else {
      // this.spinner.hide();
      this.servicoMsg.exibeErro({"statusCode": 999});
    }
    // this.spinner.hide();
  }

  adicionarClienteGrupo(frmValues){
    // this.spinner.show();
    this.servicoClienteGrupo.adicionarClienteGrupo(frmValues).subscribe(id => {
      let cont = 0;
      let url = '/clienteGrupo/visualizar/' + id;
      for (let i = 0; i < this.associados.length; i++){
        let obj = {"idClienteGrupo": id, "idCliente": this.associados[i].idCliente};
        this.servicoClienteGrupoCliente.adicionarClienteGrupoCliente(obj).subscribe(res => {
          // this.spinner.hide();
          // this.associados = res.lista;
          if (cont == (this.associados.length - 1)){
            this.servicoMsg.exibeSucesso('Criado!', 'Grupo de Cliente criado com sucesso!').then(r1 => {
              if (r1.success){
                this.router.navigate([url]);
              }
            }, (err: any) => {
              // this.spinner.hide();
              this.servicoMsg.exibeErro(err).then(r1 => {});
            });
          }
          else {
            cont += 1;
          }
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
        // this.spinner.hide();
      }
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  addRemParticipante(event, obj){
    if (event.target.checked) {
      this.associados.push(obj);
    }
    if (!event.target.checked) {
      // let index = this.associados.indexOf(obj);
      // if (index > -1) {
      //   this.associados.splice(index, 1);
      // }

      for (let i = 0; i < this.associados.length; i++){
        if (this.associados[i].idCliente === obj.idCliente){
          this.associados.splice(i, 1);
        }
      }
    }
  }

}
