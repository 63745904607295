import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCampanha } from '../../servicos/srv.campanha';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';
import { ServicoProjeto } from '../../servicos/srv.projeto';
import { ServicoSessao } from '../../servicos/srv.sessao';

@Component({
  selector: 'tsv2-cmp-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoMsg, ServicoCampanha, ServicoPaginacao, ServicoProjeto, ServicoSessao ]
})
export class CampanhaPesquisarComponent implements OnInit {
  @ViewChild('pageContent') pageContent: ElementRef;

  exibirBarra: boolean = true;

  resultado: any;
  opcaoPadrao: number;
  qtdeItens: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;

  prj: any;

  colorTheme = 'theme-default';
  bsConfig: Partial<BsDatepickerConfig>;
  bsRangeValue: Date[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCampanha: ServicoCampanha,
    private servicoPaginacao: ServicoPaginacao,
    private servicoProjeto: ServicoProjeto,
    private servicoSessao: ServicoSessao,
    private localeService: BsLocaleService) {
      this.opcaoPadrao = -1;
      this.qtdeItens = 20;
      this.pesquisa = 0;
      this.paginaAtual = 1;
      this.ttPaginas = 0;

      this.bsConfig = Object.assign({}, {
        containerClass: this.colorTheme
      });

      this.localeService.use('pt-br');
    }

    ngOnInit() { }

    exibirBarraNav(exibir) {
      this.exibirBarra = exibir;

      this.configAppComponent(exibir);
    }

    configAppComponent(exibir) {
      let pageContainer = this.pageContent.nativeElement.parentNode.parentNode;
      let classe = "page-container-wide";
      let arr = pageContainer.className.split(" ");
      if (!exibir) {
        if (arr.indexOf(classe) == -1) {
          pageContainer.className += " " + classe;
        }
      }
      else {
        let i = arr.indexOf(classe);
        if (i >= 0) {
          let classeRemovida = arr.splice(i, 1);
          pageContainer.className = arr.toString();
        }
      }
    }

    pesquisar(frmValues, pagina, qtdeItens, origem){
      if ((origem==1) || (origem==0 && this.pesquisa==1)) {
        // this.spinner.show();
        this.pesquisa = 1;
        frmValues.pagina = pagina;
        frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);

        this.prj = this.servicoSessao.buscarProjeto();
        if (this.prj.hasOwnProperty("projetoId")){

          this.servicoCampanha.listarCampanhas(frmValues).subscribe(res => {
            this.resultado = res;
            this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
            // this.spinner.hide();
          }, (err) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err);
          });
        }
        else {
          // this.spinner.hide();
          this.servicoMsg.exibeErro({"statusCode": 999});
        }
      }
    }

    trocar(pagina){
      this.paginaAtual = pagina;

      let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
      element.click();
    }

  }
