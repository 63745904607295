import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoRede } from '../../servicos/srv.rede';

@Component({
  selector: 'tsv2-rde-editar',
  templateUrl: './editar.component.html',
  providers: [ ServicoMsg, ServicoRede ]
})
export class RedeEditarComponent implements OnInit {
  exibirBarra: boolean = true;
  
  p: any;
  idRede: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoRede: ServicoRede
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.idRede = parseInt(id);
    this.servicoRede.consultarRede(this.idRede).subscribe(res => {
      this.p = res.lista[0];
      // this.spinner.hide();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  alterarRede(frmValues){
    // this.spinner.show();
    this.servicoRede.alterarRede(frmValues).subscribe(id => {
      let url = '/rede/visualizar/' + id;
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Alterada!', 'Rede alterada com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
