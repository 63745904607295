import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tsv2-mensagem-alerta',
  templateUrl: './mensagem-alerta.component.html'
})
export class MensagemAlertaComponent implements OnInit {

  @Input() popUpSair: boolean;
  @Input() msgTitulo: string;
  @Input() msgSubTitulo: string;

  @Output() confirma = new EventEmitter();
  @Output() naoConfirma = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  voltar(){
    // this.popUpSair = false;
    this.naoConfirma.emit();
  }

  sair(){
    this.confirma.emit();
  }

}
