import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tsv2-desc-listar',
  templateUrl: './listar.component.html'
})
export class DescontoListarComponent implements OnInit {
  @Input() items: any[];
  @Input() pagina: number;
  @Input() grid: boolean;

  @Output() trocar = new EventEmitter();

  constructor() {
    this.items = [];
    this.pagina = 1;
  }

  ngOnInit() {
  }

}
