import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoFornecedor } from '../../servicos/srv.produto';

@Component({
  selector: 'tsv2-frn-adicionar',
  templateUrl: './adicionar.component.html',
  providers: [ ServicoMsg, ServicoFornecedor ]
})
export class FornecedorAdicionarComponent implements OnInit {
  exibirBarra: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoFornecedor: ServicoFornecedor
  ) { }

  ngOnInit() {
  }

  adicionarFornecedor(frmValues){
    // this.spinner.show();
    this.servicoFornecedor.adicionarFornecedor(frmValues).subscribe(id => {
      let url = '/fornecedor/visualizar/' + id;
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Criado!', 'Fornecedor criado com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
