import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoClienteGrupo } from '../../servicos/srv.cliente';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';
import { ServicoSessao } from '../../servicos/srv.sessao';

@Component({
  selector: 'tsv2-cligrp-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoMsg, ServicoClienteGrupo, ServicoPaginacao, ServicoSessao ]
})
export class ClienteGrupoPesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  resultado: any;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  paginas: any;
  atual: number;
  projetos: any;
  prj: any;

  constructor(
    private spinner: NgxSpinnerService,
    private servicoClienteGrupo: ServicoClienteGrupo,
    private servicoMsg: ServicoMsg,
    private servicoPaginacao: ServicoPaginacao,
    private servicoSessao: ServicoSessao
  ) { }

  ngOnInit() {
  }

  pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      this.spinner.show();
      this.pesquisa = 1;
      frmValues.pagina = pagina;
      frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);

      this.prj = this.servicoSessao.buscarProjeto();
      if (this.prj.hasOwnProperty("projetoId")){
        frmValues.idProjeto = this.prj.projetoId;

        this.servicoClienteGrupo.listarClienteGrupo(frmValues).subscribe(res => {
          this.resultado = res;
          this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
          this.spinner.hide();
        }, (err: any) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }
      else {
        this.spinner.hide();
        this.servicoMsg.exibeErro({"statusCode": 999});
      }
    }
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

}
