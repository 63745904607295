import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicoSessao } from './srv.sessao';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../app.error-handler'

import { environment } from '../../environments/environment';

@Injectable()
export class ServicoFornecedor {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarFornecedores(psq: any) {
    return this.http.post<any>(`${environment.produto_api}/fornecedor/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarFornecedoresAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.produto_api}/fornecedor/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  consultarFornecedor(id: number) {
    return this.http.get<any>(`${environment.produto_api}/fornecedor/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarFornecedorAsync(id: number) {
    let res = await this.http.get<any>(`${environment.produto_api}/fornecedor/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  alterarFornecedor(frm: any) {
    return this.http.put<any>(`${environment.produto_api}/fornecedor/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarFornecedor(frm: any) {
    return this.http.post<any>(`${environment.produto_api}/fornecedor/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.produto_api}/fornecedor/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarAcessos(psq: any) {
    return this.http.post<any>(`${environment.produto_api}/fornecedor/listaracessos`,psq).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoMarca {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarMarcas(psq: any) {
    return this.http.post<any>(`${environment.produto_api}/marca/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarMarcasAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.produto_api}/marca/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  consultarMarca(id: number) {
    return this.http.get<any>(`${environment.produto_api}/marca/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarMarcaAsync(id: number) {
    let res = await this.http.get<any>(`${environment.produto_api}/marca/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  alterarMarca(frm: any) {
    return this.http.put<any>(`${environment.produto_api}/marca/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarMarca(frm: any) {
    return this.http.post<any>(`${environment.produto_api}/marca/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.produto_api}/marca/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCategoria {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarCategorias(psq: any) {
    return this.http.post<any>(`${environment.produto_api}/categoria/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarCategoriasAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.produto_api}/categoria/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  consultarCategoria(id: number) {
    return this.http.get<any>(`${environment.produto_api}/categoria/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarCategoriaAsync(id: number) {
    let res = await this.http.get<any>(`${environment.produto_api}/categoria/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  alterarCategoria(frm: any) {
    return this.http.put<any>(`${environment.produto_api}/categoria/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarCategoria(frm: any) {
    return this.http.post<any>(`${environment.produto_api}/categoria/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.produto_api}/categoria/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoProduto {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarProdutos(psq: any) {
    return this.http.post<any>(`${environment.produto_api}/produto/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarProdutosAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.produto_api}/produto/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  listarAcessos(psq: any) {
    return this.http.post<any>(`${environment.produto_api}/produto/listaracessos`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  consultarProduto(id: number) {
    return this.http.get<any>(`${environment.produto_api}/produto/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarProdutoAsync(id: number) {
    let res = await this.http.get<any>(`${environment.produto_api}/produto/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  alterarProduto(frm: any) {
    return this.http.put<any>(`${environment.produto_api}/produto/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarProduto(frm: any) {
    return this.http.post<any>(`${environment.produto_api}/produto/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.produto_api}/produto/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoTipoEmbalagem {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarTiposEmbalagem(psq: any) {
    return this.http.post<any>(`${environment.produto_api}/produtotipoembalagem/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarTiposEmbalagemAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.produto_api}/produtotipoembalagem/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  consultarTipoEmbalagem(id: number) {
    return this.http.get<any>(`${environment.produto_api}/produtotipoembalagem/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarTipoEmbalagemAsync(id: number) {
    let res = await this.http.get<any>(`${environment.produto_api}/produtotipoembalagem/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  alterarTipoEmbalagem(frm: any) {
    return this.http.put<any>(`${environment.produto_api}/produtotipoembalagem/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarTipoEmbalagem(frm: any) {
    return this.http.post<any>(`${environment.produto_api}/produtotipoembalagem/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.produto_api}/produtotipoembalagem/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoTipoCodigo {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarTiposCodigo(psq: any) {
    return this.http.post<any>(`${environment.produto_api}/produtotipocodigo/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarTiposCodigoAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.produto_api}/produtotipocodigo/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  consultarTipoCodigo(id: number) {
    return this.http.get<any>(`${environment.produto_api}/produtotipocodigo/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarTipoCodigoAsync(id: number) {
    let res = await this.http.get<any>(`${environment.produto_api}/produtotipocodigo/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  alterarTipoCodigo(frm: any) {
    return this.http.put<any>(`${environment.produto_api}/produtotipocodigo/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarTipoCodigo(frm: any) {
    return this.http.post<any>(`${environment.produto_api}/produtotipocodigo/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.produto_api}/produtotipocodigo/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoProdutoPromocao {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarPromocao(psq: any) {
    return this.http.post<any>(`${environment.produto_api}/produtoPromocao/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarPromocaoAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.produto_api}/produtoPromocao/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }
}
