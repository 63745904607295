import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
// import { OwlOptions } from 'ngx-owl-carousel-o';

import { ServicoCidade, ServicoEstado } from '../../servicos/srv.configuracao';
import { ServicoDashboard } from '../../servicos/srv.dashboard';
import { ServicoCategoria, ServicoFornecedor, ServicoMarca, ServicoProduto } from '../../servicos/srv.produto';
import { ServicoEstabelecimento, ServicoRede } from '../../servicos/srv.rede';
import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoSessao } from '../../servicos/srv.sessao';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

// declare var $: any;
import * as $ from 'jquery';

@Component({
  selector: 'tsv2-dashboard',
  templateUrl: './dashboard.component.html',
  providers: [
    //ServicoCampanha,
    //ServicoCategoria,
    ServicoCidade,
    //ServicoDashboard,
    ServicoEstado,
    ServicoEstabelecimento,
    ServicoFornecedor,
    ServicoMarca,
    ServicoMsg,
    ServicoProduto,
    ServicoRede,
    ServicoSessao ]
})
export class DashboardComponent implements OnInit {
  @ViewChild('pageContent') pageContent: ElementRef;
  @ViewChild('graficoHistDescontos') graficoHistDescontos: ElementRef;
  @ViewChild('graficoDescCategoria') graficoDescCategoria: ElementRef;
  @ViewChild('graficoTopProdutos') graficoTopProdutos: ElementRef;
  @ViewChild('graficoTopLojas') graficoTopLojas: ElementRef;
  @ViewChild('graficoBaseParticipantes') graficoBaseParticipantes: ElementRef;
  @ViewChild('graficoDetalheDescontos') graficoDetalheDescontos: ElementRef;
  // @ViewChild('owlUltimosDescontos') owl: ElementRef;
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  exibirBarra: boolean = true;
  campanhas: any;
  categorias: any;
  chart: Highcharts.ChartObject;
  cidades: any;
  coordenadasPadrao: any;
  estabelecimentos: any = [];
  fornecedores: any;
  marcas: any;
  prj: any;
  produtos: any;
  query: any = {};
  redes: any = [];
  titulo: string;
  ufs: any;

  ttDescontos: number;
  ttParticipantes: number;
  ttCompras: number;
  maxDescPeriodo: number;
  medDescPeriodo: number;
  minDescPeriodo: number;
  percUtilizacao: number;
  maxUtilPeriodo:  number;
  medUtilPeriodo: number;
  minUtilPeriodo: number;
  oMaxCategoria: any;
  oMinCategoria: any;
  /* *** LISTAS *** */
  listaCategorias: any;
  listaDescUtilizacao: any;
  listaFornecedores: any;
  listaDiaria: any;
  listaMensal: any;
  listaRedes: any;
  listaEstabelecimentos: any;
  listaProdutos: any;
  listaDescontos: any;

  lstDescDiario: any;
  // lstCmpDiario: any;
  // lstDescDiario: any;
  owlDesc: boolean = true;
  hoje: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  periodoInicio: Date;
  periodoFim: Date;
  // bsRangeValue: Date[];

  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;

  mySlideOptions={items: 1, dots: true, nav: false, loop: true, autoplay: true, autoplayTimeout: 3500, autoplayHoverPause: true};
  // slideCompras={items: 1, dots: true, nav: false, loop: true, autoplay: true, autoplayTimeout: 3500, autoplayHoverPause: true};
  slideCompras={items: 1, dots: true, nav: false, loop: true, autoplay: true, autoplayTimeout: 3500};
  opcoes: any = {items: 1, dots: true, nav: false, loop: true, autoplay: true};

  images = [1, 2, 3].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);

  @Input() popUpSair: boolean;

  @Output() continuar = new EventEmitter();

  // customOptions: OwlOptions = {
  //   loop: true,
  //   mouseDrag: false,
  //   touchDrag: false,
  //   pullDrag: false,
  //   dots: false,
  //   navSpeed: 700,
  //   navText: ['', ''],
    // responsive: {
    //   0: {
    //     items: 1
    //   },
    //   400: {
    //     items: 2
    //   },
    //   740: {
    //     items: 3
    //   },
    //   940: {
    //     items: 4
    //   }
    // },
  //   nav: true
  // }

  constructor(
    private spinner: NgxSpinnerService,
    private servicoCidade: ServicoCidade,
    private servicoDashboard: ServicoDashboard,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoEstado: ServicoEstado,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMarca: ServicoMarca,
    private servicoMsg: ServicoMsg,
    private servicoProduto: ServicoProduto,
    private servicoRede: ServicoRede,
    private servicoSessao: ServicoSessao,
    private localeService: BsLocaleService
  ) {
    /*** COORDENADAS UTILIZADAS NA CENTRALIZAÇÃO DO MAPA!!!  ***/
    // this.coordenadasPadrao = [
    //   {"uf": "AC", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "AL", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "AM", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "AP", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "BA", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "CE", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "DF", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "ES", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "GO", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "MA", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "MG", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "MS", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "MT", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "PA", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "PB", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "PE", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "PI", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "PR", "lat": -24.4, "lng": -50.2, "zoom": 7},
    //   {"uf": "RJ", "lat": -22.9, "lng": -44.3, "zoom": 7},
    //   {"uf": "RN", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "RO", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "RR", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "RS", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "SC", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "SE", "lat": 0, "lng": 0, "zoom": 0},
    //   {"uf": "SP", "lat": -22.4, "lng": -47.7, "zoom": 7},
    //   {"uf": "TO", "lat": 0, "lng": 0, "zoom": 0}
    // ];

    this.bsConfig = Object.assign({}, { maxDate: this.hoje, containerClass: this.colorTheme });

    this.localeService.use('pt-br');
  }

  async ngOnInit() {
    const filtro = ["idEstado","idCidade","idRede","cnpj","idCampanha","idCategoria","idFornecedor","idMarca","idProduto"];
    this.prj = this.servicoSessao.buscarProjeto();

    if (this.prj.hasOwnProperty("projetoId") && this.prj.projetoId != null){
      let inicio = new Date(this.hoje.getFullYear(),this.hoje.getMonth(),parseInt("01"));
      // this.periodoInicio = inicio;
      this.periodoInicio = this.hoje;
      this.periodoFim = this.hoje;

      // this.query.periodo = [];
      // this.query.periodo.push(inicio);
      // this.query.periodo.push(this.hoje);

      this.spinner.show();
      await this.listarEstados();
      await this.listarRedes();
      await this.listarFornecedores();
      await this.buscarDados();
      this.spinner.hide();
    }
    // else {
    //   this.spinner.hide();
    // }

    $(document).ready(function(){
      var templatePlugins = function(){

        var tp_clock = function(){
          function tp_clock_time(){
            let now     = new Date();
            let hour    = now.getHours();
            let minutes = now.getMinutes();

            // hour = hour < 10 ? '0'+hour : hour;
            let hourDesc = hour < 10 ? '0' + hour : hour;
            // minutes = minutes < 10 ? '0'+minutes : minutes;
            let minDesc = minutes < 10 ? '0' + minutes : minutes;

            // $(".plugin-clock").html(hour+"<span>:</span>"+minutes);
            $(".plugin-clock").html(hourDesc+"<span>:</span>"+minDesc);
          }
          if($(".plugin-clock").length > 0){
            tp_clock_time();

            window.setInterval(function(){
              tp_clock_time();
            },10000);
          }
        }

        var tp_date = function(){
          if($(".plugin-date").length > 0){
            var days = ['Domingo','Segunda-Feira','Terça-Feira','Quarta-Feira','Quinta-Feira','Sexta-Feira','Sábado'];
            var months = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];

            var now     = new Date();
            var day     = days[now.getDay()];
            var date    = now.getDate();
            var month   = months[now.getMonth()];
            var year    = now.getFullYear();

            $(".plugin-date").html(day+", "+date+" de "+month+" de "+year);
          }
        }

        return {
          init: function(){
            tp_clock();
            tp_date();
          }
        }
      }();

      $(".ts-button").on("click",function(){
          $(".theme-settings").toggleClass("active");
      });

      templatePlugins.init();
    });
  }

  exibirBarraNav(exibir) {
    this.exibirBarra = exibir;

    this.configAppComponent(exibir);
  }

  configAppComponent(exibir) {
    let pageContainer = this.pageContent.nativeElement.parentNode.parentNode;
    let classe = "page-container-wide";
    let arr = pageContainer.className.split(" ");
    if (!exibir) {
      if (arr.indexOf(classe) == -1) {
        pageContainer.className += " " + classe;
      }
    }
    else {
      let i = arr.indexOf(classe);
      if (i >= 0) {
        let classeRemovida = arr.splice(i, 1);
        pageContainer.className = arr.toString();
      }
    }
  }

  // listarEstados(){
  //   this.query.idEstado = -1;
  //   this.servicoEstado.listarEstados(this.query).subscribe(
  //     estados => {
  //       this.ufs = estados.lista;
  //
  //       if (estados.lista.length > 1){
  //         this.query.idEstado = -1;
  //         this.atualizarFiltros();
  //       }
  //       else {
  //         this.query.idEstado = estados.lista[0].idEstado;
  //         this.listarCidades();
  //       }
  //     }, (err) => {
  //       if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
  //       this.servicoMsg.exibeErro(err);
  //     }
  //   );
  // }

  async listarEstados(){
    this.query.idEstado = -1;

    this.ufs = await this.servicoEstado.listarEstadosAsync(this.query);

    if (this.ufs.length === 1){
      this.query.idEstado = this.ufs[0].idEstado;
      await this.listarCidades();
    }
  }

  listarCampanhas(){
    // this.atualizarParametros(5);

    this.atualizarFiltros();
  }

  // listarCidades(){
  //   this.query.idCidade = -1;
  //   if (parseInt(this.query.idEstado) >= 0) {
  //     // this.atualizarParametros(2);
  //
  //     this.servicoCidade.listarCidades(this.query).subscribe(
  //       cidades => {
  //         this.cidades = cidades.lista;
  //
  //         // if (cidades.lista.length > 1){
  //         //   this.listarRedes();
  //         //   this.atualizarFiltros();
  //         // }
  //         // else {
  //         //   this.query.idCidade = cidades.lista[0].idCidade;
  //         //   this.listarRedes();
  //         // }
  //         if (cidades.lista.length===1){
  //           this.query.idCidade = cidades.lista[0].idCidade;
  //         }
  //         this.listarRedes();
  //       }, (err) => {
  //         if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
  //         this.servicoMsg.exibeErro(err);
  //       }
  //     );
  //   }
  //   else {
  //     this.cidades = [];
  //     this.listarRedes();
  //   }
  // }

  async listarCidades(){
    this.query.idCidade = -1;
    if (parseInt(this.query.idEstado) >= 0) {
      this.cidades = await this.servicoCidade.listarCidadesAsync(this.query);

      if (this.cidades.length===1){
        this.query.idCidade = this.cidades[0].idCidade;
      }
    }
    else {
      this.cidades = [];
    }
  }

  // listarRedes(){
  //   // this.atualizarParametros(3);
  //   this.query.idRede = -1;
  //
  //   this.servicoRede.listarRedes(this.query).subscribe(
  //     redes => {
  //       this.redes = redes.lista;
  //
  //       if (redes.lista.length > 1){
  //         this.query.cnpj = -1;
  //         this.estabelecimentos = [];
  //         // this.atualizarFiltros();
  //       }
  //       else{
  //         this.query.idRede = redes.lista[0].idRede;
  //         this.listarEstabelecimentos();
  //       }
  //     }, (err) => {
  //       if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
  //       this.servicoMsg.exibeErro(err);
  //     }
  //   );
  // }

  async listarRedes(){
    // this.atualizarParametros(3);
    this.query.idRede = -1;

    this.redes = await this.servicoRede.listarRedesAsync(this.query);

    if (this.redes.length > 1){
      this.query.cnpj = -1;
      this.estabelecimentos = [];
    }
    else{
      this.query.idRede = this.redes[0].idRede;
      await this.listarEstabelecimentos();
    }
  }

  // listarEstabelecimentos(){
  //   this.query.cnpj = -1;
  //   if (parseInt(this.query.idRede) > 0){
  //     this.servicoEstabelecimento.listarEstabelecimentos(this.query).subscribe(
  //       estabelecimentos => {
  //         this.estabelecimentos = estabelecimentos.lista;
  //
  //         if (estabelecimentos.lista.length===1){
  //           this.query.cnpj = estabelecimentos.lista[0].cnpj;
  //         }
  //         this.atualizarFiltros();
  //       }, (err) => {
  //         // if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
  //         this.servicoMsg.exibeErro(err);
  //       }
  //     );
  //   }
  //   else {
  //     this.estabelecimentos = [];
  //   }
  // }

  async listarEstabelecimentos(){
    this.query.cnpj = -1;
    if (parseInt(this.query.idRede) > 0){
      this.estabelecimentos = await this.servicoEstabelecimento.listarEstabelecimentosAsync(this.query);
      if (this.estabelecimentos.length===1){
        this.query.cnpj = this.estabelecimentos[0].cnpj;
      }
      await this.atualizarFiltros();
    }
    else {
      this.estabelecimentos = [];
    }
  }

  // listarFornecedores(){
  //   this.servicoFornecedor.listarFornecedores(this.query).subscribe(
  //     fornecedores => {
  //       this.fornecedores = fornecedores.lista;
  //
  //       if (fornecedores.lista.length===1){
  //         this.query.idFornecedor = fornecedores.lista[0].idFornecedor;
  //         this.listarMarcas();
  //       }
  //       // this.atualizarFiltros();
  //     }, (err) => {
  //       this.servicoMsg.exibeErro(err);
  //     }
  //   );
  // }

  async listarFornecedores(){
    this.fornecedores = await this.servicoFornecedor.listarFornecedoresAsync(this.query);
    if (this.fornecedores.length===1){
      this.query.idFornecedor = this.fornecedores[0].idFornecedor;
      await this.listarMarcas();
    }
  }

  // listarMarcas(){
  //   this.query.idMarca = -1;
  //   if (parseInt(this.query.idFornecedor) > 0){
  //     this.servicoMarca.listarMarcas(this.query).subscribe(
  //       marcas => {
  //         this.marcas = marcas.lista;
  //
  //         if (marcas.lista.length===1){
  //           this.query.idMarca = marcas.lista[0].idMarca;
  //           this.listarProdutos();
  //         }
  //         // this.atualizarFiltros();
  //       }, (err) => {
  //         this.servicoMsg.exibeErro(err);
  //       }
  //     );
  //   }
  //   else {
  //     this.marcas = [];
  //     this.produtos = [];
  //   }
  // }

  async listarMarcas(){
    this.query.idMarca = -1;
    if (parseInt(this.query.idFornecedor) > 0){
      this.marcas = await this.servicoMarca.listarMarcasAsync(this.query);
      if (this.marcas.length===1){
        this.query.idMarca = this.marcas[0].idMarca;
        await this.listarProdutos();
      }
    }
    else {
      this.marcas = [];
      this.produtos = [];
    }
  }

  // listarProdutos(){
  //   this.query.idProduto = -1;
  //   if (parseInt(this.query.idMarca) > 0){
  //     this.servicoProduto.listarProdutos(this.query).subscribe(
  //       produtos => {
  //         this.produtos = produtos.lista;
  //
  //         if (produtos.lista.length===1){
  //           this.query.idProduto = produtos.lista[0].idProduto;
  //         }
  //         // this.atualizarFiltros();
  //       }, (err) => {
  //         this.servicoMsg.exibeErro(err);
  //       }
  //     );
  //   }else {
  //     this.produtos = [];
  //   }
  // }

  async listarProdutos(){
    this.query.idProduto = -1;
    if (parseInt(this.query.idMarca) > 0){
      this.produtos = await this.servicoProduto.listarProdutosAsync(this.query);

      if (this.produtos.length===1){
        this.query.idProduto = this.produtos[0].idProduto;
      }
    }
    else {
      this.produtos = [];
    }
  }

  atualizarFiltros(){
    let buscarDados = false;
    this.servicoDashboard.listarFiltros(this.query).subscribe(
      filtro => {
        this.campanhas = filtro.lstCampanhas;
        if (filtro.lstCampanhas != null && filtro.lstCampanhas.length==1) this.query.idCampanha = filtro.lstCampanhas[0].idCampanha; //else buscarDados = true;

        this.categorias = filtro.lstCategorias;
        if (filtro.lstCategorias != null && filtro.lstCategorias.length==1) this.query.idCategoria = filtro.lstCategorias[0].idCategoria; //else buscarDados = true;

        // this.fornecedores = filtro.lstFornecedores;
        // if (filtro.lstFornecedores != null && filtro.lstFornecedores.length==1) this.query.idFornecedor = filtro.lstFornecedores[0].idFornecedor; //else buscarDados = true;
        //
        // this.marcas = filtro.lstMarcas;
        // if (filtro.lstMarcas != null && filtro.lstMarcas.length==1) this.query.idMarca = filtro.lstMarcas[0].idMarca; //else buscarDados = true;
        //
        // this.produtos = filtro.lstProdutos;
        // if (filtro.lstProdutos != null && filtro.lstProdutos.length==1) this.query.idProduto = filtro.lstProdutos[0].idProduto;

        // this.buscarDados();
      }
    );
  }

  atualizarGraficos(){
    let xhd = [];
    let shd = [];
    if (this.listaMensal != null && this.listaMensal.length > 0) {
      xhd = this.listaMensal.map(d => { return d.mesDesc; });
      shd = this.listaMensal.map(d => { return d.descontos; });
    }
    const histDescontos: Highcharts.Options = {
      chart: {
        type: 'column',
        height: 300
      },
      title: {
        text: null
      },
      // subtitle: {
      //   text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
      // },
      xAxis: {
        categories: xhd,
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          // text: 'Population (millions)',
          text: 'Val. em Reais',
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
        }
      },
      // tooltip: {
      //   valueSuffix: ' millions'
      // },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true
          }
        }/*,
        series: {
            pointWidth: 25
        }*/
      },
      // legend: {
      //   layout: 'vertical',
      //   align: 'right',
      //   verticalAlign: 'top',
      //   x: -40,
      //   y: 80,
      //   floating: true,
      //   borderWidth: 1,
      //   backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
      //   shadow: true
      // },
      credits: {
        enabled: false
      },

      colorAxis: {
        min: 1
      },

      colors: [
        // '#BC1015'
        '#ED3237'
      ],

      series: [{
        name: 'Descontos',
        data: shd
        /*,
        tooltip: {
          valuePrefix: 'R$ ',
          pointFormat: "R$ {point.y:.,2f}"
        }*/
      }]
    }
    this.chart = chart(this.graficoHistDescontos.nativeElement, histDescontos);

    const descCategoria: Highcharts.Options = {
      chart: {
        renderTo: 'container',
        type: 'pie',
        height: 300
      },
      colors: [
        '#2F0405', '#5E080B',
        '#8D0C10', '#BC1015',
        '#ED3237', '#EF4348',
        '#F37276', '#F7A1A4',
        '#FBD0D1'
      ],
      title: {
        text: null
      },
      yAxis: {
        title: {
          text: 'Total percent market share'
        }
      },
      plotOptions: {
        // series: { dataLabels: { enabled: false } },
        pie: {
          shadow: false,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          }
        }
      },
      tooltip: {
        formatter: function() {
          return '<b>'+ this.point.name +'</b>: '+ this.y +' %';
        }
      },
      series: [{
        name: 'Browsers',
        data: this.listaCategorias.map((c, i) => { return [c.categoria, c.percDesconto]; }),
        innerSize: '60%'
      }]
    };
    this.chart = chart(this.graficoDescCategoria.nativeElement, descCategoria);

    if (this.listaProdutos!==null && this.listaProdutos.length > 0){
      const top10Produtos: Highcharts.Options = {
        chart: {
          type: 'bar'
        },
        title: {
          text: ''
        },
        // subtitle: {
        //   text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
        // },
        xAxis: {
          categories: this.listaProdutos.map(r => {return r.produto}),
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Descontos em Reais (R$)',
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        // tooltip: {
        //   valueSuffix: ' millions'
        // },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: 'R$ {point.y:.2f}'
            }
          }
        },
        // legend: {
        //   layout: 'vertical',
        //   align: 'right',
        //   verticalAlign: 'top',
        //   x: -40,
        //   y: 80,
        //   floating: true,
        //   borderWidth: 1,
        //   backgroundColor: ('#FFFFFF'),
        //   shadow: true
        // },
        credits: {
          enabled: false
        },
        colorAxis: {
          min: 1
        },
        colors: [
          '#ED3237'
        ],
        series: [{
          name: 'Total de Descontos (R$)',
          //data: [287.92,261.32,243.83,242.46,221.52,217.29,204.69,196.97,184.23,179.54]
          data: this.listaProdutos.map(r => {return r.desconto})
        }]
      }
      this.chart = chart(this.graficoTopProdutos.nativeElement, top10Produtos);
    }

    const baseParticipantes: Highcharts.Options = {
      chart: {
        renderTo: 'container',
        type: 'pie'
      },
      colors: ['#ED3237', '#F7A1A4'],
      title: {
        text: '% Base Participante'
      },
      yAxis: {
        title: {
          text: 'Total percent market share'
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%'
        }
      },
      tooltip: {
        formatter: function() {
          return '<b>'+ this.point.name +'</b>: '+ this.y +' %';
        }
      },
      series: [{
        name: 'Base CPFs',
        data: this.listaDescUtilizacao.map((u, i) => { return [u.descricao, u.percUtilizacao]; }),
        innerSize: '70%'
      }]
    };
    // this.chart = chart(this.graficoBaseParticipantes.nativeElement, baseParticipantes);

    if (this.listaEstabelecimentos!==null && this.listaEstabelecimentos.length > 0){
      const top10Lojas: Highcharts.Options = {
        chart: {
          type: 'bar'
        },
        title: {
          text: null
        },
        // subtitle: {
        //   text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
        // },
        xAxis: {
          categories: this.listaEstabelecimentos.map(r => {return r.estabelecimento}),
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Descontos em Reais (R$)',
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        // tooltip: {
        //   valueSuffix: ' millions'
        // },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: 'R$ {point.y:.2f}'
            }
          }
        },
        // legend: {
        //   layout: 'vertical',
        //   align: 'right',
        //   verticalAlign: 'top',
        //   x: -40,
        //   y: 80,
        //   floating: true,
        //   borderWidth: 1,
        //   backgroundColor: ('#FFFFFF'),
        //   shadow: true
        // },
        credits: {
          enabled: false
        },
        colorAxis: {
          min: 1
        },
        colors: [
          '#F37276'
        ],
        series: [{
          name: 'Total de Descontos (R$)',
          data: this.listaEstabelecimentos.map(r => {return r.desconto})
        }]
      }
      // this.chart = chart(this.graficoTopLojas.nativeElement, top10Lojas);
    }

    if (this.listaDiaria!==undefined && this.listaDiaria!==null && this.listaDiaria.length > 0){
    // if (this.lstDescDiario!==null && this.lstDescDiario.length > 0){
      const detalheDescontos: Highcharts.Options = {
        chart: {
          type: 'areaspline'
        },
        title: {
          text: ''
        },
        legend: {
          layout: 'vertical',
          align: 'left',
          verticalAlign: 'top',
          x: 150,
          y: 100,
          floating: true,
          borderWidth: 1,
          backgroundColor: '#FFFFFF'
        },
        xAxis: {
          // categories: this.listaDiaria.map(d => {
          //   return d.data;
          // }),
          categories: Object.getOwnPropertyNames(this.lstDescDiario)
        },
        yAxis: {
          // title: {
          //   text: 'Fruit units'
          // },
          visible: false
        },
        tooltip: {
          shared: true
          // valueSuffix: ' units'
          //formatter : function() {
          //return this.y + ' ' +  this.series.tooltipOptions.valueSuffix;
          //return this.series.tooltipOptions.valuePrefix + this.y;
          //}
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          areaspline: {
            fillOpacity: 0.5
          }
        },
        colors: [
          // '#F37276'
          '#F7A1A4', '#EF4348', '#BC1015'
        ],
        series: [
          {
            name: 'Descontos',
            data: Object.values(this.lstDescDiario)
          }/*, {
            name: 'Compras',
            data: Object.values(this.lstDescDiario)
          }, {
            name: 'Participantes',
            data: Object.values(this.lstDescDiario)
          }*/
        ]
      }
      this.chart = chart(this.graficoDetalheDescontos.nativeElement, detalheDescontos);
    }

    // this.adicionarMarcadores();
/*
    if (this.listaRedes!==null && this.listaRedes.length > 0){
      const redesMaisAtivas: Highcharts.Options = {
        chart: {
          type: 'bar'
        },
        title: {
          text: 'TOP 10 Redes'
        },
        // subtitle: {
        //   text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
        // },
        xAxis: {
          categories: this.listaRedes.map(r => {return r.rede}),
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Descontos em Reais (R$)',
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        // tooltip: {
        //   valueSuffix: ' millions'
        // },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              format: 'R$ {point.y:.2f}'
            }
          }
        },
        // legend: {
        //   layout: 'vertical',
        //   align: 'right',
        //   verticalAlign: 'top',
        //   x: -40,
        //   y: 80,
        //   floating: true,
        //   borderWidth: 1,
        //   backgroundColor: ('#FFFFFF'),
        //   shadow: true
        // },
        credits: {
          enabled: false
        },
        colorAxis: {
          min: 1
        },
        colors: [
          '#F37276'
        ],
        series: [{
          name: 'Total de Descontos (R$)',
          data: this.listaRedes.map(r => {return r.desconto})
        }]
      }
      this.chart = chart(this.graficoRedesMaisAtivas.nativeElement, redesMaisAtivas);
    }



    if (this.listaFornecedores!==null && this.listaFornecedores.length > 0){
      const frnc = Array.from(new Set(this.listaFornecedores.map(f => f.idFornecedor))).map(id => { return { "id": id, "name": this.listaFornecedores.find(s => s.idFornecedor === id).fornecedor } });

      const desempenhoParceiros: Highcharts.Options = {
        chart: {
          type: 'column',
          height: 450
        },
        title: {
          text: 'Descontos (R$) X Parceiros'
        },
        // subtitle: {
        //   text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
        // },
        xAxis: {
          categories: Array.from(new Set(this.listaFornecedores.map(f => f.mesDesc))),
          title: {
            text: null
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Descontos (R$)',
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        // tooltip: {
        //   valueSuffix: ' millions'
        // },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.y:.2f}',
              y: 10
            }
          }
        },
        // legend: {
        //   layout: 'vertical',
        //   align: 'right',
        //   verticalAlign: 'top',
        //   x: -40,
        //   y: 80,
        //   floating: true,
        //   borderWidth: 1,
        //   backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
        //   shadow: true
        // },
        credits: {
          enabled: false
        },

        colorAxis: {
          min: 1
        },

        colors: [
          '#2F0405', '#5E080B', '#8D0C10', '#BC1015', '#ED3237', '#EF4348', '#F37276', '#F7A1A4', '#FBD0D1'
        ],

        series: frnc.map(f => {
          return { "name": f.name, "data": this.listaFornecedores.filter(l => l.idFornecedor === f.id).map(d => {return d.desconto}) }
        })
      }
      this.chart = chart(this.graficoDesempenhoParceiros.nativeElement, desempenhoParceiros);
    }

    */
  }

  atualizarParametros(item){
    Object.keys(this.query).map((key, i) => {
      if (i > item) delete this.query[key];
    });
  }

  // buscarDados(){
  //   this.spinner.show();
  //   this.query.periodo = [];
  //   this.query.periodo.push(this.periodoInicio);
  //   this.query.periodo.push(this.periodoFim);
  //
  //   this.servicoDashboard.listarDetalhes(this.query).subscribe(
  //     res => {
  //       this.ttDescontos = res.ttDescontos;
  //       this.ttParticipantes = res.ttParticipantes;
  //       this.ttCompras = res.ttCompras;
  //       this.maxDescPeriodo = res.maxDescPeriodo;
  //       this.medDescPeriodo = res.medDescPeriodo;
  //       this.minDescPeriodo = res.minDescPeriodo;
  //       this.percUtilizacao = res.percUtilizacao;
  //       this.maxUtilPeriodo = res.maxUtilPeriodo;
  //       this.medUtilPeriodo = res.medUtilPeriodo;
  //       this.minUtilPeriodo = res.minUtilPeriodo;
  //       this.listaCategorias = res.listaCategorias;
  //       this.listaDescUtilizacao = res.lstDescUtilizacao;
  //       this.listaFornecedores = res.lstFornecedores;
  //       //--
  //       this.listaDiaria = res.lstDiaria;
  //       this.lstDescDiario = res.lstDiaria.reduce(function (r, o) {
  //         (r[o.data])? r[o.data] += o.descontos : r[o.data] = o.descontos;
  //         return r;
  //       }, {});
  //       // console.log('*** TOTALS ***');
  //       // console.log(Object.values(this.lstDescDiario));
  //       // console.log(Object.getOwnPropertyNames(this.lstDescDiario));
  //       //--
  //       this.listaMensal = res.lstMensal;
  //       this.listaRedes = res.lstRedes;
  //       this.listaEstabelecimentos = res.lstEstabelecimentos;
  //       this.listaProdutos = res.lstProdutos;
  //       this.listaDescontos = res.lstDescontos;
  //       this.oMaxCategoria = this.listaCategorias.reduce((max, cat) => max && max.vlDesconto > cat.vlDesconto ? max : cat, null);
  //       this.oMinCategoria = this.listaCategorias.reduce((min, cat) => min && min.vlDesconto < cat.vlDesconto ? min : cat, null);
  //
  //       this.atualizarGraficos();
  //
  //       // this.atualizarCarrosseis();
  //       // if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
  //       this.spinner.hide();
  //     }, (err) => {
  //       // if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
  //       this.spinner.hide();
  //       this.servicoMsg.exibeErro(err);
  //     }
  //   );
  // }

  async buscarDados(){
    this.spinner.show();
    this.query.periodo = [];
    this.query.periodo.push(this.periodoInicio);
    this.query.periodo.push(this.periodoFim);

    let res = await this.servicoDashboard.listarDetalhesAsync(this.query);

    this.ttDescontos = res.ttDescontos;
    this.ttParticipantes = res.ttParticipantes;
    this.ttCompras = res.ttCompras;
    this.maxDescPeriodo = res.maxDescPeriodo;
    this.medDescPeriodo = res.medDescPeriodo;
    this.minDescPeriodo = res.minDescPeriodo;
    this.percUtilizacao = res.percUtilizacao;
    this.maxUtilPeriodo = res.maxUtilPeriodo;
    this.medUtilPeriodo = res.medUtilPeriodo;
    this.minUtilPeriodo = res.minUtilPeriodo;
    this.listaCategorias = res.listaCategorias;
    this.listaDescUtilizacao = res.lstDescUtilizacao;
    this.listaFornecedores = res.lstFornecedores;
    //--
    if (res.lstDiaria !== null)
    {
      this.listaDiaria = res.lstDiaria;
      this.lstDescDiario = res.lstDiaria.reduce(function (r, o) {
        (r[o.data])? r[o.data] += o.descontos : r[o.data] = o.descontos;
        return r;
      }, {});
    }
    // console.log('*** TOTALS ***');
    // console.log(Object.values(this.lstDescDiario));
    // console.log(Object.getOwnPropertyNames(this.lstDescDiario));
    //--
    this.listaMensal = res.lstMensal;
    this.listaRedes = res.lstRedes;
    this.listaEstabelecimentos = res.lstEstabelecimentos;
    this.listaProdutos = res.lstProdutos;
    this.listaDescontos = res.lstDescontos;
    if (this.listaCategorias !== null)
    {
      this.oMaxCategoria = this.listaCategorias.reduce((max, cat) => max && max.vlDesconto > cat.vlDesconto ? max : cat, null);
      this.oMinCategoria = this.listaCategorias.reduce((min, cat) => min && min.vlDesconto < cat.vlDesconto ? min : cat, null);
    }

    this.atualizarGraficos();

    this.spinner.hide();
  }

  adicionarMarcadores(){
    let oUF = this.ufs.find(x => x.idEstado === parseInt(this.query.idEstado));
    if (this.estabelecimentos.length > 0){
      let itens = this.estabelecimentos.length;
      this.map = new google.maps.Map(this.gmapElement.nativeElement, this.novaPropriedade(oUF.uf));

      this.estabelecimentos.map((o, i) => {
        this.titulo = (o.estabelecimento!==null) ? o.estabelecimento.trim()+"\n" : "";
        this.titulo += (o.endereco!==null) ? o.endereco.trim() : "-";
        this.titulo += (o.endereco!=null && o.numero!=null) ? " nº "+o.numero.trim() : " S/N";
        this.titulo += (o.endereco!=null && o.complemento!=null) ? " - "+o.complemento.trim() : "";
        this.titulo += (o.endereco!=null && o.bairro!=null) ? ", "+o.bairro.trim() : "";
        this.titulo += (o.endereco!=null && o.cidade!=null) ? " - "+o.cidade.trim() : "";
        this.titulo += (o.endereco!=null && o.uf!=null) ? " - "+o.uf.trim() : "";

        this.addMarker(o.latitude, o.longitude, this.titulo);
        // if(this.estabelecimentos.length == (i + 1)){
        //   this.spinner.hide();
        // }
      });
    }
  }

  novaPropriedade(uf){
    let coo = this.coordenadasPadrao.find(x => x.uf === uf.toUpperCase());
    let mapProp = {
      center: new google.maps.LatLng(coo.lat, coo.lng),
      zoom: coo.zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    return mapProp;
  }

  addMarker(lat, lng, titulo){
    if (lat!==null && lng!==null){
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: this.map,
        // icon: 'https://s3.amazonaws.com/zicard-accentiv/trackingsales/logo_edited.png',
        title: titulo
      });

      marker.addListener('click', function(e) {
        alert('LAT: ' + e.latLng.lat() + ' / LNG: ' + e.latLng.lng());
      });
    }
  }

  atualizarCarrosseis(){
    // if (this.owlDesc){
      // let owl = $("#dscCarousel").owlCarousel({items: 1, dots: true, nav: false, loop: true, autoplay: true, autoplayTimeout: 3500, autoplayHoverPause: true});
    //   // owl.trigger('refresh.owl.carousel');

      // this.owlDesc = false;
    // //   $("#dscCarousel").load(url, function () {
    // //   let owl = $("#dscCarousel");
    // //   owl.load('', function () {
    // //     owl.trigger('destroy.owl.carousel');
    // //     owl.owlCarousel({items: 1, dots: true, nav: false, loop: true, autoplay: true, autoplayTimeout: 3500, autoplayHoverPause: true});
    // //     return false;
    // //   });
    //
      // owl.trigger('destroy.owl.carousel');
      // owl.html(owl.find('.owl-stage-outer').html()).removeClass('owl-loaded');
      // owl.owlCarousel({items: 1, dots: true, nav: false, loop: true, autoplay: true, autoplayTimeout: 3500, autoplayHoverPause: true});
     //  owl.trigger('refresh.owl.carousel');
     // }


    if (this.owlDesc){
      this.owlDesc = false;

      // let owl = document.getElementById('dscCarousel');
      // owl.trigger('destroy.owl.carousel');
      // owl.html(owl.find('.owl-stage-outer').html()).removeClass('owl-loaded');
      // owl.parentElement.innerHTML.owlCarousel({items: 1, dots: true, nav: false, loop: true, autoplay: true, autoplayTimeout: 3500, autoplayHoverPause: true});

      // console.log("*** atualizarCarrosseis ***");
      // console.log(this.owl);
      //
      // let owl = $("#prtCarousel");
      // var owlInstance = owl.data('owlCarousel');
      // if(owlInstance != null){
      //   owlInstance.reinit();
      // }

      // owl.trigger('destroy.owl.carousel');
      // owl.html(owl.find('.owl-stage-outer').html()).removeClass('owl-loaded');
      // owl.owlCarousel({items: 1, dots: true, nav: false, loop: true, autoplay: true, autoplayTimeout: 3500, autoplayHoverPause: true});
      // owl.trigger('refresh.owl.carousel');

      // console.log("Classe: " + this.owl.$owlChild.el.nativeElement.className);

      // this.owl.trigger("destroy.owl.carousel");
      // this.owl.trigger("refresh.owl.carousel");

      // $("#dscCarousel").find(".xs-slider-highlight").each(function() {
      //   $(this).trigger("destroy.owl.carousel");
      //   $(this).html($(this).find(".owl-stage-outer").html()).removeClass("owl-loaded");
      //
      //   $(this).myOwl({items: 1, dots: true, nav: false, loop: true, autoplay: true});
      //   $(this).trigger("refresh.owl.carousel");
      // });

      // this.owl.find(".xs-slider-highlight").each(function() {
      //   $(this).trigger("destroy.owl.carousel");
      //   $(this).html($(this).find(".owl-stage-outer").html()).removeClass("owl-loaded");
      //
      //   $(this).myOwl({items: 1, dots: true, nav: false, loop: true, autoplay: true});
      //   $(this).trigger("refresh.owl.carousel");
      // });
    }
  }

}
