import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicoSessao } from './srv.sessao';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../app.error-handler';

import { environment } from '../../environments/environment';

@Injectable()
export class ServicoEstado {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarEstado(frm: any) {
    return this.http.post<any>(`${environment.configuracao_api}/estado/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarEstado(frm: any) {
    return this.http.put<any>(`${environment.configuracao_api}/estado/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarEstado(id: number) {
    return this.http.get<any>(`${environment.configuracao_api}/estado/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarEstadoAsync(id: number) {
    let res = await this.http.get<any>(`${environment.configuracao_api}/estado/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  listarEstados(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/estado/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarEstadosAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.configuracao_api}/estado/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  removerEstado(id: number) {
    return this.http.delete<any>(`${environment.configuracao_api}/estado/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCidade {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarCidade(frm: any) {
    return this.http.post<any>(`${environment.configuracao_api}/cidade/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarCidade(frm: any) {
    return this.http.put<any>(`${environment.configuracao_api}/cidade/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarCidade(id: number) {
    return this.http.get<any>(`${environment.configuracao_api}/cidade/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarCidadeAsync(id: number) {
    let res = await this.http.get<any>(`${environment.configuracao_api}/cidade/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  listarCidades(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/cidade/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarCidadesAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.configuracao_api}/cidade/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  removerCidade(id: number) {
    return this.http.delete<any>(`${environment.configuracao_api}/cidade/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoLogin {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  logar(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/login/entrar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  buscarHash(hash: string): Observable<any> {
    return this.http.get<string>(`${environment.configuracao_api}/login/buscar/`+hash).pipe(catchError(ErrorHandler.handleError));
  }

  definirSenha(frm: any): Observable<any> {
    return this.http.post<Boolean>(`${environment.configuracao_api}/login/definirSenha`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  redefinirSenha(email: string): Observable<any> {
    return this.http.get<Boolean>(`${environment.configuracao_api}/login/redefinirSenha/`+email).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoUsuario {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarUsuario(id: number): Observable<any> {
    return this.http.get<any>(`${environment.configuracao_api}/usuario/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarEstrutura(): Observable<any> {
    return this.http.get<any>(`${environment.configuracao_api}/usuario/listarestrutura`).pipe(catchError(ErrorHandler.handleError));
  }

  redefinirSenha(id: number): Observable<any> {
    return this.http.get<any>(`${environment.configuracao_api}/usuario/redefinirSenha/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  alterarUsuario(frm: any): Observable<any> {
    return this.http.put<any>(`${environment.configuracao_api}/usuario/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  salvarUsuario(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/usuario/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoUsuarioProjeto {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarUsuarioProjeto(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioprojeto/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarUsuarioProjeto(frm: any) {
    return this.http.put<any>(`${environment.configuracao_api}/usuarioprojeto/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarUsuarioProjeto(id: number): Observable<any> {
    return this.http.get<any>(`${environment.configuracao_api}/usuarioprojeto/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarUsuarioProjetos(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioprojeto/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  removerUsuarioProjeto(id: number) {
    return this.http.delete<any>(`${environment.configuracao_api}/usuarioprojeto/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  removerUsuarioProjetoPorUK(obj: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioprojeto/removerporuk`, obj).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoUsuarioProduto {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarUsuarioProduto(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioproduto/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarUsuarioListaProduto(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioproduto/adicionarlista`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarUsuarioProduto(frm: any) {
    return this.http.put<any>(`${environment.configuracao_api}/usuarioproduto/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarUsuarioProduto(id: number): Observable<any> {
    return this.http.get<any>(`${environment.configuracao_api}/usuarioproduto/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarUsuarioProdutos(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioproduto/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  removerUsuarioProduto(id: number) {
    return this.http.delete<any>(`${environment.configuracao_api}/usuarioproduto/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  removerUsuarioProdutoPorUK(obj: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioproduto/removerporuk`, obj).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoUsuarioEstabelecimento {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarUsuarioEstabelecimento(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioestabelecimento/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarUsuarioListaEstabelecimento(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioestabelecimento/adicionarlista`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarUsuarioEstabelecimento(frm: any) {
    return this.http.put<any>(`${environment.configuracao_api}/usuarioestabelecimento/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarUsuarioEstabelecimento(id: number): Observable<any> {
    return this.http.get<any>(`${environment.configuracao_api}/usuarioestabelecimento/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarUsuarioestabelecimentos(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioestabelecimento/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  removerUsuarioEstabelecimento(id: number) {
    return this.http.delete<any>(`${environment.configuracao_api}/usuarioestabelecimento/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  removerUsuarioEstabelecimentoPorUK(obj: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioestabelecimento/removerporuk`, obj).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoUsuarioRede {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarUsuarioRede(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/usuariorede/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  listarUsuarioRedes(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuariorede/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  removerUsuarioRede(frm: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuariorede/remover/`, frm).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoUsuarioTipo {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarTipos(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuarioTipo/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoMenu {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarMenu(frm: any) {
    return this.http.post<any>(`${environment.configuracao_api}/menu/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarMenu(frm: any) {
    return this.http.put<any>(`${environment.configuracao_api}/menu/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarMenu(id: number) {
    return this.http.get<any>(`${environment.configuracao_api}/menu/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarMenus(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/menu/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  listarAcessos(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/menu/listaracessos`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  removerMenu(id: number) {
    return this.http.delete<any>(`${environment.configuracao_api}/menu/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoUsuarioMenu {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarUsuarioMenu(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/usuariomenu/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarUsuarioListaMenu(frm: any): Observable<any> {
    return this.http.post<any>(`${environment.configuracao_api}/usuariomenu/adicionarlista`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarUsuarioMenu(frm: any) {
    return this.http.put<any>(`${environment.configuracao_api}/usuariomenu/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarUsuarioMenu(id: number): Observable<any> {
    return this.http.get<any>(`${environment.configuracao_api}/usuariomenu/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarUsuarioMenus(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuariomenu/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  removerUsuarioMenu(id: number) {
    return this.http.delete<any>(`${environment.configuracao_api}/usuariomenu/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  removerUsuarioMenuPorUK(obj: any) {
    return this.http.post<any>(`${environment.configuracao_api}/usuariomenu/removerporuk`, obj).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoPerfil {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarPerfis(psq: any) {
    return this.http.post<any>(`${environment.configuracao_api}/perfil/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }
}
