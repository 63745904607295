import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../../servicos/srv.msg';
import { ServicoCustomLoreal } from '../../../servicos/srv.custom';

import swal from 'sweetalert2';

@Component({
  selector: 'tsv2-loreal-simula-limite-pontos',
  templateUrl: './loreal-simula-limite-pontos.component.html',
  providers: [ ServicoMsg, ServicoCustomLoreal ]
})
export class LorealSimulaLimitePontosComponent implements OnInit {

  exibirBarra: boolean = true;
  items: any = [];

  constructor(
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCustomLoreal: ServicoCustomLoreal
  ) { }

  ngOnInit() {
  }

  salvar(prd){
    let item = { "id": "-", "produto": prd.produto, "qtde": prd.qtde.replace(',','.'), "preco": prd.preco.replace(',','.'), "idStatus": 0 };

    this.items.push(item);
  }

  limpar(){
    this.items = [];
  }

  async gerarCompra(){
    let dataRef = new Date();
    let mm = dataRef.getMonth() + 1;
    let dd = dataRef.getDate();
    let hh = dataRef.getHours();
    let mi = dataRef.getMinutes();
    let sc = dataRef.getSeconds();

    let id = dataRef.getFullYear() +
    ((mm <= 9) ? '0' + mm.toString() : mm.toString()) +
    ((dd <= 9) ? '0' + dd.toString() : dd.toString()) +
    ((hh <= 9) ? '0' + hh.toString() : hh.toString()) +
    ((mi <= 9) ? '0' + mi.toString() : mi.toString()) +
    ((sc <= 9) ? '0' + sc.toString() : sc.toString());

    this.items.filter(el => { return el.id === "-" }).forEach(i => { i.id = id });
  }

  async calcular(){
    try {
      this.spinner.show();
      await this.gerarCompra();
      // console.log(this.items);

      let its = await this.servicoCustomLoreal.simularLimitePontos(this.items);
      this.items = its;
      this.spinner.hide();
    }
    catch (err) {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    }
  }
}
