import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCategoria } from '../../servicos/srv.produto';

@Component({
  selector: 'tsv2-ctg-visualizar',
  templateUrl: './visualizar.component.html',
  providers: [ ServicoCategoria, ServicoMsg ]
})
export class CategoriaVisualizarComponent implements OnInit {
  exibirBarra: boolean = true;

  p: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoCategoria: ServicoCategoria,
    private servicoMsg: ServicoMsg
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.servicoCategoria.consultarCategoria(id).subscribe(res => {
      this.p = res.lista[0];
      // this.spinner.hide();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  removerCategoria(id){
    let titulo = 'Remover a categoria?';
    let url = '/categoria/pesquisar/';
    let texto = 'Categoria removida com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoCategoria, texto).then(r1 => {
      if (r1.success){
        this.router.navigate([url]);
      }
    }, (err: any) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
