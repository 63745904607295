import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCategoria } from '../../servicos/srv.produto';

@Component({
  selector: 'tsv2-ctg-editar',
  templateUrl: './editar.component.html',
  providers: [ ServicoCategoria, ServicoMsg ]
})
export class CategoriaEditarComponent implements OnInit {
  exibirBarra: boolean = true;

  p: any;
  idCategoria: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoCategoria: ServicoCategoria,
    private servicoMsg: ServicoMsg
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.idCategoria = parseInt(id);
    this.servicoCategoria.consultarCategoria(this.idCategoria).subscribe(res => {
      this.p = res.lista[0];
      // this.spinner.hide();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  alterarCategoria(frmValues){
    this.spinner.show();
    this.servicoCategoria.alterarCategoria(frmValues).subscribe(id => {
      let url = '/categoria/visualizar/' + id;
      this.spinner.hide();
      this.servicoMsg.exibeSucesso('Alterada!', 'Categoria alterada com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
