import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoRede } from '../../servicos/srv.rede';

@Component({
  selector: 'tsv2-rde-listar',
  templateUrl: './listar.component.html',
  providers: [ ServicoMsg, ServicoRede ]
})
export class RedeListarComponent implements OnInit {
  @Input() items: any[];
  @Input() pagina: number;
  @Input() permissao: any;

  @Output() trocar = new EventEmitter();

  constructor(
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoRede: ServicoRede
  ) {
    this.items = [];
    this.pagina = 1;
  }

  ngOnInit() {
  }

  removerRede(id){
    let titulo = 'Remover a rede?';
    let texto = 'Rede removida com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoRede, texto).then(r1 => {
      if (r1.success){
        this.trocar.emit(this.pagina);
      }
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
