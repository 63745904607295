import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoEstabelecimento, ServicoRede } from '../../servicos/srv.rede';
import { ServicoCidade, ServicoEstado } from '../../servicos/srv.configuracao';

@Component({
  selector: 'tsv2-stb-editar',
  templateUrl: './editar.component.html',
  providers: [ ServicoEstabelecimento, ServicoMsg, ServicoRede, ServicoCidade, ServicoEstado ]
})
export class EstabelecimentoEditarComponent implements OnInit {
  exibirBarra: boolean = true;

  redes: any;
  ufs: any;
  cidades: any;
  p: any;
  cnpj: number;
  idRede: number;
  cepValido: boolean;
  query: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoMsg: ServicoMsg,
    private servicoRede: ServicoRede,
    private servicoCidade: ServicoCidade,
    private servicoEstado: ServicoEstado
  ) { }

  ngOnInit() {
    // this.spinner.show();
    this.cepValido = true;
    const cnpj = this.route.snapshot.params['id'];
    this.cnpj = parseInt(cnpj);
    this.servicoEstabelecimento.consultarEstabelecimento(this.cnpj).subscribe(res => {
      this.p = res.lista[0];
      this.servicoRede.listarRedes({"idEstado": -1}).subscribe(rsp => {
        this.redes = rsp.lista;
        this.servicoEstado.listarEstados({}).subscribe(estados => {
          this.ufs = estados.lista;
          this.servicoCidade.listarCidades({"idEstado": this.p.idEstado}).subscribe(cidades => {
            this.cidades = cidades.lista;
            // this.spinner.hide();
          }, (err) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          })
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  listarCidades(idEstado){
    // if (!this.spinner.spinnerObservable.closed) this.spinner.show();

    this.servicoCidade.listarCidades({"idEstado": idEstado}).subscribe(cidades => {
      this.cidades = cidades.lista;

      // if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
    }, (err) => {
      // if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
      this.servicoMsg.exibeErro(err);
    });
  }

  alterarEstabelecimento(frmValues){
    // this.spinner.show();
    this.servicoEstabelecimento.alterarEstabelecimento(frmValues).subscribe(id => {
      let url = '/estabelecimento/visualizar/' + id;
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Alterado!', 'Estabelecimento alterado com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  validaCEP(valor){
    var vl = valor.replace(/[^0-9]/g, "");

    if (vl.length > 0 && vl.length < 8) {
      this.cepValido = false;
    }
    else {
      this.cepValido = true;
    }
  }

}
