import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

import swal from 'sweetalert2';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoClienteGrupo, ServicoClienteGrupoCliente } from '../../servicos/srv.cliente';
import { ServicoCampanhaClienteGrupo } from '../../servicos/srv.campanha';

@Component({
  selector: 'tsv2-cligrp-visualizar',
  templateUrl: './visualizar.component.html',
  providers: [ ServicoMsg, ServicoCampanhaClienteGrupo, ServicoClienteGrupo, ServicoClienteGrupoCliente ]
})
export class ClienteGrupoVisualizarComponent implements OnInit {
  exibirBarra: boolean = true;

  p: any;
  associados: any;
  removeAvailable: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCampanhaClienteGrupo: ServicoCampanhaClienteGrupo,
    private servicoClienteGrupo: ServicoClienteGrupo,
    private servicoClienteGrupoCliente: ServicoClienteGrupoCliente
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.servicoClienteGrupo.consultarClienteGrupo(id).subscribe(res => {
      this.p = res.lista[0];
      let obj = {"idClienteGrupo" : id};
      this.servicoClienteGrupoCliente.listarClienteGrupoCliente(obj).subscribe(res => {
        // this.spinner.hide();
        this.associados = res.lista;
        this.servicoCampanhaClienteGrupo.listarCampanhaClienteGrupos(obj).subscribe(res => {
          this.removeAvailable = res.lista.length == 0 ? false : true;
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
      // this.spinner.hide();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  removerClienteGrupo(id){
    let titulo = 'Remover o grupo de cliente?';
    let url = '/clienteGrupo/pesquisar/';
    let texto = 'Grupo de Cliente removido com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoClienteGrupo, texto).then(r1 => {
      if (r1.success){
        this.router.navigate([url]);
      }
    }, (err: any) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
