import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

import { ServicoCliente } from '../../servicos/srv.cliente';
import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';
import { ServicoProjeto } from '../../servicos/srv.projeto';
import { ServicoSessao } from '../../servicos/srv.sessao';

@Component({
  selector: 'tsv2-cli-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoCliente, ServicoMsg, ServicoPaginacao, ServicoProjeto, ServicoSessao ]
})
export class ClientePesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  prj: any;
  resultado: any;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  paginas: any;
  atual: number;

  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCliente: ServicoCliente,
    private servicoPaginacao: ServicoPaginacao,
    private servicoProjeto: ServicoProjeto,
    private servicoSessao: ServicoSessao,
    private localeService: BsLocaleService
  ) {
    this.opcaoPadrao = -1;
    this.qtdeItensPadrao = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;

    this.bsConfig = Object.assign({}, {
      containerClass: this.colorTheme
    });

    this.localeService.use('pt-br');
  }

  ngOnInit() {
    // this.spinner.show();
    this.prj = this.servicoSessao.buscarProjeto();
    if (this.prj.hasOwnProperty("projetoId")){
      this.servicoProjeto.listarProjetos({ "idProjeto": this.prj.projetoId }).subscribe(res => {
        this.prj = res.lista[0];
        // this.spinner.hide();
      }, (err: any) => {
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
    else {
      // this.spinner.hide();
      this.servicoMsg.exibeErro({"statusCode": 999});
    }
    // this.spinner.hide();
  }

  pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      // this.spinner.show();
      this.pesquisa = 1;
      frmValues.pagina = pagina;
      frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);
      frmValues.idProjeto = this.prj.idProjeto;

      this.servicoCliente.listarClientes(frmValues).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

}
