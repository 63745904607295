import { Component } from '@angular/core';

@Component({
  selector: 'tsv2-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'Tracking Sales V2';

  // ngOnInit() {
  // }
}
