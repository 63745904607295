import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as FileSaver from 'file-saver';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoEstabelecimento, ServicoRede } from '../../servicos/srv.rede';
import { ServicoCategoria, ServicoFornecedor, ServicoMarca, ServicoProduto, ServicoProdutoPromocao } from '../../servicos/srv.produto';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'tsv2-prm-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoMsg, ServicoCategoria, ServicoEstabelecimento, ServicoFornecedor, ServicoMarca, ServicoPaginacao, ServicoProduto, ServicoProdutoPromocao, ServicoRede ]
})
export class PromocaoPesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  categorias: any;
  fornecedores: any;
  marcas: any;
  produtos: any;
  redes: any;
  estabelecimentos: any;
  resultado: any;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  paginas: any;
  query: any = {};
  periodo: any = [];
  hoje = new Date();
  idsProduto: string[] = [];

  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private spinner: NgxSpinnerService,
    private servicoCategoria: ServicoCategoria,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMsg: ServicoMsg,
    private servicoMarca: ServicoMarca,
    private servicoProduto: ServicoProduto,
    private servicoProdutoPromocao: ServicoProdutoPromocao,
    private servicoRede: ServicoRede,
    private servicoPaginacao: ServicoPaginacao,
    private localeService: BsLocaleService
  ) {
    this.qtdeItensPadrao = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;

    this.localeService.use('pt-br');
  }

  ngOnInit() {
    this.spinner.show();
    let inicio = new Date(this.hoje.getFullYear(),this.hoje.getMonth(),parseInt("01"));
    this.bsConfig = Object.assign({}, { maxDate: this.hoje, containerClass: this.colorTheme });
    this.periodo.push(inicio);
    this.periodo.push(this.hoje);

    this.listarRedes();

    this.listarCategorias();

    this.listarFornecedores();
    this.spinner.hide();
  }

  listarRedes(){
    // this.query.idRede = -1;
    this.servicoRede.listarRedes({}).subscribe(redes => {
      this.redes = redes.lista;

      this.query.idRede = redes.lista[0].idRede;
      if (redes.lista.length===1) {
        this.listarEstabelecimentos();
      }
      else {
        // this.redes = null;
        this.spinner.hide();
      }
    }, (err) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  listarEstabelecimentos(){
    this.query.cnpj = -1;
    if (this.query.idRede > 0) {
      this.servicoEstabelecimento.listarEstabelecimentos(this.query).subscribe(
        estabelecimentos => {
          this.estabelecimentos = estabelecimentos.lista;

          if (estabelecimentos.lista.length===1){
            this.query.cnpj = estabelecimentos.lista[0].cnpj;
          }
          else {
            this.spinner.hide();
          }
        }, (err) => {
          this.servicoMsg.exibeErro(err);
        }
      );
    }
    else {
      this.estabelecimentos = [];
      this.query.cnpj = -1;
    }
  }

  listarCategorias(){
    this.servicoCategoria.listarCategorias({}).subscribe(categorias => {
      this.categorias = categorias.lista;
      this.query = Object.assign(this.query, {"idCategoria" : -1});

      if (categorias.lista.length === 1){
        this.query.idCategoria = categorias.lista[0].idCategoria;

        this.listarFornecedores();
      }
      else {
        this.spinner.hide();
      }
    }, (err) => {
      if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
      this.servicoMsg.exibeErro(err);
    });
  }

  listarFornecedores(){
    this.servicoFornecedor.listarFornecedores(this.query).subscribe(fornecedores => {
      this.fornecedores = fornecedores.lista;
      this.query = Object.assign(this.query, {"idFornecedor" : -1});

      if (fornecedores.lista.length === 1){
        this.query.idFornecedor = fornecedores.lista[0].idFornecedor;

        this.listarMarcas();
      }
      else {
        this.spinner.hide();
      }
    }, (err) => {
      if (!this.spinner.spinnerObservable.closed) this.spinner.hide();
      this.servicoMsg.exibeErro(err);
    });
  }

  listarMarcas(){
    if (this.query.idFornecedor > 0) {
      this.servicoMarca.listarMarcas(this.query).subscribe(
        marcas => {
          this.marcas = marcas.lista;
          this.query = Object.assign(this.query, {"idMarca" : -1});

          if (marcas.lista.length === 1){
            this.query.idMarca = marcas.lista[0].idMarca;
            this.listarProdutos();
          }
          else {
            this.listarProdutos();
            this.spinner.hide();
          }
        }
      )
    }
    else {
      this.marcas = [];
      this.query.idMarca = -1;
    }
  }

  listarProdutos(){
    this.query.idProduto = -1;
    this.servicoProduto.listarProdutos(this.query).subscribe(
      produtos => {
        this.produtos = produtos.lista;

        if (produtos.lista.length===1){
          this.query.idProduto = produtos.lista[0].idProduto;
        }
        this.listarCategorias();
      }, (err) => {
        this.produtos = [];
        this.servicoMsg.exibeErro(err);
      }
    );
  }

  pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      this.spinner.show();
      this.pesquisa = 1;
      frmValues.pagina = pagina;
      frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);
      delete frmValues.idProduto;
      frmValues.idsProduto = this.idsProduto.join(',');

      this.servicoProdutoPromocao.listarPromocao(frmValues).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
  }

  baixar(frmValues){
    this.spinner.show();
    if (frmValues.hasOwnProperty("itensPagina")){
      delete frmValues.itensPagina;
    }
    if (frmValues.hasOwnProperty("pagina")){
      delete frmValues.pagina;
    }

    this.servicoProdutoPromocao.listarPromocao(frmValues).subscribe(res => {
      const replacer = (key, value) => value === null ? '' : value;
      const header = Object.keys(res.lista[0]);
      let csv = res.lista.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
      csv.unshift(header.join(';'));
      let BOM = "\uFEFF";
      let csvArray = BOM + csv.join('\r\n');

      let blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
      let baixa = new Date();
      let mm = baixa.getMonth() + 1;
      let dd = baixa.getDate();
      let hh = baixa.getHours();
      let mi = baixa.getMinutes();
      let sc = baixa.getSeconds();

      let arq = baixa.getFullYear() + '-' +
      ((mm <= 9) ? '0' + mm.toString() : mm.toString()) + '-' +
      ((dd <= 9) ? '0' + dd.toString() : dd.toString()) + '_' +
      ((hh <= 9) ? '0' + hh.toString() : hh.toString()) + '-' +
      ((mi <= 9) ? '0' + mi.toString() : mi.toString()) + '-' +
      ((sc <= 9) ? '0' + sc.toString() : sc.toString());

      FileSaver.saveAs(blob, arq + '.csv');
      this.spinner.hide();
    }, (err: any) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err);
    });
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }
}
