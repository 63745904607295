import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicoSessao } from './srv.sessao';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../app.error-handler'

import { environment } from '../../environments/environment';

@Injectable()
export class ServicoProjeto {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarProjetos(psq: any) {
    return this.http.post<any>(`${environment.projeto_api}/projeto/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  listarAcessos(psq: any) {
    return this.http.post<any>(`${environment.projeto_api}/projeto/listaracessos`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  consultarProjeto(id: number) {
    return this.http.get<any>(`${environment.projeto_api}/projeto/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  alterarProjeto(frm: any) {
    return this.http.put<any>(`${environment.projeto_api}/projeto/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarProjeto(frm: any) {
    return this.http.post<any>(`${environment.projeto_api}/projeto/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.projeto_api}/projeto/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoProjetoAberturaIdentificacao {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarProjetoAberturaIdentificacao(psq: any) {
    return this.http.post<any>(`${environment.projeto_api}/projetoAberturaIdentificacao/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  consultarProjetoAberturaIdentificacao(id: number) {
    return this.http.get<any>(`${environment.projeto_api}/projetoAberturaIdentificacao/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  alterarProjetoAberturaIdentificacao(frm: any) {
    return this.http.put<any>(`${environment.projeto_api}/projetoAberturaIdentificacao/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarProjetoAberturaIdentificacao(frm: any) {
    return this.http.post<any>(`${environment.projeto_api}/projetoAberturaIdentificacao/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  removerProjetoAberturaIdentificacao(id: number) {
    return this.http.delete<any>(`${environment.projeto_api}/projetoAberturaIdentificacao/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoProjetoTipo {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarProjetoTipo(psq: any) {
    return this.http.post<any>(`${environment.projeto_api}/projetoTipo/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  consultarProjetoTipo(id: number) {
    return this.http.get<any>(`${environment.projeto_api}/projetoTipo/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  alterarProjetoTipo(frm: any) {
    return this.http.put<any>(`${environment.projeto_api}/projetoTipo/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarProjetoTipo(frm: any) {
    return this.http.post<any>(`${environment.projeto_api}/projetoTipo/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  removerProjetoTipo(id: number) {
    return this.http.delete<any>(`${environment.projeto_api}/projetoTipo/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoProjetoTipoCaptura {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarProjetoTipoCaptura(psq: any) {
    return this.http.post<any>(`${environment.projeto_api}/projetoTipoCaptura/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  consultarProjetoTipoCaptura(id: number) {
    return this.http.get<any>(`${environment.projeto_api}/projetoTipoCaptura/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  alterarProjetoTipoCaptura(frm: any) {
    return this.http.put<any>(`${environment.projeto_api}/projetoTipoCaptura/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarProjetoTipoCaptura(frm: any) {
    return this.http.post<any>(`${environment.projeto_api}/projetoTipoCaptura/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  removerProjetoTipoCaptura(id: number) {
    return this.http.delete<any>(`${environment.projeto_api}/projetoTipoCaptura/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoProjetoTipoIdentificacao {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarProjetoTipoIdentificacao(psq: any) {
    return this.http.post<any>(`${environment.projeto_api}/projetoTipoIdentificacao/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  consultarProjetoTipoIdentificacao(id: number) {
    return this.http.get<any>(`${environment.projeto_api}/projetoTipoIdentificacao/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  alterarProjetoTipoIdentificacao(frm: any) {
    return this.http.put<any>(`${environment.projeto_api}/projetoTipoIdentificacao/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  adicionarProjetoTipoIdentificacao(frm: any) {
    return this.http.post<any>(`${environment.projeto_api}/projetoTipoIdentificacao/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  removerProjetoTipoIdentificacao(id: number) {
    return this.http.delete<any>(`${environment.projeto_api}/projetoTipoIdentificacao/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}
