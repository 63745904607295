import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

import { ServicoEstado, ServicoCidade } from '../../servicos/srv.configuracao';
import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCampanha, ServicoCampanhaTipoDesconto, ServicoCampanhaTipoLimite, ServicoCampanhaTipoPonto, ServicoCampanhaTipoSorteio, ServicoCampanhaRequisito, ServicoCampanhaRequisitoAplicacao, ServicoCampanhaProduto, ServicoCampanhaClienteGrupo, ServicoCampanhaTipoRequisito } from '../../servicos/srv.campanha';
import { ServicoClienteGrupo } from '../../servicos/srv.cliente';
import { ServicoRede, ServicoEstabelecimento } from '../../servicos/srv.rede';
import { ServicoFornecedor, ServicoProduto } from '../../servicos/srv.produto';

import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'tsv2-cmp-editar',
  templateUrl: './editar.component.html',
  providers: [ ServicoCampanha, ServicoCampanhaTipoDesconto, ServicoCampanhaTipoLimite, ServicoCampanhaTipoPonto, ServicoCampanhaTipoSorteio, ServicoCampanhaClienteGrupo, ServicoCampanhaProduto, ServicoCampanhaRequisito, ServicoCampanhaRequisitoAplicacao, ServicoCampanhaTipoRequisito, ServicoCidade, ServicoClienteGrupo, ServicoEstabelecimento, ServicoEstado, ServicoFornecedor, ServicoMsg, ServicoProduto, ServicoRede ]
})
export class CampanhaEditarComponent implements OnInit {
  @ViewChild('pageContent') pageContent: ElementRef;

  exibirBarra: boolean = true;

  campanhaClienteGrupos: any;
  clienteGrupos: any;
  filtroExtra: any;
  listaFornecedores: any;
  listaProdutos: any;
  p: any;
  produtos: any;
  requisitos: any;
  requisitoAplicacoes: any;
  prj: any;
  tipoDescontos: any;
  tipoLimites: any;
  tipoPontos: any;
  tipoRequisitos: any;
  tipoSorteios: any;
  valores: any;
  cp: any; //campanha produto

  idCampanha: number;
  tipoRequisito: string;
  idSelecionado: number;

  popProdutos: boolean = false;
  prdsAprovados: boolean = true;

  // tipoDescontos: any;
  // tipoLimites: any;
  // tipoPontos: any;

  colorTheme = 'theme-default';
  bsConfig: Partial<BsDatepickerConfig>;
  bsRangeValue: Date[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoCampanha: ServicoCampanha,
    private servicoCampanhaDesconto: ServicoCampanhaTipoDesconto,
    private servicoCampanhaLimite: ServicoCampanhaTipoLimite,
    private servicoCampanhaPonto: ServicoCampanhaTipoPonto,
    private servicoCampanhaSorteio: ServicoCampanhaTipoSorteio,
    private servicoCampanhaClienteGrupo: ServicoCampanhaClienteGrupo,
    private servicoCampanhaProduto: ServicoCampanhaProduto,
    private servicoCampanhaRequisito: ServicoCampanhaRequisito,
    private servicoCampanhaRequisitoAplicacao: ServicoCampanhaRequisitoAplicacao,
    private servicoCampanhaTipoRequisito: ServicoCampanhaTipoRequisito,
    private servicoClienteGrupo: ServicoClienteGrupo,
    private servicoCidade: ServicoCidade,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoEstado: ServicoEstado,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMsg: ServicoMsg,
    private servicoProduto: ServicoProduto,
    private servicoRede: ServicoRede,
    private localeService: BsLocaleService
  ) {
    this.bsConfig = Object.assign({}, {
      containerClass: this.colorTheme
    });

    this.localeService.use('pt-br');
  }

  ngOnInit() {
    $(document).ready(function(){
      $('#smartwizard').smartWizard({
        theme: 'dots',
        lang: {
          next: 'Próximo',
          previous: 'Anterior'
        },
        keyboardSettings: {
          keyNavigation: false,
          keyLeft: [26],
          keyRight: [27]
        },
        toolbarSettings: {
          showNextButton: false,
          showPreviousButton: false
        }
      });

      $("#smartwizard").on("showStep", function(e, anchorObject, stepNumber, stepDirection, stepPosition) {
        if(stepPosition === 'first'){
          $("#prev-btn").addClass('disabled');
        }else if(stepPosition === 'final'){
          $("#next-btn").addClass('disabled');
        }else{
          $("#prev-btn").removeClass('disabled');
          $("#next-btn").removeClass('disabled');
        }
      });

      $("#prev-btn").on("click", function() {
        $('#smartwizard').smartWizard("prev");
        // atualizarDados();
        return true;
      });

      $("#next-btn").on("click", function() {
        $('#smartwizard').smartWizard("next");
        // atualizarDados();
        return true;
      });
    });

    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.idCampanha = parseInt(id);
    this.servicoCampanha.consultarCampanha(id).subscribe(res => {
      this.p = res.lista[0];

      let i = this.p.inicio.substring(0,10).split('-');
      let f = this.p.fim.substring(0,10).split('-');
      this.bsRangeValue = [
        new Date(parseInt(i[0]),parseInt(i[1])-1,parseInt(i[2])),
        new Date(parseInt(f[0]),parseInt(f[1])-1,parseInt(f[2]))
      ];

      this.servicoCampanhaRequisito.listarCampanhaRequisitos({"idCampanha": id}).subscribe(res => {
        this.requisitos = res.lista;
        this.servicoCampanhaTipoRequisito.listarCampanhaTipoRequisitos({}).subscribe(res => {
          this.tipoRequisitos = res.lista;
          this.servicoCampanhaRequisitoAplicacao.listarCampanhaRequisitoAplicacoes({}).subscribe(res => {
            this.requisitoAplicacoes = res.lista;
            this.servicoCampanhaProduto.listarCampanhaProdutos({"idCampanha": id}).subscribe(res => {
              this.produtos = res.lista;
              this.servicoCampanhaClienteGrupo.listarCampanhaClienteGrupos({"idCampanha": id}).subscribe(res => {
                this.campanhaClienteGrupos = res.lista;
                let listaCampanhaClienteGrupos = res.lista;
                this.servicoClienteGrupo.listarClienteGrupo({}).subscribe(res => {
                  this.clienteGrupos = res.lista.filter(function(obj) {
                    return !listaCampanhaClienteGrupos.some(function(obj2) {
                      return obj.idClienteGrupo == obj2.idClienteGrupo;
                    });
                  });
                  // this.servicoFornecedor.listarFornecedores({}).subscribe(res => {
                  //   this.listaFornecedores = res.lista;
                    this.servicoCampanhaDesconto.listarCampanhaTipoDesconto({}).subscribe(res => {
                      this.tipoDescontos = res.lista;
                      this.servicoCampanhaLimite.listarCampanhaTipoLimite({}).subscribe(res => {
                        this.tipoLimites = res.lista;
                        this.servicoCampanhaPonto.listarCampanhaTipoPonto({}).subscribe(res => {
                          this.tipoPontos = res.lista;
                          this.servicoCampanhaSorteio.listarCampanhaTipoSorteio({}).subscribe(res => {
                            this.tipoSorteios = res.lista;
                          }, (err) => {
                            // this.spinner.hide();
                            this.servicoMsg.exibeErro(err).then(r1 => {});
                          });
                        }, (err) => {
                          // this.spinner.hide();
                          this.servicoMsg.exibeErro(err).then(r1 => {});
                        });
                      }, (err) => {
                        // this.spinner.hide();
                        this.servicoMsg.exibeErro(err).then(r1 => {});
                      });
                    }, (err) => {
                      // this.spinner.hide();
                      this.servicoMsg.exibeErro(err).then(r1 => {});
                    });
                    // this.spinner.hide();
                  // }, (err) => {
                  //   // this.spinner.hide();
                  //   this.servicoMsg.exibeErro(err).then(r1 => {});
                  // });
                }, (err: any) => {
                  // this.spinner.hide();
                  this.servicoMsg.exibeErro(err).then(r1 => {});
                });
              }, (err) => {
                // this.spinner.hide();
                this.servicoMsg.exibeErro(err).then(r1 => {});
              });
            }, (err) => {
              // this.spinner.hide();
              this.servicoMsg.exibeErro(err).then(r1 => {});
            });
          }, (err) => {
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }, (err) => {
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  exibirBarraNav(exibir) {
    this.exibirBarra = exibir;

    this.configAppComponent(exibir);
  }

  configAppComponent(exibir) {
    let pageContainer = this.pageContent.nativeElement.parentNode.parentNode;
    let classe = "page-container-wide";
    let arr = pageContainer.className.split(" ");
    if (!exibir) {
      if (arr.indexOf(classe) == -1) {
        pageContainer.className += " " + classe;
      }
    }
    else {
      let i = arr.indexOf(classe);
      if (i >= 0) {
        let classeRemovida = arr.splice(i, 1);
        pageContainer.className = arr.toString();
      }
    }
  }

  buscarFiltro(id){
    // this.spinner.show();
    this.tipoRequisito = id;
    if (id=="1"){ //CNPJ
      this.valores = [];
      this.servicoRede.listarRedes({}).subscribe(res => {
        this.filtroExtra = res.lista.map(item => {
          return { "id": item.idRede, "nome": item.rede };
        });
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (id=="2"){ //REDE
      this.filtroExtra = [];

      this.servicoRede.listarRedes({}).subscribe(res => {
        this.valores = res.lista.map(item => {
          // this.spinner.hide();
          return { "valor": item.idRede, "valorDesc": item.rede };
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (id=="3"){ //CIDADE
      this.valores = [];
      this.servicoEstado.listarEstados({"idEstado":-1}).subscribe(res => {
        this.filtroExtra = res.lista.map(item => {
          // this.spinner.hide();
          return { "id": item.idEstado, "nome": item.uf };
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (id=="4"){ //ESTADO
      this.filtroExtra = [];

      this.servicoEstado.listarEstados({"idEstado":-1}).subscribe(res => {
        this.valores = res.lista.map(item => {
          return { "valor": item.idEstado, "valorDesc": item.uf };
        });
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
  }

  aplicarFiltro(id){
    // this.spinner.show();
    if (this.tipoRequisito=="1"){ //CNPJ
      this.servicoEstabelecimento.listarEstabelecimentos({"idRede": id}).subscribe(res => {
        this.valores = res.lista.map(item => {
          // this.spinner.hide();
          return { "valor": item.idRede, "valorDesc": item.estabelecimento + ' - CNPJ: ' + item.cnpj };
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (this.tipoRequisito=="2"){ //REDE
      this.filtroExtra = [];
    }
    else if (this.tipoRequisito=="3"){ //CIDADE
      this.servicoCidade.listarCidades({"idEstado": id}).subscribe(res => {
        this.valores = res.lista.map(item => {
          // this.spinner.hide();
          return { "valor": item.idCidade, "valorDesc": item.cidade };
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (this.tipoRequisito=="4"){ //ESTADO
      this.filtroExtra = [];
    }
  }

  salvarRequisito(frmReq){
    frmReq.idCampanha = this.idCampanha;
    frmReq.ativo = frmReq.ativoReq;
    delete frmReq.ativoReq;
    this.servicoCampanhaRequisito.adicionarCampanhaRequisito(frmReq).subscribe(id => {
      // this.spinner.hide();
      swal(
        'Criado!',
        'Requisito criado com sucesso!',
        'success'
      ).then((result) => {
        if (result.value) {
          // this.spinner.show();
          this.servicoCampanhaRequisito.listarCampanhaRequisitos({"idCampanha": this.idCampanha}).subscribe(res => {
            this.requisitos = res.lista;
            // this.spinner.hide();
          }, (err: any) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err);
          });
        }
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err);
    });
  }

  removerRequisito(id){
    let titulo = 'Remover o requisito da campanha?';
    let url = '/campanha/pesquisar/';
    let texto = 'Requisito removido com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoCampanhaRequisito, texto).then(r1 => {
      if (r1.success){
        // this.spinner.show();
        this.servicoCampanhaRequisito.listarCampanhaRequisitos({"idCampanha": this.idCampanha}).subscribe(res => {
          this.requisitos = res.lista;
          // this.spinner.hide();
        }, (err: any) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }
    });
  }

  salvarProduto(frmPrd){
    // this.spinner.show();
    frmPrd.idCampanha = this.idCampanha;
    frmPrd.limite = this.formatarNum(frmPrd.limite);
    frmPrd.desconto = this.formatarNum(frmPrd.desconto);
    frmPrd.aporteDesconto = this.formatarNum(frmPrd.aporteDesconto);
    frmPrd.ponto = this.formatarNum(frmPrd.ponto);
    frmPrd.limitePonto = this.formatarNum(frmPrd.limitePonto);
    frmPrd.precoRef = this.formatarNum(frmPrd.precoRef);
    frmPrd.ativo = frmPrd.ativoPrd;
    delete frmPrd.ativoPrd;
    this.servicoCampanhaProduto.adicionarCampanhaProduto(frmPrd).subscribe(id => {
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Criado!', 'Produto vinculado com sucesso!').then(r1 => {
        if (r1.success){
          // this.spinner.show();
          this.servicoCampanhaProduto.listarCampanhaProdutos({"idCampanha": this.idCampanha}).subscribe(res => {
            this.produtos = res.lista;
            // this.spinner.hide();
          }, (err: any) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  exibirPopProdutos(){
    setTimeout(() => {
      // process.exit();
      this.popProdutos = (this.popProdutos) ? false : true;
    }, 500);
  }

  async novoProduto(){
    this.cp = {};
    this.listaFornecedores = await this.servicoFornecedor.listarFornecedoresAsync({"idFornecedor": this.cp.idFornecedor});
    this.exibirPopProdutos();
  }

  async editarProduto(idCampanhaProduto){
    this.cp = await this.servicoCampanhaProduto.consultarCampanhaProduto(idCampanhaProduto);
    this.listaFornecedores = await this.servicoFornecedor.listarFornecedoresAsync({"idFornecedor": this.cp.idFornecedor});
    this.listaProdutos = await this.servicoProduto.listarProdutosAsync({"idProduto": this.cp.idProduto});
    this.exibirPopProdutos();
    // console.log("idProduto: " + this.cp.idProduto);
    // console.log("Lista Produtos: " + JSON.stringify(this.listaProdutos));
    // this.servicoCampanhaProduto.consultarCampanhaProduto(idCampanhaProduto).subscribe(res => {
    //   this.cp = res.lista[0];
    //   this.listaProdutos = this.buscarProdutos02(this.cp.idFornecedor);
    //   this.exibirPopProdutos();
    // }, (err: any) => {
    //   this.servicoMsg.exibeErro(err);
    // });
  }

  removerProduto(id){
    let titulo = 'Remover vinculo c/ o produto?';
    let texto = 'Vinculo removido com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoCampanhaProduto, texto).then(r1 => {
      if (r1.success){
        // this.spinner.show();
        this.servicoCampanhaProduto.listarCampanhaProdutos({"idCampanha": this.idCampanha}).subscribe(res => {
          this.produtos = res.lista;
          // this.spinner.hide();
        }, (err: any) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }
    });
  }

  salvarGrupo(frmGrp){
    // this.spinner.show();
    frmGrp.idCampanha = this.idCampanha;
    this.servicoCampanhaClienteGrupo.adicionarCampanhaClienteGrupo(frmGrp).subscribe(id => {
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Criado!', 'Grupo vinculado com sucesso!').then(r1 => {
        if (r1.success){
          // this.spinner.show();
          this.servicoCampanhaClienteGrupo.listarCampanhaClienteGrupos({"idCampanha": this.idCampanha}).subscribe(res => {
            this.campanhaClienteGrupos = res.lista;
            // this.spinner.hide();
          }, (err: any) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  removerGrupo(id){
    let titulo = 'Remover vinculo c/ o grupo?';
    let texto = 'Vinculo removido com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoCampanhaClienteGrupo, texto).then(r1 => {
      if (r1.success){
        // this.spinner.show();
        this.servicoCampanhaClienteGrupo.listarCampanhaClienteGrupos({"idCampanha": this.idCampanha}).subscribe(res => {
          this.campanhaClienteGrupos = res.lista;
          // this.spinner.hide();
        }, (err: any) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  alterarCampanha(frmCmp){
    this.spinner.show();
    this.servicoCampanha.alterarCampanha(frmCmp).subscribe(idCampanha => {
      let url = '/campanha/editar/' + idCampanha;
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Alterada!', 'Campanha alterada com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      });
    }, (err: any) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  buscarProdutos(idFornecedor){
    this.servicoProduto.listarProdutos({"idFornecedor": idFornecedor}).subscribe(res => {
      this.listaProdutos = res.lista;
    }, (err: any) => {
      this.servicoMsg.exibeErro(err);
    });
  }

  formatarNum(value){
    return (value !== undefined && value !== null) ? value.toString().replace('.','').replace(',','.') : value;
  }

  aprovarProdutos(e){
    this.prdsAprovados= e.target.checked;
    let titulo = 'Aprovar os produtos abaixo?';
    let texto = 'Produtos aprovados com sucesso!';
    this.servicoMsg.exibeSucesso('Aprovado!', 'Produtos aprovados com sucesso!').then(r1 => {
      if (r1.success){
        // this.spinner.show();
        // this.servicoCampanhaClienteGrupo.listarCampanhaClienteGrupos({"idCampanha": this.idCampanha}).subscribe(res => {
        //   this.campanhaClienteGrupos = res.lista;
        //   // this.spinner.hide();
        // }, (err: any) => {
        //   // this.spinner.hide();
        //   this.servicoMsg.exibeErro(err).then(r1 => {});
        // });
        console.log('PRODUTOS APROVADOS');
      }
      else {
        console.log('PRODUTOS REPROVADOS');
      }
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }
}
