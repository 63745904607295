import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as FileSaver from 'file-saver';

import { ServicoCampanha } from '../../servicos/srv.campanha';
import { ServicoClienteDesconto } from '../../servicos/srv.cliente';
import { ServicoDashboard } from '../../servicos/srv.dashboard';
import { ServicoEstabelecimento, ServicoRede } from '../../servicos/srv.rede';
import { ServicoEstado, ServicoCidade } from '../../servicos/srv.configuracao';
import { ServicoCategoria, ServicoFornecedor, ServicoProduto, ServicoMarca } from '../../servicos/srv.produto';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';
import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoSessao } from '../../servicos/srv.sessao';
import { Util } from '../../servicos/srv.util';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'tsv2-desc-pendente-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [
    ServicoCampanha,
    ServicoCategoria,
    ServicoCidade,
    ServicoClienteDesconto,
    ServicoDashboard,
    ServicoEstabelecimento,
    ServicoEstado,
    ServicoFornecedor,
    ServicoMarca,
    ServicoMsg,
    ServicoPaginacao,
    ServicoProduto,
    ServicoRede,
    ServicoSessao,
    Util
  ]
})
export class DescontoPendentePesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  resultado: any;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  paginas: any;
  atual: number;
  query: any = {};
  campanhas: any;
  categorias: any;
  cidades: any;
  estabelecimentos: any;
  redes: any;
  ufs: any;
  prj: any;
  fornecedores: any;
  marcas: any;
  produtos: any;
  grid: boolean = true;
  cnpjValido: boolean = true;
  idsProduto: string[] = [];

  hoje = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  periodo: any = [];

  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig> = {};

  constructor(
    private spinner: NgxSpinnerService,
    private servicoCampanha: ServicoCampanha,
    private servicoCategoria: ServicoCategoria,
    private servicoCidade: ServicoCidade,
    private servicoClienteDesconto: ServicoClienteDesconto,
    private servicoDashboard: ServicoDashboard,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoEstado: ServicoEstado,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMarca: ServicoMarca,
    private servicoMsg: ServicoMsg,
    private servicoPaginacao: ServicoPaginacao,
    private servicoProduto: ServicoProduto,
    private servicoRede: ServicoRede,
    private servicoSessao: ServicoSessao,
    private localeService: BsLocaleService,
    private util: Util
  ) {
    this.opcaoPadrao = -1;
    this.qtdeItensPadrao = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;

    this.localeService.use('pt-br');
  }

  async ngOnInit() {
    this.spinner.show();
    this.prj = this.servicoSessao.buscarProjeto();
    if (this.prj.hasOwnProperty("projetoId")){
      let inicio = new Date(this.hoje.getFullYear(),this.hoje.getMonth(),parseInt("01"));
      this.bsConfig = { maxDate: this.hoje, containerClass: this.colorTheme };
      this.periodo.push(inicio);
      let dataPesquisa = (this.hoje.getDate() > 1) ? new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1) : this.hoje;
      this.periodo.push(dataPesquisa);
      this.query.periodo = this.periodo;

      await this.listarEstados();
      await this.listarRedes();
      await this.listarFornecedores();

      // this.pesquisar(this.query, this.paginaAtual, this.qtdeItensPadrao, 1);
      this.spinner.hide();
    }
    else {
      this.spinner.hide();
      this.servicoMsg.exibeErro({"statusCode": 999});
    }
  }

  ngAfterViewInit(): void { this.spinner.show(); }

  pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      this.spinner.show();

      if (frmValues.hasOwnProperty("cnpj") && frmValues.cnpj !== undefined && frmValues.cnpj.length > 0){
        frmValues.cnpj = this.util.soNumeros(frmValues.cnpj);
      }
      if (frmValues.hasOwnProperty("identificacao") && frmValues.identificacao !== undefined && frmValues.identificacao.length > 0){
        frmValues.identificacao = this.util.soNumeros(frmValues.identificacao);
      }

      this.pesquisa = 1;
      frmValues.pagina = pagina;
      frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);
      delete frmValues.produto;
      frmValues.idsProduto = this.idsProduto.join(',');

      this.servicoClienteDesconto.listarDescontosPendentes(frmValues, false).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
  }

  baixar(frmValues){
    this.spinner.show();
    if (frmValues.hasOwnProperty("itensPagina")){
      delete frmValues.itensPagina;
    }
    if (frmValues.hasOwnProperty("pagina")){
      delete frmValues.pagina;
    }

    this.servicoClienteDesconto.listarDescontos(frmValues, true).subscribe(res => {
      const replacer = (key, value) => value === null ? '' : value;
      const header = Object.keys(res.lista[0]);
      let csv = res.lista.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
      csv.unshift(header.join(';'));
      let BOM = "\uFEFF";
      let csvArray = BOM + csv.join('\r\n');

      let blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
      let baixa = new Date();
      let mm = baixa.getMonth() + 1;
      let dd = baixa.getDate();
      let hh = baixa.getHours();
      let mi = baixa.getMinutes();
      let sc = baixa.getSeconds();

      let arq = baixa.getFullYear() + '-' +
      ((mm <= 9) ? '0' + mm.toString() : mm.toString()) + '-' +
      ((dd <= 9) ? '0' + dd.toString() : dd.toString()) + '_' +
      ((hh <= 9) ? '0' + hh.toString() : hh.toString()) + '-' +
      ((mi <= 9) ? '0' + mi.toString() : mi.toString()) + '-' +
      ((sc <= 9) ? '0' + sc.toString() : sc.toString());

      FileSaver.saveAs(blob, arq + '.csv');
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err);
    });
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

  mudarExibicao(){
    this.grid = (!this.grid) ? true : false;
  }

  async listarEstados(){
    this.query.idEstado = -1;

    this.ufs = await this.servicoEstado.listarEstadosAsync(this.query);

    if (this.ufs.length === 1){
      this.query.idEstado = this.ufs[0].idEstado;
      await this.listarCidades();
    }
  }

  async listarCidades(){
    this.query.idCidade = -1;
    if (parseInt(this.query.idEstado) >= 0) {
      this.cidades = await this.servicoCidade.listarCidadesAsync(this.query);

      if (this.cidades.length===1){
        this.query.idCidade = this.cidades[0].idCidade;
      }
    }
    else {
      this.cidades = [];
    }
  }

  async listarRedes(){
    this.query.idRede = -1;

    this.redes = await this.servicoRede.listarRedesAsync(this.query);

    if (this.redes.length > 1){
      this.estabelecimentos = [];
    }
    else{
      this.query.idRede = this.redes[0].idRede;
    }
  }

  async listarEstabelecimentos(){
    if (parseInt(this.query.idRede) > 0){
      this.estabelecimentos = await this.servicoEstabelecimento.listarEstabelecimentosAsync(this.query);
      if (this.estabelecimentos.length===1){
        this.query.cnpj = this.estabelecimentos[0].cnpj;
      }
      await this.atualizarFiltros();
    }
    else {
      this.estabelecimentos = [];
    }
  }

  async listarCategorias(){
    this.query.idCategoria = -1;
    this.categorias = await this.servicoCategoria.listarCategorias(this.query);
    if (this.categorias.length===1){
      this.query.idCategoria = this.categorias[0].idCategoria;
    }
  }

  async listarFornecedores(){
    this.fornecedores = await this.servicoFornecedor.listarFornecedoresAsync(this.query);
    if (this.fornecedores.length===1){
      this.query.idFornecedor = this.fornecedores[0].idFornecedor;
      this.listarMarcas();
    }
    else {
      await this.listarCategorias();
    }
  }

  async listarMarcas(){
    this.query.idMarca = -1;
    if (parseInt(this.query.idFornecedor) > 0){
      this.marcas = await this.servicoMarca.listarMarcasAsync(this.query);
      if (this.marcas.length===1){
        this.query.idMarca = this.marcas[0].idMarca;
        this.listarProdutos();
      }
      else {
        this.listarProdutos();

        this.listarCategorias();
      }
    }
    else {
      this.marcas = [];
      this.produtos = [];
    }
  }

  listarProdutos(){
    this.query.idProduto = -1;
    this.servicoProduto.listarProdutos(this.query).subscribe(
      produtos => {
        this.produtos = produtos.lista;

        if (produtos.lista.length===1){
          this.query.idProduto = produtos.lista[0].idProduto;
        }

        this.listarCategorias();
      }, (err) => {
        this.servicoMsg.exibeErro(err);
      }
    );
  }

  atualizarFiltros(){
    let buscarDados = false;
    this.servicoDashboard.listarFiltros(this.query).subscribe(
      filtro => {
        this.campanhas = filtro.lstCampanhas;
        if (filtro.lstCampanhas != null && filtro.lstCampanhas.length==1) this.query.idCampanha = filtro.lstCampanhas[0].idCampanha;

        this.categorias = filtro.lstCategorias;
        if (filtro.lstCategorias != null && filtro.lstCategorias.length==1) this.query.idCategoria = filtro.lstCategorias[0].idCategoria;

        this.pesquisar(this.query, this.paginaAtual, this.qtdeItensPadrao, 1);
      }
    );
  }

  validaCNPJ(valor){
    var vl = valor.toString().replace(/[^0-9]/g, "");

    if (vl.length > 0) {
      if (!this.util.validaCNPJ(vl)) {
        let err = { "statusCode": 999, "statusText": "CNPJ inválido!" };
        this.servicoMsg.exibeErro(err).then(r1 => {});
        this.cnpjValido = false;
      }
      else {
        this.cnpjValido = true;
      }
    }
    else {
      this.cnpjValido = true;
    }
  }

}
