import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicoSessao } from './srv.sessao';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../app.error-handler'

import { environment } from '../../environments/environment';

@Injectable()
export class ServicoDashboard {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarDetalhes(frm: any) {
    return this.http.post<any>(`${environment.dashboard_api}/dashboard/detalhes`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  async listarDetalhesAsync(frm: any) {
    let res = await this.http.post<any>(`${environment.dashboard_api}/dashboard/detalhes`,frm).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res;
  }

  listarFiltros(frm: any) {
    return this.http.post<any>(`${environment.dashboard_api}/dashboard/filtros`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  async listarFiltrosAsync(frm: any) {
    let res = await this.http.post<any>(`${environment.dashboard_api}/dashboard/filtros`,frm).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res;
  }
}
