import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2TreeSettings, TreeModel, NodeEvent } from 'ng2-tree';
import { NodeMenuItemAction } from 'ng2-tree/src/menu/menu.events';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicoMsg } from '../../servicos/srv.msg';

import { NgbModal, NgbActiveModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ServicoEstabelecimento, ServicoRede } from '../../servicos/srv.rede';
import { ServicoFornecedor, ServicoProduto } from '../../servicos/srv.produto';
import { ServicoProjeto } from '../../servicos/srv.projeto';
import { ServicoMenu, ServicoPerfil, ServicoUsuario, ServicoUsuarioEstabelecimento, ServicoUsuarioProduto, ServicoUsuarioRede, ServicoUsuarioTipo } from '../../servicos/srv.configuracao';

@Component({
  selector: 'tsv2-usr-listar',
  templateUrl: './listar.component.html',
  providers: [
    ServicoEstabelecimento,
    ServicoFornecedor,
    ServicoMenu,
    ServicoMsg,
    ServicoPerfil,
    ServicoProduto,
    ServicoProjeto,
    ServicoUsuario,
    ServicoUsuarioEstabelecimento,
    ServicoUsuarioProduto,
    ServicoUsuarioRede,
    ServicoUsuarioTipo ]
})
export class UsuarioListarComponent implements OnInit {
  exibirBarra: boolean = true;

  mns: any;
  prds: any;
  prjs: any;
  redes: any;
  stbs: any;
  usrs: any;
  new: any;
  perfis: any;
  tipos: any;
  acesso: any;
  acessoLojas: any;
  acessoRedes: any;
  acessoFornecedores: any;
  acessoProdutos: any;
  idUsuarioPai: number;
  usuarioPai: string;
  errorMsg: string;
  idRede: string = "-1";
  idProduto: string = "-1";

  popNovoUsuario: boolean = false;
  popNovaLoja: boolean = false;
  popNovaRede: boolean = false;
  popNovoProduto: boolean = false;

  public tree: TreeModel;
  public menutree: TreeModel;

  constructor(
    private router: Router,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMenu: ServicoMenu,
    private servicoMsg: ServicoMsg,
    private servicoPerfil: ServicoPerfil,
    private servicoProduto: ServicoProduto,
    private servicoProjeto: ServicoProjeto,
    private servicoRede: ServicoRede,
    private servicoUsuario: ServicoUsuario,
    private servicoUsuarioEstabelecimento: ServicoUsuarioEstabelecimento,
    private servicoUsuarioProduto: ServicoUsuarioProduto,
    private servicoUsuarioRede: ServicoUsuarioRede,
    private servicoUsuarioTipo: ServicoUsuarioTipo,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.usrs = {};
    this.new = {};
    this.acesso = {};
    this.montarEstrutura();
  }

  public selecionar(e: NodeEvent): void {
    if (typeof e.node.id == 'number'){
      let id: number = e.node.id;
      this.atualizar(id);
    }
  }

  public criar(e: NodeEvent): void {
    console.log('***** CRIAR *****');
    console.log(e);
  }

  public onMenuItemSelected(e: NodeEvent): void {
    this.new = {};
    this.new.idUsuarioPai = (typeof e.node.id == 'number') ? e.node.id : 0;
    this.new.usuarioPai = e.node.value;
    this.new.idStatus = true;
    this.new.editar = false;

    this.servicoPerfil.listarPerfis({"idUsuario": this.new.idUsuarioPai}).subscribe(pf => {
      this.perfis = pf.lista;
      this.new.idPerfil = pf.lista[0].idPerfil;

      this.servicoUsuarioTipo.listarTipos({"idUsuario": this.new.idUsuarioPai}).subscribe(tp => {
        this.tipos = tp.lista;
        this.new.idUsuarioTipo = tp.lista[0].idUsuarioTipo;

        this.exibirPopNovoUsuario();
      });
    });
  }

  editar(id){
    this.servicoUsuario.consultarUsuario(id).subscribe(usr => {
      this.new = usr.lista[0];
      this.new.editar = true;
      this.new.idStatus = usr.lista[0].ativo;
      this.servicoPerfil.listarPerfis({"idUsuario": (this.new.idUsuarioPai < 0 ? 0 : this.new.idUsuarioPai)}).subscribe(pf => {
        this.perfis = pf.lista;

        this.servicoUsuarioTipo.listarTipos({"idUsuario": (this.new.idUsuarioPai < 0 ? 0 : this.new.idUsuarioPai)}).subscribe(tp => {
          this.tipos = tp.lista;

          this.exibirPopNovoUsuario();
        }, (err: any) => {
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err: any) => {
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  montarEstrutura(): any {
    this.servicoUsuario.listarEstrutura().subscribe(estr => {
      this.tree = {
        "settings": {
          "rightMenu": true,
          "cssClasses": {
            "expanded": "fa fa-caret-down",
            "collapsed": "fa fa-caret-right",
            "empty": "fa fa-caret-right disabled",
            "leaf": "fa"
          },
          "templates": {
            "node": "<i class=\"fa fa-user-circle\" style='margin-right: 10px'></i>",
            "leaf": "<i class=\"fa fa-user\" style='margin-right: 10px'></i>"
          },
          "menuItems": [
            { "action": NodeMenuItemAction.Custom, "name": "Novo Usuário", "cssClass": "fa fa-user-plus" }
          ]
        },
        "id": estr.lista[0].idUsuario,
        "value": estr.lista[0].nome
      };

      // RETIRADO O ITEM (estr.lista[0].idUsuario) INSERIDO MANUALMENTE NO OBJETO this.tree
      let children = this.listarFilhos(estr.lista.filter(x => x.idUsuario != estr.lista[0].idUsuario));
      this.tree = Object.assign(this.tree, children);
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  listarFilhos(estr: any){
    let estrutura = {};

    estr.map((e, i) => {
      estrutura = this.buscarFilhos(e, estrutura);
    });

    return estrutura;
  }

  buscarFilhos(elem: any, estr: any){
    if (Object.keys(estr).length > 0){
      let itemPai = [];
      if (estr.hasOwnProperty("children")){
        itemPai = estr.children.filter( f => { return f.id == elem.idUsuarioPai });
      }

      if (itemPai.length > 0){
        itemPai.map((l, i) => {
          if (!l.hasOwnProperty("children")) l = Object.assign(l, {"children": []});
          l.children.push({ "id": elem.idUsuario, "value": elem.nome });
        })
      }
      else {
        if (estr.hasOwnProperty("children")){
          // estr.children.forEach((o, i) => {
          //   this.buscarFilhos(elem, o);
          // });
          estr.children.push({ "id": elem.idUsuario, "value": elem.nome });
        }
      }
    }
    else {
      if (Object.keys(estr).length === 0) {
        estr = Object.assign(estr, {"children": []});
        estr.children.push({ "id": elem.idUsuario, "value": elem.nome });
      }
    }

    return estr;
  }

  montarMenuEstrutura(m): any {
    this.menutree = {
      "id": m[0].idMenu,
      "value": m[0].menu,
      "settings": {
        "static": true,
        "checked": m[0].acesso,
        "cssClasses": {
          "expanded": "fa fa-caret-down",
          "collapsed": "fa fa-caret-right",
          "empty": "fa fa-caret-right disabled",
          "leaf": "fa"
        },
        "templates": {
          "node": "<i class=\"fa fa-folder-o\"></i>",
          "leaf": "<i class=\"fa fa-file-o\"></i>"
        }
      }
    };

    // RETIRADO O ITEM (m[0].idUsuario) INSERIDO MANUALMENTE NO OBJETO this.tree
    let children = this.listarMenuFilhos(m.filter(x => x.idMenu != m[0].idMenu));

    this.menutree = Object.assign(this.menutree, children);
  }

  listarMenuFilhos(estr: any){
    let estrutura = {};

    let retorno = [];
    while(estr.length > 0){
      let e = estr[0];
      estrutura = this.buscarMenuFilhos(e, estrutura);

      estr = estr.filter(x => { return x.idMenu!==e.idMenu });
      if(!e.hasOwnProperty("elimina")) retorno.push(e);

      if ((estr.length===0) && (retorno.length > 0)){
        estr = retorno;
        retorno = [];
      }
    }

    return estrutura;
  }

  buscarMenuFilhos(elem: any, estr: any){
    if (Object.keys(estr).length > 0){
      if (estr.hasOwnProperty("children")) {
        let itemPai = estr.children.filter( f => { return f.id == elem.idMenuPai });

        if (itemPai.length > 0){
          itemPai.map((l, i) => {
            if (!l.hasOwnProperty("children")) l = Object.assign(l, {"children": []});
            l.children.push({ "id": elem.idMenu, "value": elem.menu, "settings": { "checked": elem.acesso, "selectionAllowed": (elem.acesso ? true : false) }, "idPai": elem.idMenuPai });

            elem.elimina = true;
          })
        }
        else if(estr.hasOwnProperty("children")) {
          let item = estr.children.filter( f => { return f.idPai == elem.idMenuPai });

          if (item.length > 0){
            estr.children.push({ "id": elem.idMenu, "value": elem.menu, "settings": { "checked": elem.acesso, "selectionAllowed": (elem.acesso ? true : false) }, "idPai": elem.idMenuPai });

            elem.elimina = true;
          }
          else {
            estr.children.forEach((o, i) => {
              this.buscarMenuFilhos(elem, o);
            });
          }
        }
      }
    }
    else {
      if (Object.keys(estr).length === 0) {
        estr = Object.assign(estr, {"children": []});
        estr.children.push({ "id": elem.idMenu, "value": elem.menu, "settings": { "checked": elem.acesso, "selectionAllowed": (elem.acesso ? true : false) }, "idPai": elem.idMenuPai });

        elem.elimina = true;
      }
    }

    return estr;
  }

  salvarUsuario(frm: any){
    if (this.new.editar){
      this.servicoUsuario.alterarUsuario(frm).subscribe(id => {
        this.exibirPopNovoUsuario();
        this.servicoMsg.exibeSucesso('Alterado!', 'Usuário alterado com sucesso!').then(r1 => {
          if (r1.success){
            this.montarEstrutura();
            this.atualizar(id);
          }
        }, (err: any) => {
          this.exibirPopNovoUsuario();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err: any) => {
        this.exibirPopNovoUsuario();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
    else {
      this.servicoUsuario.salvarUsuario(frm).subscribe(id => {
        this.exibirPopNovoUsuario();
        this.servicoMsg.exibeSucesso('Criado!', 'Usuário criado com sucesso!').then(r1 => {
          if (r1.success){
            this.montarEstrutura();
            this.atualizar(id);
          }
        }, (err: any) => {
          this.exibirPopNovoUsuario();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err: any) => {
        this.exibirPopNovoUsuario();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
  }

  exibirPopNovoUsuario(){
    setTimeout(() => {
      this.popNovoUsuario = (this.popNovoUsuario) ? false : true;
    }, 250);
  }

  exibirPopNovaLoja(){
    setTimeout(() => {
      this.popNovaLoja = (this.popNovaLoja) ? false : true;
    }, 250);
  }

  exibirPopNovaRede(){
    setTimeout(() => {
      this.popNovaRede = (this.popNovaRede) ? false : true;
    }, 250);
  }

  exibirPopNovoProduto(){
    setTimeout(() => {
      this.popNovoProduto = (this.popNovoProduto) ? false : true;
    }, 250);
  }

  async adicionarLoja(){
    if (this.usrs.idPerfil==6) {
      let obj = {"idUsuario": this.usrs.idUsuarioPai};
      this.acessoRedes = await this.buscarRedes(obj);
      this.idRede = "-1";
      this.exibirPopNovaLoja();
    }
    else {
      this.servicoMsg.exibeErro({"statusCode": 999}).then(r1 => {});
    }
  }

  salvarLoja(frm){
    let o = {};
    o = Object.assign(o, {"idUsuario": this.usrs.idUsuario, "cnpj": frm.value.cnpj});

    this.servicoUsuarioEstabelecimento.adicionarUsuarioEstabelecimento(o).subscribe(id => {
      this.exibirPopNovaLoja();
      this.servicoMsg.exibeSucesso('Criado!', 'Acesso a loja concedido com sucesso!').then(r1 => {
        if (r1.success){
          frm.reset();
          this.atualizar(this.usrs.idUsuario);
        }
      }, (err: any) => {
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  atualizar(id){
    this.servicoUsuario.consultarUsuario(id).subscribe(u => {
      this.usrs = u.lista[0];
      this.usrs = Object.assign(this.usrs, {"menuAlterado": 1});
      let obj = {};
      obj = Object.assign(obj, {"idUsuario": id});

      if (this.usrs.idPerfil==1 || this.usrs.idPerfil==2) {
        this.servicoProjeto.listarAcessos(obj).subscribe(projetos => {
          this.prjs = projetos.lista;
        });
      } else if (this.usrs.idPerfil==3) {
        this.servicoProduto.listarAcessos(obj).subscribe(produtos => {
          this.prds = produtos.lista;
        }, (err) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      } else if (this.usrs.idPerfil==4) {
        this.servicoRede.listarAcessos(obj).subscribe(redes => {
          this.redes = redes.lista;
        }, (err) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      } else if (this.usrs.idPerfil==6) {
        this.servicoEstabelecimento.listarAcessos(obj).subscribe(estabelecimentos => {
          this.stbs = estabelecimentos.lista;
        }, (err) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }

      this.servicoMenu.listarAcessos(obj).subscribe(menus => {
        this.montarMenuEstrutura(menus);
      });
    });
  }

  async adicionarRede(){
    if (this.usrs.idPerfil==4) {
      let obj = {"idUsuario": this.usrs.idUsuarioPai};
      let redes = await this.buscarRedes(obj);
      this.acessoRedes = this.filtrarRedes(redes);
      this.idRede = "-1";
      this.exibirPopNovaRede();
    }
    else {
      this.servicoMsg.exibeErro({"statusCode": 999}).then(r1 => {});
    }
  }

  salvarRede(frm){
    let o = {};
    o = Object.assign(o, {"idUsuario": this.usrs.idUsuario, "idRede": frm.value.idRede});

    this.servicoUsuarioRede.adicionarUsuarioRede(o).subscribe(id => {
      this.exibirPopNovaRede();
      this.servicoMsg.exibeSucesso('Criado!', 'Acesso a rede concedido com sucesso!').then(r1 => {
        if (r1.success){
          frm.reset();
          this.atualizar(this.usrs.idUsuario);
        }
      }, (err: any) => {
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  async buscarRedes(o){
    let promise = new Promise((resolve, reject) => {
        this.servicoRede.listarAcessos(o).subscribe(redes => {
          resolve(redes.lista);
        }, (err) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
          reject(err);
        });
    });
    return promise;
  }

  filtrarRedes(redes){
    let lista = this.redes.map(rede => {return rede.idRede});
    let retorno = redes.filter(rd => {
      return !lista.includes(rd.idRede);
    });
    return retorno;
  }

  async listarLojas(idRede){
    this.acessoLojas = [];

    if (idRede!=="-1"){
      let obj = {"idUsuario": this.usrs.idUsuarioPai, "idRede": idRede};
      let lojas = await this.buscarLojas(obj);

      this.acessoLojas = this.filtrarLojas(lojas);
    }
  }

  async buscarLojas(o){
    let promise = new Promise((resolve, reject) => {
        this.servicoEstabelecimento.listarAcessos(o).subscribe(estabelecimentos => {
          resolve(estabelecimentos.lista);
        }, (err) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
          reject(err);
        });
    });
    return promise;
  }

  filtrarLojas(lojas){
    let lista = this.stbs.map(stb => {return stb.cnpj});
    let retorno = lojas.filter(lj => {
      return !lista.includes(lj.cnpj);
    });
    return retorno;
  }

  async buscarProdutos(o){
    let promise = new Promise((resolve, reject) => {
        this.servicoProduto.listarAcessos(o).subscribe(produtos => {
          resolve(produtos.lista);
        }, (err) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
          reject(err);
        });
    });
    return promise;
  }

  filtrarProdutos(produtos){
    let lista = this.prds.map(prd => {return prd.idProduto});
    let retorno = produtos.filter(pd => {
      return !lista.includes(pd.idProduto);
    });
    return retorno;
  }

  async listarProdutos(idFornecedor){
    this.acessoProdutos = [];

    if (idFornecedor!=="-1"){
      let obj = {"idUsuario": this.usrs.idUsuarioPai, "idFornecedor": idFornecedor};
      let produtos = await this.buscarProdutos(obj);

      this.acessoProdutos = this.filtrarProdutos(produtos);
    }
  }

  async buscarFornecedores(o){
    let promise = new Promise((resolve, reject) => {
        this.servicoFornecedor.listarAcessos(o).subscribe(fornecedores => {
          resolve(fornecedores.lista);
        }, (err) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
          reject(err);
        });
    });
    return promise;
  }

  async adicionarProduto(){
    if (this.usrs.idPerfil==3) {
      let obj = {"idUsuario": this.usrs.idUsuarioPai};
      this.acessoFornecedores = await this.buscarFornecedores(obj);
      this.idProduto = "-1";
      this.exibirPopNovoProduto();
    }
    else {
      this.servicoMsg.exibeErro({"statusCode": 999}).then(r1 => {});
    }
  }

  salvarProduto(frm){
    let o = {};
    o = Object.assign(o, {"idUsuario": this.usrs.idUsuario, "idProduto": frm.value.idProduto});

    this.servicoUsuarioProduto.adicionarUsuarioProduto(o).subscribe(id => {
      this.exibirPopNovoProduto();
      this.servicoMsg.exibeSucesso('Criado!', 'Acesso ao produto concedido com sucesso!').then(r1 => {
        if (r1.success){
          frm.reset();
          this.atualizar(this.usrs.idUsuario);
        }
      }, (err: any) => {
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
