import { Injectable } from '@angular/core';

// import { } from '@types/googlemaps';
// import { } from 'googlemaps';

@Injectable()
export class Util {
  constructor() { }

  geocoder: google.maps.Geocoder;

  validaCPF(vl) {
    // if (obj.value.length > 0) {
      var i;
      // var vl = valor.replace(/[^0-9]/g, "");

      var c = vl.substr(0,9);
      var dv = vl.substr(9,2);
      var d1 = 0;

      for (i = 0; i < 9; i++){
        d1 += c.charAt(i)*(10-i);
      }

      if (d1 == 0){
        return false;
      }

      d1 = 11 - (d1 % 11);
      if (d1 > 9) d1 = 0;

      if (dv.charAt(0) != d1){
        return false;
      }

      d1 *= 2;
      for (i = 0; i < 9; i++){
        d1 += c.charAt(i)*(11-i);
      }

      d1 = 11 - (d1 % 11);
      if (d1 > 9) d1 = 0;

      if (dv.charAt(1) != d1){
        return false;
      }
      return true;
    // }
    // return true;
  }

  validaCNPJ(vl) {
    // if (obj.value.length > 0) {
      var i;
      // var vl = valor.replace(/[^0-9]/g, "");

      var c = vl.substr(0,12);
      var dv = vl.substr(12,2);
      var d1 = 0;
      for (i = 0; i < 12; i++)
      {
        d1 += c.charAt(11-i)*(2+(i % 8));
      }
      if (d1 == 0) return false;
      d1 = 11 - (d1 % 11);
      if (d1 > 9) d1 = 0;
      if (dv.charAt(0) != d1)
      {
        return false;
      }

      d1 *= 2;
      for (i = 0; i < 12; i++)
      {
        d1 += c.charAt(11-i)*(2+((i+1) % 8));
      }
      d1 = 11 - (d1 % 11);
      if (d1 > 9) d1 = 0;
      if (dv.charAt(1) != d1)
      {
        return false;
      }
      return true;
    // }
    // return true;
  }

  soNumeros(vl) {
    var nPattern = /\d+/g;

    var r = vl.match( nPattern ).join('');

    return r;
  }

  buscarLatLngPeloEnd(end): Promise<any>{
    return new Promise((resolve,reject)=>{
      this.geocoder = new google.maps.Geocoder();
      if (this.geocoder) {
        this.geocoder.geocode({ 'address': end }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            resolve({ "lat": results[0].geometry.location.lat(), "lng": results[0].geometry.location.lng() });
          }
          else {
            reject({ "lat": -999, "lng": -999 });
          }
        });
      }
      else {
        reject({ "lat": -999, "lng": -999 });
      }
    });
  }
}
