import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoRede } from '../../servicos/srv.rede';
import { ServicoCategoria, ServicoFornecedor, ServicoMarca, ServicoProduto, ServicoTipoEmbalagem, ServicoTipoCodigo } from '../../servicos/srv.produto';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';

@Component({
  selector: 'tsv2-prd-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoMsg, ServicoCategoria, ServicoFornecedor, ServicoMarca, ServicoPaginacao, ServicoProduto, ServicoRede, ServicoTipoEmbalagem, ServicoTipoCodigo ]
})
export class ProdutoPesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  categorias: any;
  fornecedores: any;
  marcas: any;
  tiposEmbalagem: any;
  tiposCodigo: any;
  redes: any;
  resultado: any;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  paginas: any;

  constructor(
    // private spinner: NgxSpinnerService,
    private servicoCategoria: ServicoCategoria,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMsg: ServicoMsg,
    private servicoMarca: ServicoMarca,
    private servicoProduto: ServicoProduto,
    private servicoRede: ServicoRede,
    private servicoTipoEmbalagem: ServicoTipoEmbalagem,
    private servicoTipoCodigo: ServicoTipoCodigo,
    private servicoPaginacao: ServicoPaginacao
  ) { }

  ngOnInit() {
    // this.spinner.show();

    this.qtdeItensPadrao = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;

    this.servicoCategoria.listarCategorias({}).subscribe(sc => {
      this.categorias = sc.lista;
      this.servicoFornecedor.listarFornecedores({}).subscribe(sf => {
        this.fornecedores = sf.lista;
        this.servicoMarca.listarMarcas({}).subscribe(sm => {
          this.marcas = sm.lista;
          this.servicoTipoEmbalagem.listarTiposEmbalagem({}).subscribe(ste => {
            this.tiposEmbalagem = ste.lista;
            this.servicoTipoCodigo.listarTiposCodigo({}).subscribe(stc => {
              this.tiposCodigo = stc.lista;
              this.servicoRede.listarRedes({}).subscribe(sr => {
                this.redes = sr.lista;
                // this.spinner.hide();
              }, (err) => {
                // this.spinner.hide();
                this.servicoMsg.exibeErro(err).then(r1 => {});
              });
            }, (err) => {
              // this.spinner.hide();
              this.servicoMsg.exibeErro(err).then(r1 => {});
            });
          }, (err) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      // this.spinner.show();
      this.pesquisa = 1;
      frmValues.pagina = pagina;
      frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);

      this.servicoProduto.listarProdutos(frmValues).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

}
