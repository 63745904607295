import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoFornecedor } from '../../servicos/srv.produto';
import { ServicoSessao } from '../../servicos/srv.sessao';

@Component({
  selector: 'tsv2-frn-editar',
  templateUrl: './editar.component.html',
  providers: [ ServicoMsg, ServicoFornecedor, ServicoSessao ]
})
export class FornecedorEditarComponent implements OnInit {
  exibirBarra: boolean = true;

  p: any;
  idFornecedor: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoFornecedor: ServicoFornecedor,
    private servicoSessao: ServicoSessao
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.idFornecedor = parseInt(id);
    this.servicoFornecedor.consultarFornecedor(id).subscribe(res => {
      this.p = res.lista[0];
      // this.spinner.hide();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  alterarFornecedor(frmValues){
    // this.spinner.show();
    this.servicoFornecedor.alterarFornecedor(frmValues).subscribe(id => {
      let url = '/fornecedor/visualizar/' + id;
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Alterado!', 'Fornecedor alterado com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
