import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoEstabelecimento } from '../../servicos/srv.rede';

@Component({
  selector: 'tsv2-stb-listar',
  templateUrl: './listar.component.html',
  providers: [ ServicoMsg, ServicoEstabelecimento ]
})
export class EstabelecimentoListarComponent implements OnInit {
  @Input() items: any[];
  @Input() pagina: number;
  @Input() grid: boolean;
  @Input() permissao: any;

  @Output() trocar = new EventEmitter();

  constructor(
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoEstabelecimento: ServicoEstabelecimento
  ) {
    this.items = [];
    this.pagina = 1;
  }

  ngOnInit() {
  }

  removerEstabelecimento(id){
    let titulo = 'Remover o estabelecimento?';
    let texto = 'Estabelecimento removido com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoEstabelecimento, texto).then(r1 => {
      if (r1.success){
        this.trocar.emit(this.pagina);
      }
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
