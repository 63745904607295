import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import swal from 'sweetalert2';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

import { ServicoMsg } from '../../../servicos/srv.msg';
import { ServicoCampanha, ServicoCampanhaProduto } from '../../../servicos/srv.campanha';
import { ServicoPaginacao } from '../../../servicos/srv.paginacao';
import { ServicoFornecedor, ServicoMarca, ServicoProduto } from '../../../servicos/srv.produto';
import { ServicoRede } from '../../../servicos/srv.rede';

@Component({
  selector: 'tsv2-lrl-campanha-produto-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoMsg, ServicoCampanha, ServicoCampanhaProduto, ServicoPaginacao, ServicoFornecedor, ServicoMarca, ServicoProduto, ServicoRede ]
})
export class LorealCampanhaProdutoPesquisarComponent implements OnInit {
  // @ViewChild('pageContent') pageContent: ElementRef;

  exibirBarra: boolean = true;
  resultado: any = [];
  redes: any = [];
  campanhas: any = [];
  fornecedores: any = [];
  marcas: any = [];
  produtos: any = [];
  query: any = {};

  editavel: boolean = false;
  produtosAprovados: boolean = false;
  idsProduto: string[] = [];

  opcaoPadrao: number;
  qtdeItens: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;

  constructor(
    private spinner: NgxSpinnerService,
    private servicoCampanha: ServicoCampanha,
    private servicoCampanhaProduto: ServicoCampanhaProduto,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMarca: ServicoMarca,
    private servicoMsg: ServicoMsg,
    private servicoPaginacao: ServicoPaginacao,
    private servicoProduto: ServicoProduto,
    private servicoRede: ServicoRede,
    private localeService: BsLocaleService
  ) {
    this.opcaoPadrao = -1;
    this.qtdeItens = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;

    this.localeService.use('pt-br');
  }

  async ngOnInit() {
    this.spinner.show();
    await this.listarRedes();
    await this.listarFornecedores();
    this.spinner.hide();
  }

  // exibirBarraNav(exibir) {
  //   this.exibirBarra = exibir;
  //
  //   this.configAppComponent(exibir);
  // }
  //
  // configAppComponent(exibir) {
  //   let pageContainer = this.pageContent.nativeElement.parentNode.parentNode;
  //   let classe = "page-container-wide";
  //   let arr = pageContainer.className.split(" ");
  //   if (!exibir) {
  //     if (arr.indexOf(classe) == -1) {
  //       pageContainer.className += " " + classe;
  //     }
  //   }
  //   else {
  //     let i = arr.indexOf(classe);
  //     if (i >= 0) {
  //       let classeRemovida = arr.splice(i, 1);
  //       pageContainer.className = arr.toString();
  //     }
  //   }
  // }

  async listarRedes(){
    this.query.idRede = -1;

    this.redes = await this.servicoRede.listarRedesAsync(this.query);

    if (this.redes.length > 1){
      this.query.idCampanha = -1;
      this.campanhas = [];
    }
    else{
      this.query.idRede = this.redes[0].idRede;
      await this.listarCampanhas();
    }
    this.resetarPesquisa();
  }

  async listarCampanhas(){
    this.spinner.show();
    try {
      if (this.query.idRede > 0)
      {
        this.query.idCampanha = -1;
        this.campanhas = await this.servicoCampanha.listarCampanhaPorRede(this.query);
        if (this.campanhas.length === 1){
          this.query.idCampanha = this.campanhas[0].idCampanha;
        }
        this.resetarPesquisa();
      }
      else
      {
        this.campanhas = [];
      }
      this.spinner.hide();
    }
    catch (err) {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    }
  }

  async listarFornecedores(){
    try {
      this.fornecedores = await this.servicoFornecedor.listarFornecedoresAsync(this.query);
      if (this.fornecedores.length===1){
        this.query.idFornecedor = this.fornecedores[0].idFornecedor;
        await this.listarMarcas();
      }
      this.resetarPesquisa();
    }
    catch(err) {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    }
  }

  async listarMarcas(){
    try {
      this.query.idMarca = -1;
      if (parseInt(this.query.idFornecedor) > 0){
        this.marcas = await this.servicoMarca.listarMarcasAsync(this.query);
        if (this.marcas.length===1){
          this.query.idMarca = this.marcas[0].idMarca;

          this.listarProdutos();
        }
        else {
          this.listarProdutos();
        }

        this.resetarPesquisa();
      }
      else {
        this.marcas = [];
        this.produtos = [];
      }
    }
    catch(err){
      this.servicoMsg.exibeErro(err).then(r1 => {});
    }
  }

  listarProdutos(){
    this.query.idProduto = -1;
    this.servicoProduto.listarProdutos(this.query).subscribe(
      produtos => {
        this.produtos = produtos.lista;

        if (produtos.lista.length===1){
          this.query.idProduto = produtos.lista[0].idProduto;
        }
      }, (err) => {
        this.servicoMsg.exibeErro(err);
      }
    );
  }

  async validarCampanha(){
    try {
      if (parseInt(this.query.idCampanha) > 0){
        this.spinner.show();
        let campanha = await this.servicoCampanha.consultarCampanhaAsync(this.query.idCampanha);
        this.editavel = campanha.editavel;
        this.produtosAprovados = campanha.produtosAprovados;
        this.resetarPesquisa();
        this.spinner.hide();
      }
    }
    catch(err){
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    }
  }

  async pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      try {
        this.spinner.show();
        this.pesquisa = 1;
        frmValues.pagina = pagina;
        frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);
        frmValues.idsProduto = this.idsProduto.join(',');

        let res = await this.servicoCampanhaProduto.listarCampanhaProdutosAsync(frmValues);

        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        this.spinner.hide();
      }
      catch (err) {
        this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      }
    }
  }

  trocar(pagina){
    console.log('*** pagina: ', pagina);
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

  resetarPesquisa(){
    this.resultado = [];
    this.pesquisa = 0;
    this.paginaAtual = 1;
  }

  async aprovar(valor){
    swal({
      title: (valor==1 ? 'Deseja aprovar os produtos da campanha?' : 'Deseja editar os produtos da campanha?'),
      text: (valor==1 ? 'Enquanto a campanha não entra em vigência, a edição dos produtos poderá ser realizada!' : 'A campanha pode ser aprovada, até o início da sua vigência!'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: (valor==1 ? 'Aprovar' : 'Editar'),
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        try {
          this.spinner.show();
          let dados = { "idCampanha": this.query.idCampanha, "produtosAprovados": (valor==1 ? true : false) };
          this.servicoCampanha.alterarCampanha(dados).subscribe(idCampanha => {
            let ttl = valor==1 ? 'Aprovado!' : 'Produtos Liberados!';
            let ttc = valor==1 ? 'Produtos aprovados com sucesso!!' : 'Produtos liberados p/ edição!';
            this.servicoMsg.exibeSucesso(ttl, ttc).then(r1 => {
              if (r1.success){
                this.validarCampanha().then(rsp => {
                  this.trocar(1);
                });
                this.spinner.hide();
              }
            });
          }, (err: any) => {
            this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
          this.spinner.hide();
        }
        catch(err){
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        }
      }
    });
  }

}
