import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCategoria } from '../../servicos/srv.produto';

@Component({
  selector: 'tsv2-ctg-listar',
  templateUrl: './listar.component.html',
  providers: [ ServicoCategoria, ServicoMsg ]
})
export class CategoriaListarComponent implements OnInit {
  @Input() items: any[];
  @Input() pagina: number;

  @Output() trocar = new EventEmitter();

  constructor(
    private spinner: NgxSpinnerService,
    private servicoCategoria: ServicoCategoria,
    private servicoMsg: ServicoMsg
  ) {
    this.items = [];
    this.pagina = 1;
  }

  ngOnInit() {
  }

  removerCategoria(id){
    let titulo = 'Remover a categoria?';
    let texto = 'Categoria removida com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoCategoria, texto).then(r1 => {
      if (r1.success){
        this.trocar.emit(this.pagina);
      }
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
