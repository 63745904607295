import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoProjeto } from '../../servicos/srv.projeto';

@Component({
  selector: 'tsv2-prj-listar',
  templateUrl: './listar.component.html',
  providers: [ ServicoMsg, ServicoProjeto ]
})
export class ProjetoListarComponent implements OnInit {

  @Input() items: any[];
  @Input() pagina: number;

  @Output() trocar = new EventEmitter();

  constructor(
    private servicoMsg: ServicoMsg,
    private servicoProjeto: ServicoProjeto
  ) {
    this.items = [];
    this.pagina = 1;
  }

  ngOnInit() {
  }

  removerProjeto(id){
    let titulo = 'Remover o projeto?';
    let texto = 'Projeto removido com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoProjeto, texto).then(r1 => {
      if (r1.success){
        this.trocar.emit(this.pagina);
      }
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
