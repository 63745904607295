import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicoSessao } from './srv.sessao';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../app.error-handler'

// import { REDE_API } from '../app.api'
import { environment } from '../../environments/environment';

@Injectable()
export class ServicoRede {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarRede(frm: any) {
    return this.http.post<any>(`${environment.rede_api}/rede/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarRede(frm: any) {
    return this.http.put<any>(`${environment.rede_api}/rede/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarRede(id: number) {
    return this.http.get<any>(`${environment.rede_api}/rede/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarRedeAsync(id: number) {
    let res = await this.http.get<any>(`${environment.rede_api}/rede/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  listarRedes(psq: any) {
    return this.http.post<any>(`${environment.rede_api}/rede/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarRedesAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.rede_api}/rede/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  listarAcessos(psq: any) {
    return this.http.post<any>(`${environment.rede_api}/rede/listaracessos`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.rede_api}/rede/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoEstabelecimento {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarEstabelecimento(frm: any) {
    return this.http.post<any>(`${environment.rede_api}/estabelecimento/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarEstabelecimento(frm: any) {
    return this.http.put<any>(`${environment.rede_api}/estabelecimento/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarEstabelecimento(id: number) {
    return this.http.get<any>(`${environment.rede_api}/estabelecimento/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarEstabelecimentoAsync(id: number) {
    let res = await this.http.get<any>(`${environment.rede_api}/estabelecimento/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  listarEstabelecimentos(psq: any) {
    return this.http.post<any>(`${environment.rede_api}/estabelecimento/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarEstabelecimentosAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.rede_api}/estabelecimento/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  listarAcessos(psq: any) {
    return this.http.post<any>(`${environment.rede_api}/estabelecimento/listaracessos`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.rede_api}/estabelecimento/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}
