import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';
import { ServicoRede } from '../../servicos/srv.rede';
import { ServicoSessao } from '../../servicos/srv.sessao';

@Component({
  selector: 'tsv2-rde-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoMsg, ServicoPaginacao, ServicoRede, ServicoSessao ]
})
export class RedePesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  resultado: any;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  paginas: any;
  permissao: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoPaginacao: ServicoPaginacao,
    private servicoRede: ServicoRede,
    private servicoSessao: ServicoSessao
  ) {
    let controller = this.route.snapshot.url[0].path;
    this.permissao = servicoSessao.buscarPermissoes(controller);
  }

  ngOnInit() {
    this.qtdeItensPadrao = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;
  }

  pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      // this.spinner.show();
      this.pesquisa = 1;
      frmValues.pagina = pagina;
      frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);

      this.servicoRede.listarRedes(frmValues).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

}
