import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoMarca } from '../../servicos/srv.produto';

@Component({
  selector: 'tsv2-mrc-visualizar',
  templateUrl: './visualizar.component.html',
  providers: [ ServicoMsg, ServicoMarca ]
})
export class MarcaVisualizarComponent implements OnInit {
  exibirBarra: boolean = true;

  p: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoMarca: ServicoMarca
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.servicoMarca.consultarMarca(id).subscribe(res => {
      this.p = res.lista[0];
      // this.spinner.hide();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  removerMarca(id){
    let titulo = 'Remover a marca?';
    let url = '/marca/pesquisar/';
    let texto = 'Marca removida com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoMarca, texto).then(r1 => {
      if (r1.success){
        this.router.navigate([url]);
      }
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
