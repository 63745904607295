import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'cnpjStr'})
export class CnpjStr implements PipeTransform {
  transform(value: string): string {
    let base: string = "";
    base = "00000000000000" + value;

    let cnpj = base.substring(base.length - 14);

    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
  }
}
