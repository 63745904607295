import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../app.error-handler';

import { ServicoSessao } from './srv.sessao';

import { environment } from '../../environments/environment';

@Injectable()
export class ServicoCliente {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarCliente(frm: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/cliente/adicionar`,frm,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  alterarCliente(frm: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.put<any>(`${environment.cliente_api}/cliente/alterar`,frm,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  consultarCliente(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.get<any>(`${environment.cliente_api}/cliente/consultar/`+id,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  listarClientes(psq: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/cliente/listar`,psq,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.delete<any>(`${environment.cliente_api}/cliente/remover/`+id,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoClienteGrupo {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarClienteGrupo(frm: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/clienteGrupo/adicionar`,frm,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  alterarClienteGrupo(frm: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.put<any>(`${environment.cliente_api}/clienteGrupo/alterar`,frm,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  consultarClienteGrupo(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.get<any>(`${environment.cliente_api}/clienteGrupo/consultar/`+id,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  listarClienteGrupo(psq: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/clienteGrupo/listar`,psq,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.delete<any>(`${environment.cliente_api}/clienteGrupo/remover/`+id,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoClienteDesconto {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listarDescontos(psq: any, download: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken'),
        'responseType': 'blob'
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/desconto/listar`,psq,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  listarDescontosPendentes(psq: any, download: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken'),
        'responseType': 'blob'
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/desconto/listarPendentes`,psq,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoClienteGrupoCliente {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarClienteGrupoCliente(frm: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/clienteGrupoCliente/adicionar`,frm,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  alterarClienteGrupoCliente(frm: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.put<any>(`${environment.cliente_api}/clienteGrupoCliente/alterar`,frm,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  consultarClienteGrupoCliente(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.get<any>(`${environment.cliente_api}/clienteGrupoCliente/consultar/`+id,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  listarClienteGrupoCliente(psq: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/clienteGrupoCliente/listar`,psq,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.delete<any>(`${environment.cliente_api}/clienteGrupoCliente/remover/`+id,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  listarClientesDesassociados(obj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/clienteGrupoCliente/listarDesassociados/`,obj,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  associarClientes(obj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/clienteGrupoCliente/associarCliente/`,obj,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }

  desassociarClientes(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.delete<any>(`${environment.cliente_api}/clienteGrupoCliente/remover/`+id,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoItensTransacao {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  listar(psq: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/itensTransacao/listar`,psq,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoDevolucao {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarDevolucao(frm: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'token': this.servicoSessao.buscarSessao('tsToken')
      })
    };
    return this.http.post<any>(`${environment.cliente_api}/devolucao/adicionar`,frm,httpOptions).pipe(catchError(ErrorHandler.handleError));
  }
}
