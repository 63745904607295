import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCampanha, ServicoCampanhaGrupo, ServicoCampanhaClienteGrupo, ServicoCampanhaProduto, ServicoCampanhaGrupoRegra, ServicoCampanhaRequisito, ServicoCampanhaRequisitoAplicacao, ServicoCampanhaTipo, ServicoCampanhaTipoDesconto, ServicoCampanhaTipoLimite, ServicoCampanhaTipoPonto, ServicoCampanhaTipoSorteio, ServicoCampanhaTipoRequisito } from '../../servicos/srv.campanha';
import { ServicoEstado, ServicoCidade } from '../../servicos/srv.configuracao';
import { ServicoClienteGrupo } from '../../servicos/srv.cliente';
import { ServicoRede, ServicoEstabelecimento } from '../../servicos/srv.rede';
import { ServicoFornecedor, ServicoProduto } from '../../servicos/srv.produto';

//, ServicoCampanhaProduto, ServicoCampanhaClienteGrupo

import { ServicoSessao } from '../../servicos/srv.sessao';

import swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'tsv2-cmp-adicionar',
  templateUrl: './adicionar.component.html',
  providers: [ ServicoMsg,
    ServicoCampanha,
    ServicoCampanhaGrupo,
    ServicoCampanhaClienteGrupo,
    ServicoCampanhaProduto,
    ServicoCampanhaGrupoRegra,
    ServicoCampanhaRequisito,
    ServicoCampanhaRequisitoAplicacao,
    ServicoCampanhaTipo,
    ServicoCampanhaTipoDesconto,
    ServicoCampanhaTipoLimite,
    ServicoCampanhaTipoPonto,
    ServicoCampanhaTipoSorteio,
    ServicoCampanhaTipoRequisito,
    ServicoCidade,
    ServicoClienteGrupo,
    ServicoEstabelecimento,
    ServicoEstado,
    ServicoFornecedor,
    ServicoProduto,
    ServicoRede,
    ServicoSessao ]
})
export class CampanhaAdicionarComponent implements OnInit {
  @ViewChild('pageContent') pageContent: ElementRef;

  exibirBarra: boolean = true;

  tipos: any;
  grupos: any;
  regras: any;
  prj: any;
  valores: any;

  clienteGrupos: any;
  filtroExtra: any;
  listaFornecedores: any;
  listaProdutos: any;
  requisitoAplicacoes: any;
  tipoDescontos: any;
  tipoLimites: any;
  tipoPontos: any;
  tipoRequisitos: any;
  tipoSorteios: any;
  campanhaClienteGrupos: any = [];

  tipoRequisito: string;

  requisitos: any = [];
  produtos: any = [];
  hoje: Date = new Date();

  colorTheme = 'theme-default';
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCampanha: ServicoCampanha,
    private servicoCampanhaClienteGrupo: ServicoCampanhaClienteGrupo,
    private servicoCampanhaGrupo: ServicoCampanhaGrupo,
    private servicoCampanhaProduto: ServicoCampanhaProduto,
    private servicoCampanhaRequisito: ServicoCampanhaRequisito,
    private servicoCampanhaRequisitoAplicacao: ServicoCampanhaRequisitoAplicacao,
    private servicoCampanhaTipo: ServicoCampanhaTipo,
    private servicoCampanhaDesconto: ServicoCampanhaTipoDesconto,
    private servicoCampanhaLimite: ServicoCampanhaTipoLimite,
    private servicoCampanhaPonto: ServicoCampanhaTipoPonto,
    private servicoCampanhaTipoRequisito: ServicoCampanhaTipoRequisito,
    private servicoCampanhaSorteio: ServicoCampanhaTipoSorteio,
    private servicoCampanhaGrupoRegra: ServicoCampanhaGrupoRegra,
    private servicoCidade: ServicoCidade,
    private servicoClienteGrupo: ServicoClienteGrupo,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoEstado: ServicoEstado,
    private servicoFornecedor: ServicoFornecedor,
    private servicoProduto: ServicoProduto,
    private servicoRede: ServicoRede,
    private servicoSessao: ServicoSessao,
    private localeService: BsLocaleService
  ) {
    this.bsConfig = Object.assign({}, { minDate: this.hoje,  containerClass: this.colorTheme });

    this.localeService.use('pt-br');
  }

  ngOnInit() {
    $(document).ready(function(){
      $('#smartwizard').smartWizard({
        theme: 'dots',
        lang: {
          next: 'Próximo',
          previous: 'Anterior'
        },
        toolbarSettings: {
          showNextButton: false,
          showPreviousButton: false
        }
      });

      $("#smartwizard").on("showStep", function(e, anchorObject, stepNumber, stepDirection, stepPosition) {
        if(stepPosition === 'first'){
          $("#prev-btn").addClass('disabled');
        }else if(stepPosition === 'final'){
          $("#next-btn").addClass('disabled');
        }else{
          $("#prev-btn").removeClass('disabled');
          $("#next-btn").removeClass('disabled');
        }
      });

      $("#prev-btn").on("click", function() {
        $('#smartwizard').smartWizard("prev");
        // atualizarDados();
        return true;
      });

      $("#next-btn").on("click", function() {
        $('#smartwizard').smartWizard("next");
        // atualizarDados();
        return true;
      });

      // function atualizarDados(){
      //   let dados = {"campanha": {}};
      //   $("#frmCampanha").serializeArray().map(function(x){dados.campanha[x.name] = x.value;});
      //   console.log( dados );
      // }
    });

    this.prj = this.servicoSessao.buscarProjeto();

    // this.spinner.show();
    this.servicoCampanhaTipo.listarCampanhaTipos({}).subscribe(res => {
      this.tipos = res.lista;
      this.servicoCampanhaGrupo.listarCampanhaGrupos({}).subscribe(res => {
        this.grupos = res.lista;
        this.servicoCampanhaGrupoRegra.listarCampanhaGrupoRegras({}).subscribe(res => {
          this.regras = res.lista;
          this.servicoCampanhaTipoRequisito.listarCampanhaTipoRequisitos({}).subscribe(res => {
            this.tipoRequisitos = res.lista;
            this.servicoCampanhaRequisitoAplicacao.listarCampanhaRequisitoAplicacoes({}).subscribe(res => {
              this.requisitoAplicacoes = res.lista;
              this.servicoFornecedor.listarFornecedores({}).subscribe(res => {
                this.listaFornecedores = res.lista;
                this.servicoCampanhaDesconto.listarCampanhaTipoDesconto({}).subscribe(res => {
                  this.tipoDescontos = res.lista;
                  this.servicoCampanhaLimite.listarCampanhaTipoLimite({}).subscribe(res => {
                    this.tipoLimites = res.lista;
                    this.servicoCampanhaPonto.listarCampanhaTipoPonto({}).subscribe(res => {
                      this.tipoPontos = res.lista;
                      this.servicoCampanhaSorteio.listarCampanhaTipoSorteio({}).subscribe(res => {
                        this.tipoSorteios = res.lista;
                        // this.servicoCampanhaClienteGrupo.listarCampanhaClienteGrupos({}).subscribe(res => {
                        //   this.campanhaClienteGrupos = res.lista.filter(x => x.idCampanhaClienteGrupo > 0);
                        //   this.clienteGrupos = res.lista.filter(x => x.idCampanhaClienteGrupo == 0);
                        // }, (err) => {
                        //   this.servicoMsg.exibeErro(err).then(r1 => {});
                        // });
                        this.servicoClienteGrupo.listarClienteGrupo({}).subscribe(res => {
                          this.clienteGrupos = res.lista;
                        }, (err: any) => {
                          // this.spinner.hide();
                          this.servicoMsg.exibeErro(err).then(r1 => {});
                        });
                      }, (err: any) => {
                        // this.spinner.hide();
                        this.servicoMsg.exibeErro(err).then(r1 => {});
                      });
                    }, (err: any) => {
                      // this.spinner.hide();
                      this.servicoMsg.exibeErro(err).then(r1 => {});
                    });
                  }, (err: any) => {
                    // this.spinner.hide();
                    this.servicoMsg.exibeErro(err).then(r1 => {});
                  });
                }, (err: any) => {
                  // this.spinner.hide();
                  this.servicoMsg.exibeErro(err).then(r1 => {});
                });
              }, (err: any) => {
                // this.spinner.hide();
                this.servicoMsg.exibeErro(err).then(r1 => {});
              });
            }, (err: any) => {
              // this.spinner.hide();
              this.servicoMsg.exibeErro(err).then(r1 => {});
            });
          }, (err: any) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }, (err: any) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  exibirBarraNav(exibir) {
    this.exibirBarra = exibir;

    this.configAppComponent(exibir);
  }

  configAppComponent(exibir) {
    let pageContainer = this.pageContent.nativeElement.parentNode.parentNode;
    let classe = "page-container-wide";
    let arr = pageContainer.className.split(" ");
    if (!exibir) {
      if (arr.indexOf(classe) == -1) {
        pageContainer.className += " " + classe;
      }
    }
    else {
      let i = arr.indexOf(classe);
      if (i >= 0) {
        let classeRemovida = arr.splice(i, 1);
        pageContainer.className = arr.toString();
      }
    }
  }

  buscarFiltro(id){
    // this.spinner.show();
    this.tipoRequisito = id;
    if (id=="1"){ //CNPJ
      this.valores = [];
      this.servicoRede.listarRedes({}).subscribe(res => {
        this.filtroExtra = res.lista.map(item => {
          return { "id": item.idRede, "nome": item.rede };
        });
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (id=="2"){ //REDE
      this.filtroExtra = [];

      this.servicoRede.listarRedes({}).subscribe(res => {
        this.valores = res.lista.map(item => {
          // this.spinner.hide();
          return { "valor": item.idRede, "valorDesc": item.rede };
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (id=="3"){ //CIDADE
      this.valores = [];
      this.servicoEstado.listarEstados({"idEstado":-1}).subscribe(res => {
        this.filtroExtra = res.lista.map(item => {
          // this.spinner.hide();
          return { "id": item.idEstado, "nome": item.uf };
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (id=="4"){ //ESTADO
      this.filtroExtra = [];

      this.servicoEstado.listarEstados({"idEstado":-1}).subscribe(res => {
        this.valores = res.lista.map(item => {
          return { "valor": item.idEstado, "valorDesc": item.uf };
        });
        // this.spinner.hide();
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
  }

  aplicarFiltro(id){
    // this.spinner.show();
    if (this.tipoRequisito=="1"){ //CNPJ
      this.servicoEstabelecimento.listarEstabelecimentos({"idRede": id}).subscribe(res => {
        this.valores = res.lista.map(item => {
          // this.spinner.hide();
          return { "valor": item.idRede, "valorDesc": item.estabelecimento + ' - CNPJ: ' + item.cnpj };
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (this.tipoRequisito=="2"){ //REDE
      this.filtroExtra = [];
    }
    else if (this.tipoRequisito=="3"){ //CIDADE
      this.servicoCidade.listarCidades({"idEstado": id}).subscribe(res => {
        this.valores = res.lista.map(item => {
          // this.spinner.hide();
          return { "valor": item.idCidade, "valorDesc": item.cidade };
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
    else if (this.tipoRequisito=="4"){ //ESTADO
      this.filtroExtra = [];
    }
  }

  salvarRequisito(frmReq){
    let requisito = this.tipoRequisitos.find(x => x.idTipoRequisito === parseInt(frmReq.idTipoRequisito));
    frmReq.tipoRequisito = requisito.tipoRequisito;
    let requisitoAplicacao = this.requisitoAplicacoes.find(x => x.idRequisitoAplicacao === parseInt(frmReq.idRequisitoAplicacao));
    frmReq.aplicacao = requisitoAplicacao.aplicacao;
    let valor = this.valores.find(x => x.valor === parseInt(frmReq.valor));
    frmReq.valorDesc = valor.valorDesc;
    frmReq.ativo = frmReq.ativoReq;
    delete frmReq.ativoReq;
    this.requisitos.push(frmReq);

    // console.log(this.requisitos);
    // // this.spinner.show();
    // // frmValues.idCampanha = this.idCampanha;
    // this.servicoCampanhaRequisito.adicionarCampanhaRequisito(frmValues).subscribe(id => {
    //   // this.spinner.hide();
    //   swal(
    //     'Criado!',
    //     'Requisito criado com sucesso!',
    //     'success'
    //   ).then((result) => {
    //     if (result.value) {
    //       // this.spinner.show();
    //       // this.servicoCampanhaRequisito.listarCampanhaRequisitos({"idCampanha": this.idCampanha}).subscribe(res => {
    //       this.servicoCampanhaRequisito.listarCampanhaRequisitos({}).subscribe(res => {
    //         console.log(res);
    //         // this.requisitos = res.lista;
    //         // this.spinner.hide();
    //       }, (err: any) => {
    //         // this.spinner.hide();
    //         this.servicoMsg.exibeErro(err);
    //       });
    //     }
    //   });
    // }, (err: any) => {
    //   // this.spinner.hide();
    //   this.servicoMsg.exibeErro(err);
    // });
  }

  removerRequisito(requisito){
    for (let i = 0; i < this.requisitos.length; i++){
      if (this.requisitos[i].idTipoRequisito === requisito.idTipoRequisito && this.requisitos[i].idRequisitoAplicacao === requisito.idRequisitoAplicacao && this.requisitos[i].valor === requisito.valor){
        this.requisitos.splice(i, 1);
      }
    }
  }

  adicionarCampanha(frmValues){
    // this.spinner.show();
    this.prj = this.servicoSessao.buscarProjeto();
    if (this.prj.hasOwnProperty("projetoId")){
      frmValues.idProjeto = this.prj.projetoId;

      this.servicoCampanha.adicionarCampanha(frmValues).subscribe(id => {
        let url = '/campanha/visualizar/' + id;
        // this.spinner.hide();
        this.servicoMsg.exibeSucesso('Criada!', 'Campanha criada com sucesso!').then(r1 => {
          if (r1.success){
            this.router.navigate([url]);
          }
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
    else {
      // this.spinner.hide();
      this.servicoMsg.exibeErro({"statusCode": 999}).then(r1 => {});
    }
  }

  buscarProdutos(idFornecedor){
    this.servicoProduto.listarProdutos({"idFornecedor": idFornecedor}).subscribe(res => {
      this.listaProdutos = res.lista;
    }, (err: any) => {
      this.servicoMsg.exibeErro(err);
    });
  }

  salvarProduto(frmPrd){
    //let obj = this.produtos.find(x => x.codigoProduto == frmPrd.codigoProduto);
    // if(obj==undefined || obj==null){
      // let produto = this.listaProdutos.find(x => x.codigoProduto === frmPrd.codigoProduto);
      let produto = this.listaProdutos.find(x => x.idProduto === parseInt(frmPrd.idProduto));
      // frmPrd.idProduto = produto.idProduto;
      frmPrd.codigoProduto = produto.codigoProduto;
      frmPrd.produto = produto.produto;
      if (frmPrd.idTipoDesconto!==''){
        let tipoDesconto = this.tipoDescontos.find(x => x.idTipoDesconto === parseInt(frmPrd.idTipoDesconto));
        frmPrd.tipoDesconto = tipoDesconto.tipoDesconto;
      }
      frmPrd.desconto = (frmPrd.desconto.length>0) ? frmPrd.desconto.replace(',','.') : '';
      frmPrd.aporteDesconto = (frmPrd.aporteDesconto.length>0) ? frmPrd.aporteDesconto.replace(',','.') : '';
      frmPrd.precoRef = (frmPrd.precoRef.length>0) ? frmPrd.precoRef.replace(',','.') : '';
      if (frmPrd.idTipoPonto!==''){
        let tipoPonto = this.tipoPontos.find(x => x.idTipoPonto === parseInt(frmPrd.idTipoPonto));
        frmPrd.tipoPonto = tipoPonto.tipoPonto;
      }
      frmPrd.ponto = (frmPrd.ponto.length>0) ? frmPrd.ponto.replace(',','.') : '';
      if (frmPrd.idTipoSorteio!==''){
        let tipoSorteio = this.tipoSorteios.find(x => x.idTipoSorteio === parseInt(frmPrd.idTipoSorteio));
        frmPrd.tipoSorteio = tipoSorteio.tipoSorteio;
      }
      if (frmPrd.idTipoLimite!==''){
        let tipoLimite = this.tipoLimites.find(x => x.idTipoLimite === parseInt(frmPrd.idTipoLimite));
        frmPrd.tipoLimite = tipoLimite.tipoLimite;
      }
      frmPrd.limite = (frmPrd.limite!==null && frmPrd.limite!=='') ? frmPrd.limite.replace(',','.') : '';
      frmPrd.ativo = frmPrd.ativoPrd;
      delete frmPrd.ativoPrd;

      this.produtos.push(frmPrd);
      console.log(this.produtos);
    // }
  }

  removerProduto(cp){
    for (let i = 0; i < this.produtos.length; i++){
      if (this.produtos[i].codigoProduto === cp){
        this.produtos.splice(i, 1);
      }
    }
  }

  salvarGrupo(frmGrp){
    // let obj = this.campanhaClienteGrupos.find(x => x.idClienteGrupo == frmGrp.idClienteGrupo);
    // if(obj==undefined || obj==null){
      let clienteGrupo = this.clienteGrupos.find(x => x.idClienteGrupo === parseInt(frmGrp.idClienteGrupo));
      frmGrp.clienteGrupo = clienteGrupo.clienteGrupo;

      this.campanhaClienteGrupos.push(frmGrp);
      console.log(this.campanhaClienteGrupos);
    // }
  }

  removerGrupo(idCG){
    for (let i = 0; i < this.campanhaClienteGrupos.length; i++){
      if (this.campanhaClienteGrupos[i].idClienteGrupo === idCG){
        this.campanhaClienteGrupos.splice(i, 1);
      }
    }
  }

  criarCampanha(frmCmp){
    this.spinner.show();
    this.servicoCampanha.adicionarCampanha(frmCmp).subscribe(idCampanha => {
      this.requisitos.map(req => {
        req.idCampanha = idCampanha;
        this.servicoCampanhaRequisito.adicionarCampanhaRequisito(req).subscribe(idCampanhaRequisito => {
        }, (err: any) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      });
      this.produtos.map(prd => {
        prd.idCampanha = idCampanha;
        this.servicoCampanhaProduto.adicionarCampanhaProduto(prd).subscribe(idCampanhaProduto => {
        }, (err: any) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      });
      this.campanhaClienteGrupos.map(grp => {
        grp.idCampanha = idCampanha;
        this.servicoCampanhaClienteGrupo.adicionarCampanhaClienteGrupo(grp).subscribe(idCampanhaClienteGrupo => {
        }, (err: any) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      });
      let url = '/campanha/editar/' + idCampanha;
      this.servicoMsg.exibeSucesso('Criada!', 'Campanha criada com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
