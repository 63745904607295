import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'cepStr'})
export class CepStr implements PipeTransform {
  transform(value: string): string {
    let base: string = "";
    base = "00000000" + value;

    let cep = base.substring(base.length - 8);

    return cep.replace(/(\d{5})(\d{3})/g,"\$1\-\$2");
  }
}
