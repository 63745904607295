import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EncryptDecrypt } from '../../servicos/srv.encryptdecrypt';
import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoSessao } from '../../servicos/srv.sessao';

declare var $: any;

@Component({
  selector: 'tsv2-trocar-projeto',
  templateUrl: './pop-trocar-projeto.component.html',
  providers: [ ServicoMsg, ServicoSessao, EncryptDecrypt ]
})
export class PopTrocarProjetoComponent implements OnInit {

  @Input() projetos: any;
  @Input() idProjeto: number;

  logado: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    // private servicoProjeto: ServicoProjeto,
    private servicoSessao: ServicoSessao,
    private encryptDecrypt: EncryptDecrypt
    // , private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.logado = this.servicoSessao.checarSessao('tsToken');
  }

  salvar(frm){
    if (this.logado){
      // this.spinner.show();
      this.servicoSessao.alterarProjeto(frm).subscribe(idSessao => {
        this.servicoSessao.buscarDadosSessao().subscribe(sessao => {
          this.servicoSessao.adicionarSessao('tsDadosSessao', this.encryptDecrypt.Encrypt(JSON.stringify(sessao))).then(r1 => {
            window.location.href=this.router.url;
          }, (err) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err);
          });
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err);
        });
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
  }

}
