import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoDevolucao } from '../../servicos/srv.cliente';

@Component({
  selector: 'tsv2-devolucao-listar',
  templateUrl: './listar.component.html',
  providers: [ ServicoDevolucao, ServicoMsg ]
})
export class DevolucaoListarComponent implements OnInit {
  @Input() items: any[];
  @Input() pagina: number;

  @Output() trocar = new EventEmitter();

  popDevolucao: boolean = false;
  cp: any;
  errorMsg: string;

  constructor(
    private servicoDevolucao: ServicoDevolucao,
    private servicoMsg: ServicoMsg
  ) {
    this.items = [];
    this.pagina = 1;
  }

  ngOnInit() {
  }

  exibirPopDevolucao(){
    setTimeout(() => {
      this.popDevolucao = (this.popDevolucao) ? false : true;
    }, 500);
  }

  detalharProduto(index){
    this.cp = this.items[index];
    this.exibirPopDevolucao();
  }

  devolver(qtdeDevolvida){
    let qtde = parseFloat(qtdeDevolvida);

    if (qtde <= 0) {
      this.errorMsg = "Quantidade à ser devolvida deve ser maior que 0!";
    }
    else if(qtde > this.cp.qtdeVendida) {
      this.errorMsg = "Quantidade devolvida não pode ser maior que a vendida!";
    }
    else {
      this.exibirPopDevolucao();
      let devolucao = { cnpj: this.cp.cnpj, idTerminal: this.cp.idTerminal, cupom: this.cp.cupom, data: this.cp.hora, tipoProduto: this.cp.tipoProduto, codigoProduto: this.cp.codigoProduto, nsuHostOut: this.cp.nsuHostOut, quantidade: qtde };

      this.servicoDevolucao.adicionarDevolucao(devolucao).subscribe(id => {
        if (id > 0) {
          this.servicoMsg.exibeSucesso('Criado!', 'Devolução criada com sucesso!').then(r1 => {
            if (r1.success){
              this.trocar.emit(1); //SIMULA REFRESH NA PAGINA!
            }
          }, (err: any) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }
        else {
          this.errorMsg = "Não foi possível concluir a devolução do produto!";
        }
      }, (err) => {
        //this.spinner.hide();
        this.servicoMsg.exibeErro({"statusCode": 999});
      });
    }
  }
}
