import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router/src/router_state';
import { Observable, Subject, of } from 'rxjs';
import 'rxjs/add/operator/map';
// import { map } from "rxjs/operators";

import { ServicoMenu } from './servicos/srv.configuracao';
import { ServicoSessao } from './servicos/srv.sessao';

@Injectable()
export class NeedAuthGuard implements CanActivate {
  constructor(
    private servicoMenu: ServicoMenu,
    private servicoSessao: ServicoSessao,
    private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      const redirectUrl = route['_routerState']['url'];

      if (this.servicoSessao.checarSessao('tsToken')) {
        const urlAccess = route.params.hasOwnProperty('id') ? redirectUrl.replace('/'+route.params.id,'') : redirectUrl;
        let obj = {'url': urlAccess};
        return this.servicoMenu.listarMenus(obj).map(res => {
          if (res.hasOwnProperty("lista") && res.lista.length) {
            return true;
          }
          else {
            this.router.navigateByUrl(this.router.createUrlTree(['/unauthorized']));
            return false;
          }
        }, (err: any) => {
          this.router.navigateByUrl(this.router.createUrlTree(['/404']));
          return false;
        });
      }
      else {
        this.router.navigateByUrl(
          this.router.createUrlTree(
            ['/login'], {
              queryParams: {
                redirectUrl
              }
            }
          )
        );

        of(false);
      }
    }
}
