import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filtroAcesso'})
export class FiltroAcesso implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter(item => item.acesso == filter.acesso);
  }
}
