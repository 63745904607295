import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';

@Component({
  selector: 'tsv2-prm-listar',
  templateUrl: './listar.component.html',
  providers: [ ServicoMsg ]
})
export class PromocaoListarComponent implements OnInit {
  @Input() items: any[];
  @Input() pagina: number;

  @Output() trocar = new EventEmitter();

  constructor(
    private servicoMsg: ServicoMsg
  ) {
    this.items = [];
    this.pagina = 1;
  }

  ngOnInit() {
  }

}
