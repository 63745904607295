import { Injectable } from '@angular/core';

import { APP_CONFIG } from '../app.config'

@Injectable()
export class ServicoPaginacao {

  private qtdeItensPaginacao: number;
  private margem: number;
  private paginas: number[];

  constructor() { }

  setarPaginacao(pagina: number, total: number) {
    this.qtdeItensPaginacao = APP_CONFIG.qtdeItensPaginacao;
    this.margem = Math.floor(APP_CONFIG.qtdeItensPaginacao / 2);

    if (total <= this.qtdeItensPaginacao) {
      this.paginas = Array.from(Array(total).keys()).map(i => 1 + i);
    }
    else if ((pagina - this.margem) < 1) {
      this.paginas = Array.from(Array(this.qtdeItensPaginacao).keys()).map(i => 1 + i);
    }
    else if ((pagina + this.margem) >= total) {
      this.paginas = Array.from(Array(this.qtdeItensPaginacao).keys()).map(i => (total - (this.qtdeItensPaginacao - 1)) + i);
    }
    else {
      this.paginas = Array.from(Array(this.qtdeItensPaginacao).keys()).map(i => (pagina - this.margem) + i);
    }

    return this.paginas;
  }
}
