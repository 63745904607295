import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsv2-minha-conta',
  templateUrl: './minha-conta.component.html'
})
export class MinhaContaComponent implements OnInit {
  exibirBarra: boolean = true;
  files: File[] = [];
  editar: boolean = false;
  editarSenha: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  editarDados(){
    this.editar = (!this.editar) ? true : false;
  }

  editarNovaSenha(e){
    this.editarSenha = (!e.currentTarget.checked) ? true : false;
  }

  onSelect(event) {
		console.log(event);
    if (this.files.length > 0) this.onRemove(event);
		this.files.push(...event.addedFiles);
	}

	onRemove(event) {
		console.log(event);
		this.files.splice(this.files.indexOf(event), 1);
	}

}
