import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'cpfStr'})
export class CpfStr implements PipeTransform {
  transform(value: string): string {
    let base: string = "";
    base = "00000000000" + value;

    let cpf = base.substring(base.length - 11);

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
  }
}

@Pipe({name: 'cpfMaskStr'})
export class CpfMaskStr implements PipeTransform {
  transform(value: string): string {
    let base: string = "";
    base = "00000000000" + value;

    let cpf = base.substring(base.length - 11);

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.XXX.XXX\-\$4");
  }
}
