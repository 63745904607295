import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCliente, ServicoClienteGrupo, ServicoClienteGrupoCliente } from '../../servicos/srv.cliente';
import { ServicoSessao } from '../../servicos/srv.sessao';

@Component({
  selector: 'tsv2-cligrp-editar',
  templateUrl: './editar.component.html',
  providers: [ ServicoMsg, ServicoCliente, ServicoClienteGrupo, ServicoClienteGrupoCliente, ServicoSessao ]
})
export class ClienteGrupoEditarComponent implements OnInit {
  exibirBarra: boolean = true;

  p: any;
  clientes: any;
  idClienteGrupo: number;
  associados: any = [];
  assTemporarios: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCliente: ServicoCliente,
    private servicoClienteGrupo: ServicoClienteGrupo,
    private servicoClienteGrupoCliente: ServicoClienteGrupoCliente,
    private servicoSessao: ServicoSessao
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.idClienteGrupo = parseInt(id);
    this.servicoClienteGrupo.consultarClienteGrupo(id).subscribe(res => {
      this.p = res.lista[0];
      let obj = {"idClienteGrupo" : this.idClienteGrupo};
      this.servicoClienteGrupoCliente.listarClienteGrupoCliente(obj).subscribe(res => {
        // this.spinner.hide();
        this.associados = res.lista;
        this.buscarClientes(obj);
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
      // this.spinner.hide();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  alterarClienteGrupo(frmValues){
    // this.spinner.show();
    this.servicoClienteGrupo.alterarClienteGrupo(frmValues).subscribe(id => {
      let url = '/clienteGrupo/visualizar/' + id;
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Criado!', 'Grupo de Cliente alterado com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  addRemParticipante(event, obj){
    if (event.target.checked) {
      // this.associados.push(obj);
      this.assTemporarios.push(obj);
    }
    if (!event.target.checked) {
      for (let i = 0; i < this.assTemporarios.length; i++){
        if (this.assTemporarios[i].idCliente === obj.idCliente){
          this.assTemporarios.splice(i, 1);
        }
      }
    }
  }

  remParticipante(id){
    let titulo = 'Remover o participante?';
    let url = '/clienteGrupo/pesquisar/';
    let texto = 'Participante removido com sucesso!';
    let obj = {"idClienteGrupo" : this.idClienteGrupo};
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoClienteGrupoCliente, texto).then(r1 => {
      if (r1.success){
        this.servicoClienteGrupoCliente.listarClienteGrupoCliente(obj).subscribe(res => {
          // this.spinner.hide();
          this.associados = res.lista;
          this.buscarClientes(obj);
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }
    }, (err: any) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  addParticipante(){
    let cont = 0;
    for (let i = 0; i < this.assTemporarios.length; i++){
      let obj = {"idClienteGrupo" : this.idClienteGrupo, "idCliente" : this.assTemporarios[i].idCliente};
      this.servicoClienteGrupoCliente.adicionarClienteGrupoCliente(obj).subscribe(res => {
        this.servicoClienteGrupoCliente.listarClienteGrupoCliente({"idClienteGrupo": this.idClienteGrupo}).subscribe(res => {
          // this.spinner.hide();
          this.associados = res.lista;
          this.buscarClientes(obj);
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
  }

  buscarClientes(obj){
    this.servicoCliente.listarClientes(obj).subscribe(res => {
      if (this.associados.length == 0){
        this.clientes = res.lista;
      }
      else {
        let listaAssociados = this.associados;
        this.clientes = res.lista.filter(function(o) {
          return !listaAssociados.some(function(o2) {
            return o.idCliente == o2.idCliente;
          });
        });
      }
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }
}
