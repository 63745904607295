import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicoSessao } from './srv.sessao';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../app.error-handler'

import { environment } from '../../environments/environment';

@Injectable()
export class ServicoCustomLoreal {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  async simularLimitePontos(frm: any) {
    let res = await this.http.post<any>(`${environment.configuracao_api}/simular-limite-pontos/calcular`,frm).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res;
  }

  async listarDescontos(frm: any) {
    let res = await this.http.post<any>(`${environment.cliente_api}/loreal-desconto/listar`,frm).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res;
  }
}
