import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoProjeto } from '../../servicos/srv.projeto';
import { ServicoSessao } from '../../servicos/srv.sessao';

declare var $: any;

@Component({
  selector: 'tsv2-pagina-barra-lateral',
  templateUrl: './pagina-barra-lateral.component.html',
  providers: [ ServicoMsg, ServicoProjeto, ServicoSessao ]
})
export class PaginaBarraLateralComponent implements OnInit {
  @ViewChild('ulMenu') ul: ElementRef;

  @Input() exibir: boolean;

  data: SafeHtml;
  idProjeto: number;
  logado: boolean;
  nomeProjeto: string;
  nomeUsuario: string;
  popProjeto: boolean = false;
  projetos: any;

  constructor(
    private router : Router,
    private sanitizer: DomSanitizer,
    private servicoMsg: ServicoMsg,
    private servicoProjeto: ServicoProjeto,
    private servicoSessao: ServicoSessao
  ) { }

  ngOnInit() {
    this.logado = this.servicoSessao.checarSessao('tsToken');

    if (this.logado) {
      this.buscarDadosSessao();

      this.buscarProjetos();
    }
  }

  buscarDadosSessao(){
    this.servicoSessao.buscarDadosSessao().subscribe(us => {
      if (us != null){
        this.nomeUsuario = us.nome;
        this.idProjeto = us.projetoId;
        this.nomeProjeto = us.projeto;

        this.buscarMenus();
      }
    }, (err) => {
      this.servicoMsg.exibeErro(err).then(e => {
        if (e.hasOwnProperty('url')){
          window.location.href=e.url;
        }
      });
    });
  }

  buscarMenus() {
    let menuItems = this.ul.nativeElement.innerHTML;
    menuItems = menuItems.replace('^nomeUsuario^',this.nomeUsuario);
    menuItems = menuItems.replace('^nomeProjeto^',this.nomeProjeto);

    let m = this.servicoSessao.buscarMenus();

    if (m.hasOwnProperty("menus") && m.menus.length > 0) {
      this.ul.nativeElement.innerHTML = menuItems + this.menuLogado(m.menus.filter(x => {return x.exibe===true}));

      this.applyJQuery();
    }
  }

  applyJQuery(){
    $(document).ready(function(){
      $(".x-navigation  li > a").click(function(){
        var li = $(this).parent('li');
        var ul = li.parent("ul");

        ul.find(" > li").not(li).removeClass("active");
      });

      $(".x-navigation li").click(function(event){
        event.stopPropagation();

        var li = $(this);

        if(li.children("ul").length > 0 || li.children(".panel").length > 0 || $(this).hasClass("xn-profile") > 0){
          if(li.hasClass("active")){
            li.removeClass("active");
            li.find("li.active").removeClass("active");
          }else
          li.addClass("active");

          onresize(200);

          if($(this).hasClass("xn-profile") > 0)
          return true;
          else
          return false;
        }
      });

      $("a.profile-control-right").on("click",function(){
        var box = $($(this).data("box"));
        if(box.length > 0){
          box.toggleClass("open");

          var sound = box.data("sound");

          if(sound === 'alert')
          playAudio('alert');

          if(sound === 'fail')
          playAudio('fail');
        }
        return false;
      });

      $("a.x-navigation-control").click(function(){
        $(this).parents(".x-navigation").toggleClass("x-navigation-open");

        onresize(200);

        return false;
      });

      if($(".page-navigation-toggled").length > 0){
        x_navigation_minimize("close");
      }

      $(".x-navigation-minimize").click(function(){

        if($(".page-sidebar .x-navigation").hasClass("x-navigation-minimized")){
          $(".page-container").removeClass("page-navigation-toggled");
          x_navigation_minimize("open");
        }else{
          $(".page-container").addClass("page-navigation-toggled");
          x_navigation_minimize("close");
        }

        onresize(200);

        return false;
      });

      $(".xn-search").on("click",function(){
        $(this).find("input").focus();
      });

      function onresize(timeout){
        timeout = timeout ? timeout : 200;

        setTimeout(function(){
          page_content_onresize();
        },timeout);
      }

      function page_content_onresize(){
        $(".page-content,.content-frame-body,.content-frame-right,.content-frame-left").css("width","").css("height","");

        var content_minus = 0;
        content_minus = ($(".page-container-boxed").length > 0) ? 40 : content_minus;
        content_minus += ($(".page-navigation-top-fixed").length > 0) ? 50 : 0;

        var content = $(".page-content");
        var sidebar = $(".page-sidebar");

        if(content.height() < $(document).height() - content_minus){
          content.height($(document).height() - content_minus);
        }

        if(sidebar.height() > content.height()){
          content.height(sidebar.height());
        }

        if($(window).width() > 1024){

          if($(".page-sidebar").hasClass("scroll")){
            let doc_height = 0;
            if($("body").hasClass("page-container-boxed")){
              doc_height = $(document).height() - 40;
            }
            else {
              doc_height = $(window).height();
            }
            $(".page-sidebar").height(doc_height);
          }

          if($(".content-frame-body").height() < $(document).height()-162){
            $(".content-frame-body,.content-frame-right,.content-frame-left").height($(document).height()-162);
          }else{
            $(".content-frame-right,.content-frame-left").height($(".content-frame-body").height());
          }

          $(".content-frame-left").show();
          $(".content-frame-right").show();
        }else{
          $(".content-frame-body").height($(".content-frame").height()-80);

          if($(".page-sidebar").hasClass("scroll"))
          $(".page-sidebar").css("height","");
        }

        if($(window).width() < 1200){
          if($("body").hasClass("page-container-boxed")){
            $("body").removeClass("page-container-boxed").data("boxed","1");
          }
        }else{
          if($("body").data("boxed") === "1"){
            $("body").addClass("page-container-boxed").data("boxed","");
          }
        }
      }

      function playAudio(file){
        if(file === 'alert'){
          let audioAlert = <HTMLVideoElement> document.getElementById('audio-alert');
          audioAlert.play();
        }

        if(file === 'fail'){
          let audioFail = <HTMLVideoElement> document.getElementById('audio-fail');
          audioFail.play();
        }
      }

      function x_navigation_minimize(action){
        if(action == 'open'){
          $(".page-container").removeClass("page-container-wide");
          $(".page-sidebar .x-navigation").removeClass("x-navigation-minimized");
          $(".x-navigation-minimize").find(".fa").removeClass("fa-indent").addClass("fa-dedent");
          $(".page-sidebar.scroll").mCustomScrollbar("update");
        }

        if(action == 'close'){
          $(".page-container").addClass("page-container-wide");
          $(".page-sidebar .x-navigation").addClass("x-navigation-minimized");
          $(".x-navigation-minimize").find(".fa").removeClass("fa-dedent").addClass("fa-indent");
          $(".page-sidebar.scroll").mCustomScrollbar("disable",true);
        }

        $(".x-navigation li.active").removeClass("active");

      }
    })
  }

  menuLogado(m): string {
    if (Object.keys(m).length > 0){
      let dataHtml = ``, urlAtual = '';

      m.map(mn => {
        urlAtual = this.router.url;

        if (mn.idTipoMenu == 1 && (!mn.hasOwnProperty("elimina"))) {
          dataHtml += `<li` + (urlAtual===mn.url.replace('/uf','') ? ` class="active"` : ``) + `><a href="`+ mn.url +`"><span class="`+ mn.icone +`"></span> <span class="xn-text">`+ mn.menu +`</span></a></li>`;
          mn.elimina = true;
        }
        else if (mn.idTipoMenu == 2 && (!mn.hasOwnProperty("elimina"))) {
          dataHtml += `<li class="xn-openable"><a href="`+ (mn.url || "#") +`"><span class="`+ mn.icone +`"></span> <span class="xn-text">`+ mn.menu +`</span></a>`;
          dataHtml += this.buscarMenuFilhos(mn.idMenu, m);
          dataHtml += `</li>`;
          mn.elimina = true;
        }
      });

      return dataHtml;
    }
  }

  buscarMenuFilhos(idMenu, menus){
    let dataHtml = ``;
    let itens = menus.filter(m => { return m.idMenuPai === idMenu });
    if (itens.length > 0) {
      // dataHtml += `<ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">`;
      dataHtml += `<ul>`;
      itens.forEach(obj => {
        if (obj.idTipoMenu === 1 && (!obj.hasOwnProperty("elimina"))) {
          dataHtml += `<li><a href="`+ obj.url +`"><span class="`+ obj.icone +`"></span> <span class="xn-text">`+ obj.menu +`</span></a></li>`;
          dataHtml += this.buscarMenuFilhos(obj.idMenu, menus);
          obj.elimina = true;
        }
        else if (obj.idTipoMenu === 2 && (!obj.hasOwnProperty("elimina"))) {
          dataHtml += `<li class="xn-openable"><a href="`+ (obj.url || "#") +`"><span class="`+ obj.icone +`"></span> <span class="xn-text">`+ obj.menu +`</span></a>`;
          dataHtml += this.buscarMenuFilhos(obj.idMenu, menus);
          dataHtml += `</li>`;
          obj.elimina = true;
        }
      })
      dataHtml += `</ul>`;
    }

    return dataHtml;
  }

  buscarProjetos() {
    this.servicoProjeto.listarProjetos({}).subscribe(rsp => {
      this.projetos = rsp.lista;
    }, (err) => {
      this.servicoMsg.exibeErro(err);
    });
  }
}
