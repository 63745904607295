import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeModule } from 'ng2-tree';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgScrollbarModule } from 'ngx-scrollbar';
// import * as FileSaver from 'file-saver';
// import { saveAs } from 'file-saver';

import * as $ from 'jquery';
import { OwlModule } from 'ngx-owl-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { } from 'googlemaps';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddHttpHeaderInterceptor } from './servicos/srv.httpheaderinterceptor';

import { ROUTES } from './app.routes';

import { NeedAuthGuard } from './auth.guard';

import { AppComponent } from './app.component';
import { CepStr } from './pipes/cep-str.pipe';
import { CpfStr, CpfMaskStr } from './pipes/cpf-str.pipe';
import { CnpjStr } from './pipes/cnpj-str.pipe';
import { FiltroAcesso } from './pipes/filtro-acesso.pipe';
import { LoginComponent } from './modulos/login/login.component';
import { HomeComponent } from './modulos/home/home.component';
import { PaginaBarraLateralComponent } from './modulos/pagina-barra-lateral/pagina-barra-lateral.component';
import { PaginaBarraSuperiorComponent } from './modulos/pagina-barra-superior/pagina-barra-superior.component';
import { PaginaBarraNavegacaoComponent } from './modulos/pagina-barra-navegacao/pagina-barra-navegacao.component';
import { PaginacaoComponent } from './modulos/shared/paginacao.component';
import { PopTrocarProjetoComponent } from './modulos/pop-trocar-projeto/pop-trocar-projeto.component';

import { CampanhaAdicionarComponent } from './modulos/campanha/adicionar.component';
import { CampanhaConfiguracoesComponent } from './modulos/campanha/configuracoes.component';
import { CampanhaEditarComponent } from './modulos/campanha/editar.component';
import { CampanhaListarComponent } from './modulos/campanha/listar.component';
import { CampanhaPesquisarComponent } from './modulos/campanha/pesquisar.component';
import { CampanhaVisualizarComponent } from './modulos/campanha/visualizar.component';

import { CarregandoComponent } from './modulos/shared/carregando.component';

import { CategoriaAdicionarComponent } from './modulos/categoria/adicionar.component';
import { CategoriaEditarComponent } from './modulos/categoria/editar.component';
import { CategoriaListarComponent } from './modulos/categoria/listar.component';
import { CategoriaPesquisarComponent } from './modulos/categoria/pesquisar.component';
import { CategoriaVisualizarComponent } from './modulos/categoria/visualizar.component';

import { ClienteAdicionarComponent } from './modulos/cliente/adicionar.component';
import { ClienteListarComponent } from './modulos/cliente/listar.component';
import { ClientePesquisarComponent } from './modulos/cliente/pesquisar.component';
import { ClienteVisualizarComponent } from './modulos/cliente/visualizar.component';

import { ClienteGrupoAdicionarComponent } from './modulos/cliente-grupo/adicionar.component';
import { ClienteGrupoEditarComponent } from './modulos/cliente-grupo/editar.component';
import { ClienteGrupoListarComponent } from './modulos/cliente-grupo/listar.component';
import { ClienteGrupoPesquisarComponent } from './modulos/cliente-grupo/pesquisar.component';
import { ClienteGrupoVisualizarComponent } from './modulos/cliente-grupo/visualizar.component';

import { DashboardComponent } from './modulos/dashboard/dashboard.component';

import { DescontoListarComponent } from './modulos/desconto/listar.component';
import { DescontoPesquisarComponent } from './modulos/desconto/pesquisar.component';
import { DescontoPendentePesquisarComponent } from './modulos/descontoPendente/pesquisar.component';
import { DescontoPendenteListarComponent } from './modulos/descontoPendente/listar.component';

import { DevolucaoListarComponent } from './modulos/devolucao/listar.component';
import { DevolucaoPesquisarComponent } from './modulos/devolucao/pesquisar.component';

import { EstabelecimentoAdicionarComponent } from './modulos/estabelecimento/adicionar.component';
import { EstabelecimentoEditarComponent } from './modulos/estabelecimento/editar.component';
import { EstabelecimentoPesquisarComponent } from './modulos/estabelecimento/pesquisar.component';
import { EstabelecimentoListarComponent } from './modulos/estabelecimento/listar.component';
import { EstabelecimentoVisualizarComponent } from './modulos/estabelecimento/visualizar.component';

import { FornecedorAdicionarComponent } from './modulos/fornecedor/adicionar.component';
import { FornecedorEditarComponent } from './modulos/fornecedor/editar.component';
import { FornecedorListarComponent } from './modulos/fornecedor/listar.component';
import { FornecedorPesquisarComponent } from './modulos/fornecedor/pesquisar.component';
import { FornecedorVisualizarComponent } from './modulos/fornecedor/visualizar.component';

import { MarcaAdicionarComponent } from './modulos/marca/adicionar.component';
import { MarcaEditarComponent } from './modulos/marca/editar.component';
import { MarcaListarComponent } from './modulos/marca/listar.component';
import { MarcaPesquisarComponent } from './modulos/marca/pesquisar.component';
import { MarcaVisualizarComponent } from './modulos/marca/visualizar.component';

import { MensagemAlertaComponent } from './modulos/mensagem-alerta/mensagem-alerta.component';

import { MinhaContaComponent } from './modulos/minha-conta/minha-conta.component';

import { ProdutoAdicionarComponent } from './modulos/produto/adicionar.component';
import { ProdutoEditarComponent } from './modulos/produto/editar.component';
import { ProdutoListarComponent } from './modulos/produto/listar.component';
import { ProdutoPesquisarComponent } from './modulos/produto/pesquisar.component';
import { ProdutoVisualizarComponent } from './modulos/produto/visualizar.component';

import { PromocaoListarComponent } from './modulos/promocao/listar.component';
import { PromocaoPesquisarComponent } from './modulos/promocao/pesquisar.component';

import { ProjetoAdicionarComponent } from './modulos/projeto/adicionar.component';
import { ProjetoEditarComponent } from './modulos/projeto/editar.component';
import { ProjetoListarComponent } from './modulos/projeto/listar.component';
import { ProjetoPesquisarComponent } from './modulos/projeto/pesquisar.component';
import { ProjetoVisualizarComponent } from './modulos/projeto/visualizar.component';

import { RedeAdicionarComponent } from './modulos/rede/adicionar.component';
import { RedeEditarComponent } from './modulos/rede/editar.component';
import { RedeListarComponent } from './modulos/rede/listar.component';
import { RedePesquisarComponent } from './modulos/rede/pesquisar.component';
import { RedeVisualizarComponent } from './modulos/rede/visualizar.component';

import { RedefinirSenhaComponent } from './modulos/redefinir-senha/redefinir-senha.component';

import { EncryptDecrypt } from './servicos/srv.encryptdecrypt';

import { ServicoCidade, ServicoEstado, ServicoMenu } from './servicos/srv.configuracao';
import { ServicoDashboard } from './servicos/srv.dashboard';
import { ServicoCategoria, ServicoFornecedor, ServicoMarca, ServicoProduto } from './servicos/srv.produto';
import { ServicoEstabelecimento, ServicoRede } from './servicos/srv.rede';
import { ServicoMsg } from './servicos/srv.msg';
import { ServicoSessao } from './servicos/srv.sessao';

import { TrueFalseValueDirective } from './diretivas/true-false-value.directive';

import { UsuarioConfiguracoesComponent } from './modulos/usuario/configuracoes.component';
import { UsuarioListarComponent } from './modulos/usuario/listar.component';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

// COMPONENTES CUSTOMIZADOS POR CLIENTES
import { LorealCampanhaProdutoPesquisarComponent } from './modulos/custom/loreal-campanha-produto/pesquisar.component';
import { LorealCampanhaProdutoListarComponent } from './modulos/custom/loreal-campanha-produto/listar.component';
import { LorealSimulaLimitePontosComponent } from './modulos/custom/loreal-simula-limite-pontos/loreal-simula-limite-pontos.component';
import { LorealDescontoPesquisarComponent } from './modulos/custom/loreal-desconto/pesquisar.component';
import { LorealDescontoListarComponent } from './modulos/custom/loreal-desconto/listar.component';

registerLocaleData(ptBr)

@NgModule({
  declarations: [
    AppComponent,
    CepStr,
    CpfStr,
    CpfMaskStr,
    CnpjStr,
    FiltroAcesso,
    TrueFalseValueDirective,
    // CarouselHolderComponent,
    HomeComponent,
    LoginComponent,
    PaginaBarraLateralComponent,
    PaginaBarraSuperiorComponent,
    PaginaBarraNavegacaoComponent,
    CampanhaAdicionarComponent,
    CampanhaConfiguracoesComponent,
    CampanhaEditarComponent,
    CampanhaListarComponent,
    CampanhaPesquisarComponent,
    CampanhaVisualizarComponent,
    CategoriaAdicionarComponent,
    CategoriaEditarComponent,
    CategoriaListarComponent,
    CategoriaPesquisarComponent,
    CategoriaVisualizarComponent,
    ClienteAdicionarComponent,
    ClienteListarComponent,
    ClientePesquisarComponent,
    ClienteVisualizarComponent,
    ClienteGrupoAdicionarComponent,
    ClienteGrupoEditarComponent,
    ClienteGrupoListarComponent,
    ClienteGrupoPesquisarComponent,
    ClienteGrupoVisualizarComponent,
    DashboardComponent,
    DescontoListarComponent,
    DescontoPesquisarComponent,
    DescontoPendenteListarComponent,
    DescontoPendentePesquisarComponent,
    DevolucaoListarComponent,
    DevolucaoPesquisarComponent,
    EstabelecimentoAdicionarComponent,
    EstabelecimentoEditarComponent,
    EstabelecimentoListarComponent,
    EstabelecimentoPesquisarComponent,
    EstabelecimentoVisualizarComponent,
    FornecedorAdicionarComponent,
    FornecedorEditarComponent,
    FornecedorListarComponent,
    FornecedorPesquisarComponent,
    FornecedorVisualizarComponent,
    MarcaAdicionarComponent,
    MarcaEditarComponent,
    MarcaPesquisarComponent,
    MarcaListarComponent,
    MarcaVisualizarComponent,
    MensagemAlertaComponent,
    MinhaContaComponent,
    PopTrocarProjetoComponent,
    ProdutoAdicionarComponent,
    ProdutoEditarComponent,
    ProdutoListarComponent,
    ProdutoPesquisarComponent,
    ProdutoVisualizarComponent,
    PromocaoListarComponent,
    PromocaoPesquisarComponent,
    ProjetoAdicionarComponent,
    ProjetoEditarComponent,
    ProjetoListarComponent,
    ProjetoPesquisarComponent,
    ProjetoVisualizarComponent,
    RedeAdicionarComponent,
    RedeEditarComponent,
    RedeListarComponent,
    RedePesquisarComponent,
    RedeVisualizarComponent,
    RedefinirSenhaComponent,
    UsuarioConfiguracoesComponent,
    UsuarioListarComponent,
    PaginacaoComponent,
    CarregandoComponent,
    LorealCampanhaProdutoPesquisarComponent,
    LorealCampanhaProdutoListarComponent,
    LorealSimulaLimitePontosComponent,
    LorealDescontoPesquisarComponent,
    LorealDescontoListarComponent
  ],
  imports: [
    BrowserModule
    , BrowserAnimationsModule
    , HttpClientModule
    , RouterModule.forRoot(ROUTES)
    , NgSelectModule
    , FormsModule
    , BsDatepickerModule.forRoot()
    , TreeModule
    , NgxMaskModule.forRoot()
    , NgbModule.forRoot()
    , NgxDropzoneModule
    , NgxSpinnerModule
    , NgScrollbarModule
    // , FileSaver
    , OwlModule
    , CarouselModule
  ],
  providers: [
    EncryptDecrypt
    , NeedAuthGuard
    , NgbActiveModal
    , ServicoCategoria
    , ServicoCidade
    , ServicoDashboard
    , ServicoEstado
    , ServicoEstabelecimento
    , ServicoFornecedor
    , ServicoMarca
    , ServicoMenu  /* DEVIDO AO USO NO NeedAuthGuard */
    , ServicoMsg
    , ServicoProduto
    , ServicoRede
    , ServicoSessao
    , { provide: HTTP_INTERCEPTORS, useClass: AddHttpHeaderInterceptor, multi: true }
    , { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
