import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ServicoSessao } from '../../servicos/srv.sessao';

declare var $: any;

@Component({
  selector: 'tsv2-pagina-barra-superior',
  templateUrl: './pagina-barra-superior.component.html',
  providers: [
    ServicoSessao
  ]
})
export class PaginaBarraSuperiorComponent implements OnInit {
  popUpSair: boolean = false;
  popMsgs: boolean = false;
  barraNav: boolean = true;
  msgTitulo: string = `<span class="fa fa-sign-out-alt"></span> <strong>Sair</strong> ?`;
  msgSubTitulo: string = `<p>Deseja realmente sair?</p>
  <p>Clique em <b>Não</b>, caso deseje continuar ou <b>Sim</b> para sair do sistema.</p>`;

  @Input() exibir: boolean;

  @Output() exibirBarraNav = new EventEmitter();

  constructor(
    private servicoSessao: ServicoSessao
  ) { }

  ngOnInit() {
    // this.applyJQuery();
  }

  ngAfterViewInit() {
    this.applyJQuery();
  }

  sair(){
    // this.popUpSair = true;

    // this.spinner.show();
    this.servicoSessao.removerSessao('tsToken').then(r1 => {
      this.servicoSessao.removerSessao('tsDadosSessao').then(r2 => {
        // this.spinner.hide();
        window.location.href='/login';
      }, (err) => {
        // this.spinner.hide();
        // this.servicoMsg.exibeErro({"statusCode": 401});
      });
    }, (err) => {
      // this.spinner.hide();
      // this.servicoMsg.exibeErro({"statusCode": 401});
    });
  }

  continuar(pagina){
    this.popUpSair = false;
  }

  exibirMsgs() {
    this.popMsgs = this.popMsgs ? false : true;
  }

  exibirNavegacao() {
    this.barraNav = this.barraNav ? false : true;

    this.exibirBarraNav.emit(this.barraNav);
  }

  applyJQuery(){
    $(document).ready(function(){
      $(".x-navigation-control").click(function(){
          $(this).parents(".x-navigation").toggleClass("x-navigation-open");

          onresize(200);

          return false;
      });

      if($(".page-navigation-toggled").length > 0){
          x_navigation_minimize("close");
      }

      $(".x-navigation-minimize").click(function(){

          if($(".page-sidebar .x-navigation").hasClass("x-navigation-minimized")){
              $(".page-container").removeClass("page-navigation-toggled");
              x_navigation_minimize("open");
          }else{
              $(".page-container").addClass("page-navigation-toggled");
              x_navigation_minimize("close");
          }

          onresize(200);

          return false;
      });

      /* MESSAGE BOX */
      $(".mb-control").on("click",function(){
        var box = $($(this).data("box"));
        if(box.length > 0){
          box.toggleClass("open");

          var sound = box.data("sound");

          if(sound === 'alert')
          playAudio('alert');

          if(sound === 'fail')
          playAudio('fail');

          //(+) RESET ALL VALUES
          var $inputs = box.find(':input');
          $inputs.each(function() {
            let tagName = $(this).prop("tagName");
            if (tagName=='INPUT' || tagName=='SELECT'){
              $(this).val("");
            }
          });
          //(+) RESET ALL VALUES
        }
        return false;
      });
      $(".mb-control-close").on("click",function(){
        $(this).parents(".message-box").removeClass("open");
        return false;
      });
      /* MESSAGE BOX */

      /* XN-SEARCH */
      $(".xn-search").on("click",function(){
          $(this).find("input").focus();
      })
      /* END XN-SEARCH */

      function playAudio(file){
        if(file === 'alert'){
          let audioAlert = <HTMLVideoElement> document.getElementById('audio-alert');
          audioAlert.play();
        }

        if(file === 'fail'){
          let audioFail = <HTMLVideoElement> document.getElementById('audio-fail');
          audioFail.play();
        }
      }

      function onresize(timeout){
          timeout = timeout ? timeout : 200;

          setTimeout(function(){
              page_content_onresize();
          },timeout);
      }

      function x_navigation_minimize(action){

          if(action == 'open'){
              $(".page-container").removeClass("page-container-wide");
              $(".page-sidebar .x-navigation").removeClass("x-navigation-minimized");
              $(".x-navigation-minimize").find(".fa").removeClass("fa-indent").addClass("fa-outdent");
              // $(".page-sidebar.scroll").mCustomScrollbar("update");
          }

          if(action == 'close'){
              $(".page-container").addClass("page-container-wide");
              $(".page-sidebar .x-navigation").addClass("x-navigation-minimized");
              $(".x-navigation-minimize").find(".fa").removeClass("fa-outdent").addClass("fa-indent");
              // $(".page-sidebar.scroll").mCustomScrollbar("disable",true);
          }

          $(".x-navigation li.active").removeClass("active");

      }

      function page_content_onresize(){
          $(".page-content,.content-frame-body,.content-frame-right,.content-frame-left").css("width","").css("height","");

          var content_minus = 0;
          content_minus = ($(".page-container-boxed").length > 0) ? 40 : content_minus;
          content_minus += ($(".page-navigation-top-fixed").length > 0) ? 50 : 0;

          var content = $(".page-content");
          var sidebar = $(".page-sidebar");

          if(content.height() < $(document).height() - content_minus){
              content.height($(document).height() - content_minus);
          }

          if(sidebar.height() > content.height()){
              content.height(sidebar.height());
          }

          if($(window).width() > 1024){

            if($(".page-sidebar").hasClass("scroll")){
              let doc_height = 0;
              if($("body").hasClass("page-container-boxed")){
                doc_height = $(document).height() - 40;
              }
              else {
                doc_height = $(window).height();
              }
              $(".page-sidebar").height(doc_height);
            }

            if($(".content-frame-body").height() < $(document).height()-162){
              $(".content-frame-body,.content-frame-right,.content-frame-left").height($(document).height()-162);
            }else{
              $(".content-frame-right,.content-frame-left").height($(".content-frame-body").height());
            }

            $(".content-frame-left").show();
            $(".content-frame-right").show();
          }else{
            $(".content-frame-body").height($(".content-frame").height()-80);

            if($(".page-sidebar").hasClass("scroll"))
            $(".page-sidebar").css("height","");
          }

          if($(window).width() < 1200){
              if($("body").hasClass("page-container-boxed")){
                  $("body").removeClass("page-container-boxed").data("boxed","1");
              }
          }else{
              if($("body").data("boxed") === "1"){
                  $("body").addClass("page-container-boxed").data("boxed","");
              }
          }
      }
    });
  }

}
