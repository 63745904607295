import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoFornecedor, ServicoMarca } from '../../servicos/srv.produto';

@Component({
  selector: 'tsv2-mrc-editar',
  templateUrl: './editar.component.html',
  providers: [ ServicoMsg, ServicoFornecedor, ServicoMarca ]
})
export class MarcaEditarComponent implements OnInit {
  exibirBarra: boolean = true;

  p: any;
  fornecedores: any;
  idMarca: number;
  idFornecedor: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMsg: ServicoMsg,
    private servicoMarca: ServicoMarca
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.idMarca = parseInt(id);
    this.servicoMarca.consultarMarca(this.idMarca).subscribe(res => {
      this.p = res.lista[0];
      this.servicoFornecedor.listarFornecedores({}).subscribe(rsp => {
        this.fornecedores = rsp.lista;
        // this.spinner.hide();
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  alterarMarca(frmValues){
    // this.spinner.show();
    this.servicoMarca.alterarMarca(frmValues).subscribe(id => {
      let url = '/marca/visualizar/' + id;
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Alterada!', 'Marca alterada com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
