// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // campanha_api: 'http://localhost:57861/api',
  // cliente_api: 'http://localhost:55770/api',
  // configuracao_api: 'http://localhost:51595/api',
  // dashboard_api: 'http://localhost:60463/api',
  // produto_api: 'http://localhost:50132/api',
  // projeto_api: 'http://localhost:53478/api',
  // rede_api: 'http://localhost:56036/api',

  // campanha_api: 'https://campanhahml.trackingsales.com.br/api',
  // cliente_api: 'https://clientehml.trackingsales.com.br/api',
  // configuracao_api: 'https://configuracaohml.trackingsales.com.br/api',
  // dashboard_api: 'https://dashboardhml.trackingsales.com.br/api',
  // produto_api: 'https://produtohml.trackingsales.com.br/api',
  // projeto_api: 'https://projetohml.trackingsales.com.br/api',
  // rede_api: 'https://redehml.trackingsales.com.br/api'

  campanha_api: 'https://campanha.trackingsales.com.br/api',
  cliente_api: 'https://cliente.trackingsales.com.br/api',
  configuracao_api: 'https://configuracao.trackingsales.com.br/api',
  dashboard_api: 'https://dashboard.trackingsales.com.br/api',
  produto_api: 'https://produto.trackingsales.com.br/api',
  projeto_api: 'https://projeto.trackingsales.com.br/api',
  rede_api: 'https://rede.trackingsales.com.br/api'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
