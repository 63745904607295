import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicoSessao } from './srv.sessao';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../app.error-handler'

import { environment } from '../../environments/environment';

@Injectable()
export class ServicoCampanha {

  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarCampanha(frm: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanha/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  alterarCampanha(frm: any) {
    return this.http.put<any>(`${environment.campanha_api}/campanha/alterar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  async alterarCampanhaAsync(frm: any) {
    let res = await this.http.put<any>(`${environment.campanha_api}/campanha/alterar`,frm).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res;
  }

  consultarCampanha(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanha/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  async consultarCampanhaAsync(id: number) {
    let res = await this.http.get<any>(`${environment.campanha_api}/campanha/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  listarCampanhas(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanha/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarCampanhaPorRede(psq: any) {
    let res = await this.http.post<any>(`${environment.campanha_api}/campanhaPorRede/listar/`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista;
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.campanha_api}/campanha/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCampanhaTipo {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarCampanhaTipo(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaTipo/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaTipos(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaTipo/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

}

@Injectable()
export class ServicoCampanhaGrupo {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarCampanhaGrupo(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaGrupo/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaGrupos(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaGrupo/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

}

@Injectable()
export class ServicoCampanhaGrupoRegra {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarCampanhaGrupoRegra(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaGrupoRegra/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaGrupoRegras(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaGrupoRegra/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

}

@Injectable()
export class ServicoCampanhaRequisito {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarCampanhaRequisito(frm: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaRequisito/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarCampanhaRequisito(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaRequisito/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaRequisitos(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaRequisito/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.campanha_api}/campanhaRequisito/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCampanhaProduto {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarCampanhaProduto(frm: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaProduto/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  async adicionarCampanhaProdutoAsync(frm: any) {
    let res = await this.http.post<any>(`${environment.campanha_api}/campanhaProduto/adicionar`,frm).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res;
  }

  async consultarCampanhaProduto(id: number) {
    let res = await this.http.get<any>(`${environment.campanha_api}/campanhaProduto/consultar/`+id).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res.lista[0];
  }

  listarCampanhaProdutos(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaProduto/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  async listarCampanhaProdutosAsync(psq: any) {
    let res = await this.http.post<any>(`${environment.campanha_api}/campanhaProduto/listar`,psq).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res;
  }

  async alterarCampanhaProdutoAsync(frm: any) {
    let res = await this.http.put<any>(`${environment.campanha_api}/campanhaProduto/alterar`,frm).pipe(catchError(ErrorHandler.handleError)).toPromise();
    return res;
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.campanha_api}/campanhaProduto/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCampanhaClienteGrupo {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  adicionarCampanhaClienteGrupo(frm: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaClienteGrupo/adicionar`,frm).pipe(catchError(ErrorHandler.handleError));
  }

  consultarCampanhaClienteGrupo(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaClienteGrupo/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaClienteGrupos(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaClienteGrupo/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }

  remover(id: number) {
    return this.http.delete<any>(`${environment.campanha_api}/campanhaClienteGrupo/remover/`+id).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCampanhaTipoRequisito {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarCampanhaTipoRequisito(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaTipoRequisito/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaTipoRequisitos(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaTipoRequisito/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCampanhaRequisitoAplicacao {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarCampanhaRequisitoAplicacao(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaRequisitoAplicacao/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaRequisitoAplicacoes(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaRequisitoAplicacao/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCampanhaTipoLimite {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarCampanhaTipoLimite(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaTipoLimite/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaTipoLimite(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaTipoLimite/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCampanhaTipoDesconto {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarCampanhaTipoDesconto(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaTipoDesconto/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaTipoDesconto(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaTipoDesconto/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCampanhaTipoPonto {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarCampanhaTipoPonto(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaTipoPonto/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaTipoPonto(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaTipoPonto/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }
}

@Injectable()
export class ServicoCampanhaTipoSorteio {
  constructor(
    private http: HttpClient,
    private servicoSessao: ServicoSessao
  ) { }

  consultarCampanhaTipoSorteio(id: number) {
    return this.http.get<any>(`${environment.campanha_api}/campanhaTipoSorteio/consultar/`+id).pipe(catchError(ErrorHandler.handleError));
  }

  listarCampanhaTipoSorteio(psq: any) {
    return this.http.post<any>(`${environment.campanha_api}/campanhaTipoSorteio/listar`,psq).pipe(catchError(ErrorHandler.handleError));
  }
}
