import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Util } from '../../servicos/srv.util';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoEstabelecimento, ServicoRede } from '../../servicos/srv.rede';
import { ServicoCidade, ServicoEstado } from '../../servicos/srv.configuracao';

@Component({
  selector: 'tsv2-stb-adicionar',
  templateUrl: './adicionar.component.html',
  providers: [ ServicoMsg, ServicoCidade, ServicoEstabelecimento, ServicoEstado, ServicoRede, Util ]
})
export class EstabelecimentoAdicionarComponent implements OnInit {
  exibirBarra: boolean = true;

  redes: any;
  ufs: any;
  cidades: any;
  psq: any;
  cnpjValido: boolean;
  cepValido: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCidade: ServicoCidade,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoEstado: ServicoEstado,
    private servicoRede: ServicoRede,
    private util: Util
  ) { }

  ngOnInit() {
    // this.spinner.show();
    this.cnpjValido = true;
    this.cepValido = true;
    this.servicoRede.listarRedes({}).subscribe(rsp => {
      this.redes = rsp.lista;
      this.servicoEstado.listarEstados({}).subscribe(
        estados => {
          this.ufs = estados.lista;
          // this.spinner.hide();
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  adicionarEstabelecimento(frmValues){
    // this.spinner.show();

    let endCompleto = this.BuscarEndCompleto(frmValues);

    this.util.buscarLatLngPeloEnd(endCompleto).then(obj => {
      if ((obj.lat != null && obj.lat != -999) || (obj.lng != null && obj.lng != -999)){
        frmValues.latitude = obj.lat.toFixed(8);
        frmValues.longitude = obj.lng.toFixed(8);

        this.servicoEstabelecimento.adicionarEstabelecimento(frmValues).subscribe(id => {
          let url = '/estabelecimento/visualizar/' + id;
          // this.spinner.hide();
          this.servicoMsg.exibeSucesso('Criado!', 'Estabelecimento criado com sucesso!').then(r1 => {
            if (r1.success){
              this.router.navigate([url]);
            }
          }, (err: any) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }, (err: any) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }
      else {
        // this.spinner.hide();
        this.servicoMsg.exibeErro({"statusCode": 999}).then(r1 => {});
      }
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro({"statusCode": 999}).then(r1 => {});
    });
  }

  listarCidades(idEstado){
    // this.spinner.show();
    this.servicoCidade.listarCidades({"idEstado": idEstado}).subscribe(
      cidades => {
        this.cidades = cidades.lista;

        if (cidades.lista.length > 1){
          // this.spinner.hide();
        }
        else if (cidades.lista.length === 1){
          // this.spinner.hide();
        }
        else {
          this.cidades = null;
          // this.spinner.hide();
        }
      }
    );
  }

  validaCNPJ(valor){
    var vl = valor.replace(/[^0-9]/g, "");

    if (vl.length > 0) {
      if (!this.util.validaCNPJ(vl)) {
        this.cnpjValido = false;
      }
      else {
        this.cnpjValido = true;
      }
    }
    else {
      this.cnpjValido = true;
    }
  }

  validaCEP(valor){
    var vl = valor.replace(/[^0-9]/g, "");

    if (vl.length > 0 && vl.length < 8) {
      this.cepValido = false;
    }
    else {
      this.cepValido = true;
    }
  }

  BuscarEndCompleto(obj){
    let idTemp = (obj.hasOwnProperty("idCidade") || obj.idCidade.length > 0) ? parseInt(obj.idCidade) : -99;
    let dataCidade = this.cidades.find(x => x.idCidade === idTemp);
    idTemp = (obj.hasOwnProperty("idEstado") || obj.idEstado.length > 0) ? parseInt(obj.idEstado) : -99;
    let dataUf = this.ufs.find(x => x.idEstado === idTemp);

    let endereco = obj.endereco.length > 0 ? obj.endereco : '';
    endereco += obj.numero.length > 0 ? ', ' + obj.numero : '';
    endereco += obj.bairro.length > 0 ? ', ' + obj.bairro : '';
    endereco += dataCidade.cidade.length > 0 ? ', ' + dataCidade.cidade : '';
    endereco += dataUf.uf.length > 0 ? ', ' + dataUf.uf : '';

    return endereco;
  }

}
