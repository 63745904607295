import { Routes } from '@angular/router'

import { CampanhaAdicionarComponent } from './modulos/campanha/adicionar.component';
import { CampanhaEditarComponent } from './modulos/campanha/editar.component';
import { CampanhaPesquisarComponent } from './modulos/campanha/pesquisar.component';
import { CampanhaVisualizarComponent } from './modulos/campanha/visualizar.component';

import { CategoriaAdicionarComponent } from './modulos/categoria/adicionar.component';
import { CategoriaEditarComponent } from './modulos/categoria/editar.component';
import { CategoriaPesquisarComponent } from './modulos/categoria/pesquisar.component';
import { CategoriaVisualizarComponent } from './modulos/categoria/visualizar.component';

import { ClienteAdicionarComponent } from './modulos/cliente/adicionar.component';
import { ClientePesquisarComponent } from './modulos/cliente/pesquisar.component';
import { ClienteVisualizarComponent } from './modulos/cliente/visualizar.component';

import { ClienteGrupoAdicionarComponent } from './modulos/cliente-grupo/adicionar.component';
import { ClienteGrupoEditarComponent } from './modulos/cliente-grupo/editar.component';
import { ClienteGrupoPesquisarComponent } from './modulos/cliente-grupo/pesquisar.component';
import { ClienteGrupoVisualizarComponent } from './modulos/cliente-grupo/visualizar.component';

import { DashboardComponent } from './modulos/dashboard/dashboard.component';

import { DescontoPesquisarComponent } from './modulos/desconto/pesquisar.component';
import { DescontoPendentePesquisarComponent } from './modulos/descontoPendente/pesquisar.component';

import { DevolucaoPesquisarComponent } from './modulos/devolucao/pesquisar.component';

import { EstabelecimentoAdicionarComponent } from './modulos/estabelecimento/adicionar.component';
import { EstabelecimentoEditarComponent } from './modulos/estabelecimento/editar.component';
import { EstabelecimentoPesquisarComponent } from './modulos/estabelecimento/pesquisar.component';
import { EstabelecimentoVisualizarComponent } from './modulos/estabelecimento/visualizar.component';

import { FornecedorAdicionarComponent } from './modulos/fornecedor/adicionar.component';
import { FornecedorEditarComponent } from './modulos/fornecedor/editar.component';
import { FornecedorPesquisarComponent } from './modulos/fornecedor/pesquisar.component';
import { FornecedorVisualizarComponent } from './modulos/fornecedor/visualizar.component';

import { HomeComponent } from './modulos/home/home.component';
import { LoginComponent } from './modulos/login/login.component';

import { MarcaAdicionarComponent } from './modulos/marca/adicionar.component';
import { MarcaEditarComponent } from './modulos/marca/editar.component';
import { MarcaPesquisarComponent } from './modulos/marca/pesquisar.component';
import { MarcaVisualizarComponent } from './modulos/marca/visualizar.component';

import { MinhaContaComponent } from './modulos/minha-conta/minha-conta.component';

import { ProdutoAdicionarComponent } from './modulos/produto/adicionar.component';
import { ProdutoEditarComponent } from './modulos/produto/editar.component';
import { ProdutoPesquisarComponent } from './modulos/produto/pesquisar.component';
import { ProdutoVisualizarComponent } from './modulos/produto/visualizar.component';

import { PromocaoPesquisarComponent } from './modulos/promocao/pesquisar.component';

import { ProjetoAdicionarComponent } from './modulos/projeto/adicionar.component';
import { ProjetoEditarComponent } from './modulos/projeto/editar.component';
import { ProjetoPesquisarComponent } from './modulos/projeto/pesquisar.component';
import { ProjetoVisualizarComponent } from './modulos/projeto/visualizar.component';

import { RedeAdicionarComponent } from './modulos/rede/adicionar.component';
import { RedeEditarComponent } from './modulos/rede/editar.component';
import { RedePesquisarComponent } from './modulos/rede/pesquisar.component';
import { RedeVisualizarComponent } from './modulos/rede/visualizar.component';

import { RedefinirSenhaComponent } from './modulos/redefinir-senha/redefinir-senha.component';

import { UsuarioListarComponent } from './modulos/usuario/listar.component';

import { NeedAuthGuard } from './auth.guard';

// COMPONENTES CUSTOMIZADOS POR CLIENTES
import { LorealCampanhaProdutoPesquisarComponent } from './modulos/custom/loreal-campanha-produto/pesquisar.component';
import { LorealSimulaLimitePontosComponent } from './modulos/custom/loreal-simula-limite-pontos/loreal-simula-limite-pontos.component';
import { LorealDescontoPesquisarComponent } from './modulos/custom/loreal-desconto/pesquisar.component';

export const ROUTES: Routes = [
  /* *** ACESSO PUBLICO *** */
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'redefinirSenha/:hash', component: RedefinirSenhaComponent },
  /* *** ACESSO PRIVADO *** */
  { path: 'campanha/adicionar', component: CampanhaAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'campanha/editar/:id', component: CampanhaEditarComponent, canActivate: [NeedAuthGuard] },
  { path: 'campanha/pesquisar', component: CampanhaPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'campanha/visualizar/:id', component: CampanhaVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'categoria/adicionar', component: CategoriaAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'categoria/editar/:id', component: CategoriaEditarComponent, canActivate: [NeedAuthGuard] },
  { path: 'categoria/pesquisar', component: CategoriaPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'categoria/visualizar/:id', component: CategoriaVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'cliente/adicionar', component: ClienteAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'cliente/pesquisar', component: ClientePesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'cliente/visualizar/:id', component: ClienteVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'clienteGrupo/adicionar', component: ClienteGrupoAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'clienteGrupo/editar/:id', component: ClienteGrupoEditarComponent, canActivate: [NeedAuthGuard] },
  { path: 'clienteGrupo/pesquisar', component: ClienteGrupoPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'clienteGrupo/visualizar/:id', component: ClienteGrupoVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'dashboard', component: DashboardComponent, canActivate: [NeedAuthGuard] },

  { path: 'desconto/pesquisar', component: DescontoPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'descontoPendente/pesquisar', component: DescontoPendentePesquisarComponent, canActivate: [NeedAuthGuard] },

  { path: 'devolucao/pesquisar', component: DevolucaoPesquisarComponent, canActivate: [NeedAuthGuard] },

  { path: 'estabelecimento/adicionar', component: EstabelecimentoAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'estabelecimento/editar/:id', component: EstabelecimentoEditarComponent, canActivate: [NeedAuthGuard] },
  { path: 'estabelecimento/pesquisar', component: EstabelecimentoPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'estabelecimento/visualizar/:id', component: EstabelecimentoVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'fornecedor/adicionar', component: FornecedorAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'fornecedor/editar/:id', component: FornecedorEditarComponent, canActivate: [NeedAuthGuard] },
  { path: 'fornecedor/pesquisar', component: FornecedorPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'fornecedor/visualizar/:id', component: FornecedorVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'marca/adicionar', component: MarcaAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'marca/editar/:id', component: MarcaEditarComponent, canActivate: [NeedAuthGuard] },
  { path: 'marca/pesquisar', component: MarcaPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'marca/visualizar/:id', component: MarcaVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'minha-conta', component: MinhaContaComponent, canActivate: [NeedAuthGuard] },

  { path: 'produto/adicionar', component: ProdutoAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'produto/editar/:id', component: ProdutoEditarComponent, canActivate: [NeedAuthGuard] },
  { path: 'produto/pesquisar', component: ProdutoPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'produto/visualizar/:id', component: ProdutoVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'promocao/pesquisar', component: PromocaoPesquisarComponent, canActivate: [NeedAuthGuard] },

  { path: 'projeto/adicionar', component: ProjetoAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'projeto/editar/:id', component: ProjetoEditarComponent, canActivate: [NeedAuthGuard] },
  { path: 'projeto/pesquisar', component: ProjetoPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'projeto/visualizar/:id', component: ProjetoVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'rede/adicionar', component: RedeAdicionarComponent, canActivate: [NeedAuthGuard] },
  { path: 'rede/editar/:id', component: RedeEditarComponent, canActivate: [NeedAuthGuard] },
  { path: 'rede/pesquisar', component: RedePesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'rede/visualizar/:id', component: RedeVisualizarComponent, canActivate: [NeedAuthGuard] },

  { path: 'usuario/listar', component: UsuarioListarComponent, canActivate: [NeedAuthGuard] },

  // COMPONENTES CUSTOMIZADOS POR CLIENTES
  { path: 'loreal/campanhaProduto/listar', component: LorealCampanhaProdutoPesquisarComponent, canActivate: [NeedAuthGuard] },
  { path: 'loreal/limitePontos/simular', component: LorealSimulaLimitePontosComponent, canActivate: [NeedAuthGuard] },
  { path: 'loreal/desconto/pesquisar', component: LorealDescontoPesquisarComponent, canActivate: [NeedAuthGuard] },

  { path: '**', redirectTo: 'dashboard' }
  // { path: '**', component: NotFoundComponent }
]
