import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tsv2-paginacao',
  templateUrl: './paginacao.component.html'
})
export class PaginacaoComponent implements OnInit {
  @Input() atual: number;
  @Input() total: number;
  @Input() paginas: any[];
  @Input() itens: number;
  @Input() form: any;
  // --
  @Output() trocar = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  emitirTroca(pagina: number){
    // console.log('### EMITIR TROCA ###');
    this.trocar.emit(pagina);
  }

}
