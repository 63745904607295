import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoRede } from '../../servicos/srv.rede';
import { ServicoCategoria, ServicoFornecedor, ServicoMarca, ServicoProduto, ServicoTipoEmbalagem, ServicoTipoCodigo } from '../../servicos/srv.produto';

@Component({
  selector: 'tsv2-prd-editar',
  templateUrl: './editar.component.html',
  providers: [ ServicoMsg, ServicoCategoria, ServicoFornecedor, ServicoMarca, ServicoProduto, ServicoRede, ServicoTipoEmbalagem, ServicoTipoCodigo ]
})
export class ProdutoEditarComponent implements OnInit {
  exibirBarra: boolean = true;

  categorias: any;
  fornecedores: any;
  marcas: any;
  tiposEmbalagem: any;
  tiposCodigo: any;
  redes: any;
  p: any;
  idProduto: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoCategoria: ServicoCategoria,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMsg: ServicoMsg,
    private servicoMarca: ServicoMarca,
    private servicoProduto: ServicoProduto,
    private servicoRede: ServicoRede,
    private servicoTipoEmbalagem: ServicoTipoEmbalagem,
    private servicoTipoCodigo: ServicoTipoCodigo
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.idProduto = parseInt(id);
    this.servicoCategoria.listarCategorias({}).subscribe(sc => {
      this.categorias = sc.lista;
      this.servicoFornecedor.listarFornecedores({}).subscribe(sf => {
        this.fornecedores = sf.lista;
        this.servicoMarca.listarMarcas({}).subscribe(sm => {
          this.marcas = sm.lista;
          this.servicoTipoEmbalagem.listarTiposEmbalagem({}).subscribe(ste => {
            this.tiposEmbalagem = ste.lista;
            this.servicoTipoCodigo.listarTiposCodigo({}).subscribe(stc => {
              this.tiposCodigo = stc.lista;
              this.servicoProduto.consultarProduto(this.idProduto).subscribe(sp => {
                this.p = sp.lista[0];
                // this.spinner.hide();
              }, (err) => {
                // this.spinner.hide();
                this.servicoMsg.exibeErro(err).then(r1 => {});
              });
            }, (err) => {
              // this.spinner.hide();
              this.servicoMsg.exibeErro(err).then(r1 => {});
            });
          }, (err) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  editarProduto(frmValues){
    // this.spinner.show();
    this.servicoProduto.alterarProduto(frmValues).subscribe(id => {
      let url = '/produto/visualizar/' + id;
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Alterado!', 'Produto alterado com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
