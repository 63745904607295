import { Response } from '@angular/http'
import { Observable, throwError } from 'rxjs'

export class ErrorHandler {
  static handleError(error: Response | any) {
    // let errorMessage: string
    let errorMessage: {}

    if (error instanceof Response) {
      // errorMessage = `Erro ${error.status} ao acessar a URL ${error.url}  - ${error.statusText}`
      errorMessage = {"msg": `Erro ${error.status} ao acessar a URL ${error.url}  - ${error.statusText}`};
    }
    else {
      // errorMessage = error.toString()
      errorMessage = {"statusCode": error.status, "statusText": error.statusText, "msg": error.message};
    }

    // console.log(errorMessage)
    // return Observable.throw(errorMessage)
    return throwError(errorMessage);
  }
}
