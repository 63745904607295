import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoProduto } from '../../servicos/srv.produto';

@Component({
  selector: 'tsv2-prd-visualizar',
  templateUrl: './visualizar.component.html',
  providers: [ ServicoMsg, ServicoProduto ]
})
export class ProdutoVisualizarComponent implements OnInit {
  exibirBarra: boolean = true;

  p: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoProduto: ServicoProduto
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.servicoProduto.consultarProduto(id).subscribe(res => {
      this.p = res.lista[0];
      // this.spinner.hide();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  removerProduto(id){
    let titulo = 'Remover o produto?';
    let url = '/produto/pesquisar/';
    let texto = 'Produto removido com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoProduto, texto).then(r1 => {
      if (r1.success){
        this.router.navigate([url]);
      }
    }, (err: any) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
