import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import swal from 'sweetalert2';

import { ServicoSessao } from './srv.sessao';

@Injectable()
export class ServicoMsg {

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoSessao: ServicoSessao
  ) { }

  exibeSucesso(titulo, texto): Promise<any>{
    return new Promise((resolve,reject)=>{
      swal(titulo, texto, 'success').then((result) => {
        if (result.value) {
          resolve({success: true});
        }
        else {
          reject({success: false});
        }
      });
    });
  }

  exibeErro(erro): Promise<any>{
    return new Promise((resolve,reject)=>{
      if (erro.statusCode==401){
        swal({
          type: 'error',
          title: 'Oops...',
          text: erro.statusText
        }).then((result) => {
          if (result.value) {
            resolve({success: true});
          }
          else {
            reject({success: false});
          }
        });
      }
      else if (erro.statusCode==403){
        swal({
          type: 'error',
          title: 'Oops...',
          text: 'Sua sessão expirou! Realize o login novamente...'
        }).then((result) => {
          if (result.value) {
            this.servicoSessao.removerSessao('tsToken').then(r1 => {
              this.servicoSessao.removerSessao('tsDadosSessao').then(r2 => {
                const returnUrl = this.router.url;
                const url = this.router.createUrlTree(['/login'], {
                  queryParams: {
                    returnUrl
                  }
                });
                resolve({success: true, url: url});
              }, (err) => {
                swal({
                  type: 'error',
                  title: 'Oops...',
                  text: 'Ocorreu um erro não identificado!',
                  footer: `<label style='font-size: 0.8rem;'>Favor entrar em contato com o suporte técnico: <b><i>suporte@trackingsales.com.br</i></b></label>`
                }).then((result) => {
                  if (result.value) {
                    reject({success: false});
                  }
                });
              });
            }, (err) => {
              swal({
                type: 'error',
                title: 'Oops...',
                text: 'Ocorreu um erro não identificado!',
                footer: `<label style='font-size: 0.8rem;'>Favor entrar em contato com o suporte técnico: <b><i>suporte@trackingsales.com.br</i></b></label>`
              }).then((result) => {
                if (result.value) {
                  reject({success: false});
                }
              });
            });
          }
        });
      }
      else if (erro.statusCode==500){
        swal({
          type: 'error',
          title: 'Oops...',
          text: 'Ocorreu um erro não identificado!',
          footer: `<label style='font-size: 0.8rem;'>Favor entrar em contato com o suporte técnico: <b><i>suporte@trackingsales.com.br</i></b></label>`
        }).then((result) => {
          if (result.value) {
            reject({success: false});
          }
        });
      }
      else {
        let texto = erro.hasOwnProperty("statusText") ? erro.statusText : 'Ocorreu um erro não identificado!';
        swal({
          type: 'error',
          title: 'Oops...',
          text: texto,
          footer: `<label style='font-size: 0.8rem;'>Favor entrar em contato com o suporte técnico: <b><i>suporte@trackingsales.com.br</i></b></label>`
        }).then((result) => {
          if (result.value) {
            reject({success: false});
          }
        });
      }
    });
  }

  exibeAlerta(titulo, id, srv, texto): Promise<any>{
    return new Promise((resolve,reject)=>{
      swal({
        title: titulo,
        text: 'Atenção, esta ação não poderá ser revertida!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, remova!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          // this.spinner.show();
          srv.remover(id).subscribe(res => {
            // this.spinner.hide();
            this.exibeSucesso('Removido!', texto).then(r1 => {
              if (r1) {
                resolve({success: true});
              }
              else {
                reject({success: false});
              }
            });
          }, (err: any) => {
            // this.spinner.hide();
            this.exibeErro(err).then(r2 => {
              reject({success: false});
            });
          });
        }
      });
    });
  }

  exibeAlertaCustomizado(tituloAlerta, id, srv, metodo, tituloSucesso, textoSucesso, txtBtnConfirm, txtBtnCancel): Promise<any>{
    return new Promise((resolve,reject)=>{
      swal({
        title: tituloAlerta,
        text: 'Atenção, esta ação não poderá ser revertida!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: txtBtnConfirm,
        cancelButtonText: txtBtnCancel
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          srv[metodo](id).subscribe(res => {
            this.spinner.hide();
            this.exibeSucesso(tituloSucesso, textoSucesso).then(r1 => {
              if (r1) {
                resolve({success: true});
              }
              else {
                reject({success: false});
              }
            });
          }, (err: any) => {
            this.spinner.hide();
            this.exibeErro(err).then(r2 => {
              reject({success: false});
            });
          });
        }
      });
    });
  }
}
