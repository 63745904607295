import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCliente } from '../../servicos/srv.cliente';
import { ServicoProjeto } from '../../servicos/srv.projeto';
import { ServicoSessao } from '../../servicos/srv.sessao';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'tsv2-adicionar',
  templateUrl: './adicionar.component.html',
  providers: [ ServicoMsg, ServicoCliente, ServicoProjeto, ServicoSessao ]
})
export class ClienteAdicionarComponent implements OnInit {
  exibirBarra: boolean = true;
  prj: any;

  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCliente: ServicoCliente,
    private servicoProjeto: ServicoProjeto,
    private servicoSessao: ServicoSessao,
    private localeService: BsLocaleService
  ) {
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });

    this.localeService.use('pt-br');
  }

  ngOnInit() {
    // this.spinner.show();
    this.prj = this.servicoSessao.buscarProjeto();
    this.servicoProjeto.listarProjetos({ "idProjeto": this.prj.projetoId }).subscribe(res => {
      this.prj = res.lista[0];
      // this.spinner.hide();
    }, (err: any) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
    // this.spinner.hide();
  }

  adicionarCliente(frmValues){
    // this.spinner.show();
    this.prj = this.servicoSessao.buscarProjeto();
    if (this.prj.hasOwnProperty("projetoId")){
      frmValues.idProjeto = this.prj.projetoId;
      this.servicoCliente.adicionarCliente(frmValues).subscribe(id => {
        let url = '/cliente/visualizar/' + id;
        // this.spinner.hide();
        this.servicoMsg.exibeSucesso('Criado!', 'Cliente criado com sucesso!').then(r1 => {
          if (r1.success){
            this.router.navigate([url]);
          }
        }, (err: any) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
    else{
      // this.spinner.hide();
      this.servicoMsg.exibeErro({"statusCode": 999}).then(r1 => {});
    }
  }

}
