import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoFornecedor } from '../../servicos/srv.produto';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';

@Component({
  selector: 'tsv2-frn-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoMsg, ServicoFornecedor, ServicoPaginacao ]
})
export class FornecedorPesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  resultado: any;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  paginas: any;

  constructor(
    private spinner: NgxSpinnerService,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMsg: ServicoMsg,
    private servicoPaginacao: ServicoPaginacao
  ) {
    this.qtdeItensPadrao = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;
  }

  ngOnInit() {
  }

  pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      this.spinner.show();
      this.pesquisa = 1;
      frmValues.pagina = pagina;
      frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);

      this.servicoFornecedor.listarFornecedores(frmValues).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

}
