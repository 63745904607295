import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../../servicos/srv.msg';
import { ServicoCampanhaProduto } from '../../../servicos/srv.campanha';

@Component({
  selector: 'tsv2-lrl-campanha-produto-listar',
  templateUrl: './listar.component.html',
  providers: [ ServicoMsg, ServicoCampanhaProduto ]
})
export class LorealCampanhaProdutoListarComponent implements OnInit {
  @Input() items: any[];
  @Input() pagina: number;
  @Input() produtosAprovados: boolean;

  @Output() trocar = new EventEmitter();

  editar: boolean = false;
  editarIndice: number = -1;
  valor: number = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCampanhaProduto: ServicoCampanhaProduto
  ) { }

  ngOnInit() {
  }

  async salvarEdicao(i, valor){
    try {
      this.spinner.show();
      i.aporteDesconto = parseFloat(valor.replace(',','.'));
      if (i.desconto >= i.aporteDesconto){
        let res = await this.servicoCampanhaProduto.alterarCampanhaProdutoAsync(i);

        this.editar = false;
        this.editarIndice = -1;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        this.servicoMsg.exibeErro({"statusCode": 999, "statusText": "O valor do ressarcimento não pode ser maior que o valor do desconto!"}).then(r1 => {});
      }
    }
    catch (err) {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    }
  }

  editarProduto(e, i, vl){
    this.editar = (!this.editar) ? true : false;
    this.editarIndice = i;
    this.valor = vl;
    // console.log(i, e);
  }

  cancelarEdicao(e, i){
    this.editar = false;
    this.editarIndice = -1;
  }
}
