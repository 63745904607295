import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCategoria } from '../../servicos/srv.produto';

@Component({
  selector: 'tsv2-ctg-adicionar',
  templateUrl: './adicionar.component.html'
})
export class CategoriaAdicionarComponent implements OnInit {
  exibirBarra: boolean = true;

  psq: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoCategoria: ServicoCategoria,
    private servicoMsg: ServicoMsg
  ) { }

  ngOnInit() {
  }

  adicionarCategoria(frmValues){
    // this.spinner.show();
    this.servicoCategoria.adicionarCategoria(frmValues).subscribe(id => {
      let url = '/categoria/visualizar/' + id;
      // this.spinner.hide();
      this.servicoMsg.exibeSucesso('Criada!', 'Categoria criada com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
