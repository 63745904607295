import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoLogin } from '../../servicos/srv.configuracao';
import { ServicoMsg } from '../../servicos/srv.msg';

import { EncryptDecrypt } from '../../servicos/srv.encryptdecrypt';

@Component({
  selector: 'tsv2-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  providers: [ ServicoLogin, ServicoMsg, EncryptDecrypt ]
})
export class RedefinirSenhaComponent implements OnInit {

  ano: number;
  hash: string;
  nome: string;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private servicoLogin: ServicoLogin,
    private servicoMsg: ServicoMsg,
    private encryptDecrypt: EncryptDecrypt
  ) { }

  ngOnInit() {
    this.spinner.show();
    let data = new Date();
    this.ano = data.getFullYear();

    this.hash = this.route.snapshot.params.hash;
    this.servicoLogin.buscarHash(this.hash).subscribe(nome => {
      if (nome !== null){
        this.spinner.hide();
        this.nome = nome;
      }
      else {
        window.location.href=window.location.origin + '/login';
      }
    }, (err) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  definirSenha(frm){
    this.spinner.show();
    frm.senha = this.encryptDecrypt.Encrypt(frm.senha);
    frm.hash = this.hash;
    this.servicoLogin.definirSenha(frm).subscribe(reg => {
      if (reg){
        this.servicoMsg.exibeSucesso('Alerada!', 'Senha definida com sucesso!').then(r1 => {
          if (r1.success){
            // this.router.navigate([url]);
            window.location.href=this.route.snapshot.queryParams['returnUrl'] || '/login';
          }
        }, (err: any) => {
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }
      else {
        this.spinner.hide();
        this.servicoMsg.exibeErro({statusCode: 999}).then(r1 => {});
      }
    }, (err) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
