import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoLogin } from '../../servicos/srv.configuracao';
import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoSessao } from '../../servicos/srv.sessao';

import { EncryptDecrypt } from '../../servicos/srv.encryptdecrypt';

@Component({
  selector: 'tsv2-login',
  templateUrl: './login.component.html',
  providers: [ ServicoLogin, ServicoMsg, ServicoSessao, EncryptDecrypt ]
})
export class LoginComponent implements OnInit {

  ano: number;
  popEsqueceuSenha: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private servicoLogin: ServicoLogin,
    private servicoMsg: ServicoMsg,
    private servicoSessao: ServicoSessao,
    private encryptDecrypt: EncryptDecrypt,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    let data = new Date();
    this.ano = data.getFullYear();
  }

  logar(frm: NgForm){
    this.spinner.show();
    frm.value.senha = this.encryptDecrypt.Encrypt(frm.value.senha);
    this.servicoLogin.logar(frm.value).subscribe(idSessao => {
      this.servicoSessao.adicionarSessao('tsToken', idSessao).then(r1 => {
        this.servicoSessao.buscarDadosSessao().subscribe(sessao => {
          this.servicoSessao.adicionarSessao('tsDadosSessao', this.encryptDecrypt.Encrypt(JSON.stringify(sessao))).then(r2 => {
            window.location.href=this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
          }, (err) => {
            this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }, (err) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(e => {
            if (e.hasOwnProperty('url')){
              window.location.href=e.url;
            }
          });
        });
      }, (err) => {
        frm.resetForm();
        this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err) => {
      frm.resetForm();
      this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  exibirPopEsqueceuSenha(){
    setTimeout(() => {
      this.popEsqueceuSenha = (this.popEsqueceuSenha) ? false : true;
    }, 250);
  }

  enviarSenha(frm: NgForm){
    if (frm.valid) {
      this.exibirPopEsqueceuSenha();
      this.spinner.show();
      let email = this.encryptDecrypt.Encrypt(frm.value.email);
      email = email.split('+').join('-');
      email = email.split('/').join('_');
      this.servicoLogin.redefinirSenha(email).subscribe(retorno => {
        this.spinner.hide();
        this.servicoMsg.exibeSucesso('Nova Credencial!', 'Será enviada uma nova credencial, caso o e-mail esteja cadastrado!').then(r1 => {
          if (r1.success){
            this.spinner.show();
            window.location.href=window.location.origin + '/login';
          }
        }, (err: any) => {
          frm.resetForm();
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        frm.resetForm();
        this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
  }
}
