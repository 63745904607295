import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoFornecedor, ServicoMarca } from '../../servicos/srv.produto';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';

@Component({
  selector: 'tsv2-mrc-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoFornecedor, ServicoMsg, ServicoMarca, ServicoPaginacao ]
})
export class MarcaPesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  fornecedores: any;
  resultado: any;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  paginas: any;

  constructor(
    private spinner: NgxSpinnerService,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMarca: ServicoMarca,
    private servicoMsg: ServicoMsg,
    private servicoPaginacao: ServicoPaginacao
  ) { }

  ngOnInit() {
    this.spinner.show();

    this.qtdeItensPadrao = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;

    this.servicoFornecedor.listarFornecedores({}).subscribe(rsp => {
      this.fornecedores = rsp.lista;
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.servicoMsg.exibeErro(err);
    });
  }

  pesquisar(frmValues, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      this.spinner.show();
      this.pesquisa = 1;
      frmValues.pagina = pagina;
      frmValues.itensPagina = (qtdeItens==undefined || qtdeItens==null ? 20 : qtdeItens);

      this.servicoMarca.listarMarcas(frmValues).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        this.servicoMsg.exibeErro(err);
      });
    }
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

}
