import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

import swal from 'sweetalert2';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCampanha, ServicoCampanhaRequisito, ServicoCampanhaProduto, ServicoCampanhaClienteGrupo } from '../../servicos/srv.campanha';

@Component({
  selector: 'tsv2-cmp-visualizar',
  templateUrl: './visualizar.component.html',
  providers: [ ServicoMsg, ServicoCampanha, ServicoCampanhaRequisito, ServicoCampanhaProduto, ServicoCampanhaClienteGrupo ]
})
export class CampanhaVisualizarComponent implements OnInit {
  @ViewChild('pageContent') pageContent: ElementRef;

  exibirBarra: boolean = true;

  p: any;
  requisitos: any;
  produtos: any;
  grupos: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // private spinner: NgxSpinnerService,
    private servicoMsg: ServicoMsg,
    private servicoCampanha: ServicoCampanha,
    private servicoCampanhaRequisito: ServicoCampanhaRequisito,
    private servicoCampanhaProduto: ServicoCampanhaProduto,
    private servicoCampanhaClienteGrupo: ServicoCampanhaClienteGrupo
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.servicoCampanha.consultarCampanha(id).subscribe(res => {
      this.p = res.lista[0];
      // this.servicoCampanhaRequisito.listarCampanhaRequisitos({"idCampanha": id}).subscribe(res => {
      //   this.requisitos = res.lista;
      //   this.servicoCampanhaProduto.listarCampanhaProdutos({"idCampanha": id}).subscribe(res => {
      //     this.produtos = res.lista;
      //     this.servicoCampanhaClienteGrupo.listarCampanhaClienteGrupos({"idCampanha": id}).subscribe(res => {
      //       this.grupos = res.lista;
      //       this.spinner.hide();
      //     }, (err) => {
      //       this.spinner.hide();
      //       this.servicoMsg.exibeErro(err).then(r1 => {});
      //     });
      //   }, (err) => {
      //     this.spinner.hide();
      //     this.servicoMsg.exibeErro(err).then(r1 => {});
      //   });
      // }, (err) => {
      //   this.spinner.hide();
      //   this.servicoMsg.exibeErro(err).then(r1 => {});
      // });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  exibirBarraNav(exibir) {
    this.exibirBarra = exibir;

    this.configAppComponent(exibir);
  }

  configAppComponent(exibir) {
    let pageContainer = this.pageContent.nativeElement.parentNode.parentNode;
    let classe = "page-container-wide";
    let arr = pageContainer.className.split(" ");
    if (!exibir) {
      if (arr.indexOf(classe) == -1) {
        pageContainer.className += " " + classe;
      }
    }
    else {
      let i = arr.indexOf(classe);
      if (i >= 0) {
        let classeRemovida = arr.splice(i, 1);
        pageContainer.className = arr.toString();
      }
    }
  }

  removerCampanha(id){
    let titulo = 'Remover a campanha?';
    let url = '/campanha/pesquisar/';
    let texto = 'Campanha removida com sucesso!';
    this.servicoMsg.exibeAlerta(titulo, id, this.servicoCampanha, texto).then(r1 => {
      if (r1.success){
        this.router.navigate([url]);
      }
    });
  }

}
