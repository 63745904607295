import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoProjeto, ServicoProjetoAberturaIdentificacao, ServicoProjetoTipo, ServicoProjetoTipoCaptura, ServicoProjetoTipoIdentificacao } from '../../servicos/srv.projeto';

@Component({
  selector: 'tsv2-prj-editar',
  templateUrl: './editar.component.html',
  providers: [ ServicoMsg, ServicoProjeto, ServicoProjetoAberturaIdentificacao, ServicoProjetoTipo, ServicoProjetoTipoCaptura, ServicoProjetoTipoIdentificacao ]
})
export class ProjetoEditarComponent implements OnInit {
  exibirBarra: boolean = true;
  p: any;

  tipos: any;
  tiposAbertura: any;
  tiposCaptura: any;
  tiposIdentificacao: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private servicoMsg: ServicoMsg,
    private servicoProjeto: ServicoProjeto,
    private servicoProjetoAberturaIdentificacao: ServicoProjetoAberturaIdentificacao,
    private servicoProjetoTipo: ServicoProjetoTipo,
    private servicoProjetoTipoCaptura: ServicoProjetoTipoCaptura,
    private servicoProjetoTipoIdentificacao: ServicoProjetoTipoIdentificacao
  ) { }

  ngOnInit() {
    // this.spinner.show();
    const id = this.route.snapshot.params['id'];
    this.servicoProjeto.consultarProjeto(id).subscribe(res => {
      this.p = res.lista[0];
      // this.spinner.hide();
      this.buscarParametros();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  alterarProjeto(frmValues){
    // this.spinner.show();
    this.servicoProjeto.alterarProjeto(frmValues).subscribe(id => {
      let url = '/projeto/visualizar/' + id;
      // this.spinner.hide();
      // this.servicoMsg.exibeSucesso('Alterado!', 'Projeto alterado com sucesso!', ('/projeto/visualizar/' + id));
      this.servicoMsg.exibeSucesso('Alterado!', 'Projeto alterado com sucesso!').then(r1 => {
        if (r1.success){
          this.router.navigate([url]);
        }
      }, (err: any) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err: any) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  buscarParametros(){
    this.servicoProjetoAberturaIdentificacao.listarProjetoAberturaIdentificacao({}).subscribe(rsp => {
      this.tiposAbertura = rsp.lista;
      this.servicoProjetoTipo.listarProjetoTipo({}).subscribe(rsp => {
        this.tipos = rsp.lista;
        this.servicoProjetoTipoCaptura.listarProjetoTipoCaptura({}).subscribe(rsp => {
          this.tiposCaptura = rsp.lista;
          this.servicoProjetoTipoIdentificacao.listarProjetoTipoIdentificacao({}).subscribe(rsp => {
            this.tiposIdentificacao = rsp.lista;
            // this.spinner.hide();
          }, (err) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

}
