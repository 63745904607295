import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoItensTransacao } from '../../servicos/srv.cliente';
import { ServicoEstabelecimento, ServicoRede } from '../../servicos/srv.rede';
import { ServicoPaginacao } from '../../servicos/srv.paginacao';

import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'tsv2-devolucao-pesquisar',
  templateUrl: './pesquisar.component.html',
  providers: [ ServicoMsg, ServicoEstabelecimento, ServicoItensTransacao, ServicoPaginacao, ServicoRede ]
})
export class DevolucaoPesquisarComponent implements OnInit {
  exibirBarra: boolean = true;

  redes: any;
  estabelecimentos: any;
  resultado: any;
  opcaoPadrao: number;
  qtdeItensPadrao: number;
  pesquisa: number;
  paginaAtual: number;
  ttPaginas: number;
  paginas: any;
  query: any = {};
  hoje = new Date();
  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private spinner: NgxSpinnerService,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoItensTransacao: ServicoItensTransacao,
    private servicoMsg: ServicoMsg,
    private servicoRede: ServicoRede,
    private servicoPaginacao: ServicoPaginacao,
    private localeService: BsLocaleService
  ) {
    this.bsConfig = Object.assign({}, { maxDate: this.hoje, containerClass: this.colorTheme });

    this.localeService.use('pt-br');
  }

  ngOnInit() {
    this.qtdeItensPadrao = 20;
    this.pesquisa = 0;
    this.paginaAtual = 1;
    this.ttPaginas = 0;

    this.listarRedes();
  }

  exibirBarraNav(exibir) {
    this.exibirBarra = exibir;
  }

  listarRedes(){
    this.servicoRede.listarRedes({}).subscribe(redes => {
      this.redes = redes.lista;
      this.query = Object.assign(this.query, {"idRede" : "-1"});

      if (redes.lista.length===1){
        this.query.idRede = redes.lista[0].idRede;

        this.listarEstabelecimentos(this.query.idRede);
      }
      else {
        this.spinner.hide();
      }
    }, (err) => {
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  listarEstabelecimentos(idRede){
    if (idRede != "-1") {
      this.query.idRede = idRede;
      if (this.query.hasOwnProperty("cnpj")){
        this.query.cnpj = undefined;
      }
      else {
        this.query = Object.assign(this.query, {"cnpj" : undefined});
      }

      this.servicoEstabelecimento.listarEstabelecimentos(this.query).subscribe(
        estabelecimentos => {
          this.estabelecimentos = estabelecimentos.lista;

          if (estabelecimentos.lista.length===1){
            this.query.cnpj = estabelecimentos.lista[0].cnpj;
          }
          else {
            this.spinner.hide();
          }
        }, (err) => {
          this.servicoMsg.exibeErro(err);
        }
      );
    }
    else {
      this.estabelecimentos = [];
      this.query.cnpj = undefined;
    }
  }

  pesquisar(frm, pagina, qtdeItens, origem){
    if ((origem==1) || (origem==0 && this.pesquisa==1)) {
      //this.spinner.show();
      this.pesquisa = 1;
      frm.pagina = pagina;
      frm.itensPagina = (qtdeItens==undefined || qtdeItens==null ? this.qtdeItensPadrao : qtdeItens);

      this.servicoItensTransacao.listar(frm).subscribe(res => {
        this.resultado = res;
        this.resultado.paginas = this.servicoPaginacao.setarPaginacao(pagina, res.ttPaginas);
        //this.spinner.hide();
      }, (err) => {
        //this.spinner.hide();
        this.servicoMsg.exibeErro({"statusCode": 999});
      });
    }
  }

  trocar(pagina){
    this.paginaAtual = pagina;

    let element: HTMLElement = document.getElementById('pesquisar') as HTMLElement;
    element.click();
  }

}
