import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';

import swal from 'sweetalert2';

import { ServicoSessao } from '../../servicos/srv.sessao';
import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoCampanhaRequisito, ServicoCampanhaTipoRequisito, ServicoCampanhaRequisitoAplicacao, ServicoCampanhaProduto, ServicoCampanhaClienteGrupo } from '../../servicos/srv.campanha';
import { ServicoEstado, ServicoCidade } from '../../servicos/srv.configuracao';
import { ServicoRede, ServicoEstabelecimento } from '../../servicos/srv.rede';
import { ServicoClienteGrupo } from '../../servicos/srv.cliente';

@Component({
  selector: 'tsv2-cmp-configuracoes',
  templateUrl: './configuracoes.component.html',
  providers: [ ServicoSessao, ServicoMsg, ServicoCampanhaRequisito, ServicoCampanhaTipoRequisito, ServicoCampanhaRequisitoAplicacao, ServicoCampanhaProduto, ServicoCampanhaClienteGrupo, ServicoClienteGrupo, ServicoEstado, ServicoCidade, ServicoRede, ServicoEstabelecimento ]
})
export class CampanhaConfiguracoesComponent implements OnInit {
  @Input() idCampanha: number;
  @Input() editar: number;
  @Input() requisitos: any[];
  @Input() produtos: any[];
  @Input() grupos: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // private spinner: NgxSpinnerService,
    private servicoSessao: ServicoSessao,
    private servicoMsg: ServicoMsg,
    private servicoCampanhaClienteGrupo: ServicoCampanhaClienteGrupo,
    private servicoCampanhaProduto: ServicoCampanhaProduto,
    private servicoCampanhaRequisito: ServicoCampanhaRequisito,
    private servicoCampanhaRequisitoAplicacao: ServicoCampanhaRequisitoAplicacao,
    private servicoCampanhaTipoRequisito: ServicoCampanhaTipoRequisito,
    private servicoCidade: ServicoCidade,
    private servicoClienteGrupo: ServicoClienteGrupo,
    private servicoEstabelecimento: ServicoEstabelecimento,
    private servicoEstado: ServicoEstado,
    private servicoRede: ServicoRede
  ) { }

  ngOnInit() {
  }

}
