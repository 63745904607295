import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicoMsg } from '../../servicos/srv.msg';
import { ServicoRede } from '../../servicos/srv.rede';
import { ServicoCategoria, ServicoFornecedor, ServicoMarca, ServicoProduto, ServicoTipoEmbalagem, ServicoTipoCodigo } from '../../servicos/srv.produto';
import { ServicoSessao } from '../../servicos/srv.sessao';

@Component({
  selector: 'tsv2-prd-adicionar',
  templateUrl: './adicionar.component.html',
  providers: [ ServicoMsg, ServicoCategoria, ServicoFornecedor, ServicoMarca, ServicoProduto, ServicoRede, ServicoTipoEmbalagem, ServicoTipoCodigo ]
})
export class ProdutoAdicionarComponent implements OnInit {
  exibirBarra: boolean = true;

  categorias: any;
  fornecedores: any;
  marcas: any;
  tiposEmbalagem: any;
  tiposCodigo: any;
  redes: any;
  psq: any;
  prj: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private servicoCategoria: ServicoCategoria,
    private servicoFornecedor: ServicoFornecedor,
    private servicoMsg: ServicoMsg,
    private servicoMarca: ServicoMarca,
    private servicoProduto: ServicoProduto,
    private servicoRede: ServicoRede,
    private servicoTipoEmbalagem: ServicoTipoEmbalagem,
    private servicoTipoCodigo: ServicoTipoCodigo,
    private servicoSessao: ServicoSessao
  ) { }

  ngOnInit() {
    // this.spinner.show();
    this.servicoCategoria.listarCategorias({}).subscribe(sc => {
      this.categorias = sc.lista;
      this.servicoFornecedor.listarFornecedores({}).subscribe(sf => {
        this.fornecedores = sf.lista;
        this.servicoMarca.listarMarcas({}).subscribe(sm => {
          this.marcas = sm.lista;
          this.servicoTipoEmbalagem.listarTiposEmbalagem({}).subscribe(ste => {
            this.tiposEmbalagem = ste.lista;
            this.servicoTipoCodigo.listarTiposCodigo({}).subscribe(stc => {
              this.tiposCodigo = stc.lista;
              // this.spinner.hide();
            }, (err) => {
              // this.spinner.hide();
              this.servicoMsg.exibeErro(err).then(r1 => {});
            });
          }, (err) => {
            // this.spinner.hide();
            this.servicoMsg.exibeErro(err).then(r1 => {});
          });
        }, (err) => {
          // this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err) => {
        // this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  buscarDadosComplementares(){
    // this.spinner.show();
    this.servicoRede.listarRedes({}).subscribe(sr => {
      this.redes = sr.lista;
      this.spinner.hide();
    }, (err) => {
      // this.spinner.hide();
      this.servicoMsg.exibeErro(err).then(r1 => {});
    });
  }

  adicionarProduto(frmValues){
    // this.spinner.show();
    this.prj = this.servicoSessao.buscarProjeto();
    if (this.prj.hasOwnProperty("projetoId")){
      frmValues.idProjeto = this.prj.projetoId;

      this.servicoProduto.adicionarProduto(frmValues).subscribe(id => {
        let url = '/produto/visualizar/' + id;
        // this.spinner.hide();
        this.servicoMsg.exibeSucesso('Criado!', 'Produto criado com sucesso!').then(r1 => {
          if (r1.success){
            this.router.navigate([url]);
          }
        }, (err: any) => {
          this.spinner.hide();
          this.servicoMsg.exibeErro(err).then(r1 => {});
        });
      }, (err: any) => {
        this.spinner.hide();
        this.servicoMsg.exibeErro(err).then(r1 => {});
      });
    }
    else {
      // this.spinner.hide();
      this.servicoMsg.exibeErro({"statusCode": 999}).then(r1 => {});
    }
  }

}
